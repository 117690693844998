<div class="CaptchaContain">
  <div class='CaptchaWrap' [ngStyle]="getStyles()">
    <div id="CaptchaImageCode" class="CaptchaTxtField" [ngStyle]="getColor()">
      <canvas #canvas></canvas>
    </div>
    <canvas hidden #canvas2></canvas>
  </div>
  <a mdbBtn floating="true" class="btn-primario btnCaptchaUpdate" mdbWavesEffect (click)="createCapcha()">
    <mdb-icon fas icon="redo"></mdb-icon>
  </a>
</div>


<div class="row back_unico cls_header_list">
  <div class="container cls_center">
    <h4 class="cls_title_2 color_white" [innerHTML]="'com_crt_title_payments' | translate"></h4>

    <div class="row back_unico">
      <mdb-carousel [isControls]="true"
                    [interval]="0"
                    (activeSlideChange)="onCoruselActive($event)"
                    class="carousel slide W100"
                    [type]="'carousel'"
                    [animation]="'slide'">
        <mdb-carousel-item *ngFor="let data of list">
          <div class="W100">
            <div style="width: 15% !important; float: left; height: 100%;"></div>
            <div style="width: 70% !important; float: left;">
              <app-card-credit-detail
                [credit]="data"
                [selected]="true"
              ></app-card-credit-detail>
            </div>
            <div style="width: 15% !important; float: left; height: 100%;"></div>
          </div>
        </mdb-carousel-item>
      </mdb-carousel>
    </div>
  </div>
</div>
<div class="row cls_body_list" style="height: 50px">
</div>
<div class="row ">
  <div style="width: 100%;display: flex; text-align: center; background: #FFFFFF">
    <div style="width: 25%">
      <a>Fecha</a>
    </div>
    <div style="width: 25%">
      <a>Total Pagado</a>
    </div>
    <div style="width: 25%">
      <a>Pago Interés</a>
    </div>
    <div style="width: 25%">
      <a>Pago Capital</a>
    </div>
  </div>
  <div class="W100 border_boton_input border_top_input"
       *ngFor="let data of listDetail">
    <app-card-row-detaicredit
      (selectDetail)="selectedDetailView($event, detailModal)"
      [currency]=currency
      [detail]="data">
    </app-card-row-detaicredit>
  </div>
  <div style="width: 100%;display: flex; text-align: center; background: #FFFFFF">
    <div class="W100 border_boton_input border_top_input"
         *ngIf="listDetail.length==0">
      <img src="assets/util/images/Icono_datos_no_encontrados.png" style="display: block; margin-left: auto; margin-right: auto;">
    </div>
  </div>
  <div style="height: 100px;">

  </div>
</div>
<div mdbModal #detailModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="border-radius: 30px;">
      <div class="modal-body" *ngIf="selectCreditView">
        <div>
          <app-card-detail-cargos
              [detailModel]="selectCreditView"
              [moneda]="currency"
              [numeroCredito]="numeroCredito"
          >
          </app-card-detail-cargos>
        </div>
        <div class="cls_center">
          <button mdbBtn
                  class="gradient-custom-2 W100 cls_font_bold"
                  (click)="detailModal.hide()"
                  style="width: 50px !important"
                  mdbWavesEffect
                  type="button">{{'close' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

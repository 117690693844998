import {RequestHeaderModel} from '@models/requestHeader.model';


export class AuthModel1 {
    header: RequestHeaderModel;
    password: string;
    constructor(header: RequestHeaderModel, password?: string) {
        this.header = header;
        this.password = password;
    }
}

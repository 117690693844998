export class ClientModel {
  clientId: number;
  ci: string;
  phoneNumber: string;
  fullName: string;
  accountCode: string;
  contractNumber?: string;
  contractDate: Date;
  birthDate: Date;
  account: string;
  state: string;
  nameBank: string;
  codeBank: string;
  representativeCode: string;
  rol: string;
  company: string;
  nameUserLegal: string;
  lastTime?: Date;
}

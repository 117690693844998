import {CONFIG, DOMAIN, FORMS, LOCSESION, SERVICES, STATUS} from './../../config/config';
import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '@services/auth.service';
import {BaseModel} from '@models/util/base.model';
import {TranslateService} from '@ngx-translate/core';
import {BeneService} from '@pages/beneficiary/bene.service';
import {HistoricalTrazeService} from '@services/historical.traze.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Router} from '@angular/router';
import {DomainService} from '@services/domain.service';
import {MessageService} from '@app/error/message.service';
import {BeneficiaryModel} from '@models/beneficiary';
import {FalDirective, MdbStepperComponent} from 'ng-uikit-pro-standard';
import {forkJoin, fromEvent, Subscription} from 'rxjs';
import {TxDto} from '@models/transfer/TxDto.model';
import {Acc1, AccountDto} from '@models/account';
import {AccountService} from '@services/account.service';
import {ConfirmComponent} from '@app/components/confirm/confirm.component';
import {Tx1, Tx2} from '@models/transfer';
import {TransferService} from '@services/transfer.service';
import {ItemsModel, StorageUtil} from '@models/util';
import {D1, D2, DomainModel} from '@models/domain';
import {ConfirmKeyComponent} from '@app/components/confirm/confirm-key/confirm-key.component';
import {BankbeneModel} from "@models/beneficiary/bankbene.model";
import {LoaderService} from "@services/loader.service";
import {ConfigAppModel} from "@models/client";
import {AuthModel} from '@models/auth';
import { TYPE_TRANS } from '../../config/config';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Cd1Model } from '@app/models/qoute';
import { ConstantPool } from '@angular/compiler';


@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.scss'],
})
export class TransferComponent extends BaseModel implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('stepper', {static: false}) stepper: MdbStepperComponent;
  @ViewChild('div_back', {static: false}) divBack: ElementRef;
  clickedElement: Subscription = new Subscription();

  STEP_BENEFICIARY = 1;
  STEP_BENEFICIARY_NEO_EDIT = 11;
  STEP_ACCOUNT = 2;
  STEP_RESUME = 3;

  cmpReq = FORMS.FRM_REQUIRED;
  cmpMin = FORMS.FRM_MINIMO;
  submit = false;
  entity: TxDto;
  key: string;
  listAccountAll: AccountDto[] = [];
  USD_SALE_VALUE = parseFloat(this.getCurrentUser().sale);

  completeOrder = false;
  step = this.STEP_BENEFICIARY;
  neoEditBeneficiary = false;

  selectBeneficiary: BeneficiaryModel;
  selectAccountDest: AccountDto;
  listItemAccountOri: ItemsModel[] = [];
  listItemCurrency: ItemsModel[] = [];
  selectAccountOri: AccountDto;

  buttonStatus = true;
  nameOriginante: string;
  nameBankOrig: string;
  status: string;
  isLoad = false;

  listDomain: DomainModel[];
  limitInfo: boolean;
  limitDescDest: string;
  limitDescOrig: string;
  limitAmount: number;

  configApp: ConfigAppModel;
  currentUser: AuthModel;
  transfACHL = false;

  sales: number;
  buys : number;



  constructor(
    private loaderService: LoaderService,
    protected authService: AuthService,
    protected domainService: DomainService,
    private translate: TranslateService,
    protected historical: HistoricalTrazeService,
    protected deviceService: DeviceDetectorService,
    private messageService: MessageService,
    private beneService: BeneService,
    public accountService: AccountService,
    public transferService: TransferService,
    private router: Router
  ) {
    super(authService, historical, deviceService);
    super.registerEvent('Transfer Create', this.router.url);

    this.configApp = new ConfigAppModel();
    this.configApp.msgDest = 'Mensaje destino';
    this.configApp.msgOrigin = 'Mensaje origen';
  }

  ngOnInit() {
    this.nameOriginante = this.authService.getCurrentUser().client.fullName;
    this.nameBankOrig = this.authService.getCurrentUser().client.nameBank;

    this.entity = new TxDto();
    this.entity.cuentaPropia = false;
    this.selectBeneficiary = new BeneficiaryModel();
    this.selectAccountOri = new AccountDto();
    this.selectAccountDest = new AccountDto();

    const user = this.authService.getCurrentUser();

    this.sales = Number(user.sale);
    this.buys = Number(user.purhchase);

    console.log("getCurrentUser", user);


    if (user.configApp !== undefined) {
      this.configApp = user.configApp;
      this.currentUser = user;
    }

    this.onListAccounts();
    this.onListCurrency();
    this.limitVariables();
  }

  ngAfterViewInit() {
    this.addAccionBtnBack(this.STEP_BENEFICIARY);
  }

  ngOnDestroy() {
    this.clickedElement.unsubscribe();
  }

  onListAccounts() {
    const user = this.authService.getCurrentUser();
    const acc1 = new Acc1(this.getHeader(this.authService.getUser()), user.client.accountCode, user.client.representativeCode);
    this.accountService.list(acc1)
      .subscribe((resp: any) => {
        this.listAccountAll = resp.list;
        this.isLoad = true;
      });
  }

  onListCurrency() {
    const d1 = new D1(this.getHeader(this.authService.getUser()), DOMAIN.DOM_CURRENCY);
    this.domainService.listDomain(d1).subscribe(
      (data: any) => {
        this.listItemCurrency = new ItemsModel().getItems(data.list);
      }
    );
  }

  getCurencyLabel(value : string ): string {
      const aux = this.listItemCurrency.find(x => x.value === value);
      return aux.label;
  }

  /*************** BENEFICIARY ********************/
  onSelectBeneficiary(value: BeneficiaryModel) {
    console.log( "value:", value);
    this.selectBeneficiary = value;

    this.listItemAccountOri = new ItemsModel().getItemsAccounts(this.listAccountAll);
    this.selectDefaults(null);

    this.onNextBeneficiary();
  }

  onNextBeneficiary() {
    this.stepper.next();
    this.goTop();
    this.step = this.STEP_ACCOUNT;
    this.addAccionBtnBack(this.STEP_ACCOUNT);
  }

  onBackBeneficiary() {
    this.stepper.previous();
    this.goTop();
    this.step = this.STEP_BENEFICIARY;
    this.addAccionBtnBack(this.STEP_BENEFICIARY);
  }

  onBackNeoEditBeneficiary() {
    this.beneService.onBack();

    this.step = this.STEP_BENEFICIARY;
    this.addAccionBtnBack(this.STEP_BENEFICIARY);
  }

  actionNeoEditBeneficiary(action: boolean) {
    this.neoEditBeneficiary = action;
    if (this.neoEditBeneficiary) {
      this.addAccionBtnBack(this.STEP_BENEFICIARY_NEO_EDIT);
    }
  }

  onSelectAccountDest(value: AccountDto) {
    console.log('onSelectAccountDest::', value);

    this.selectAccountDest = value;

    const aux = this.listAccountAll.filter(x => x.numAccount !== value.numAccount);
    console.log('aux::', aux);
    this.listItemAccountOri = new ItemsModel().getItemsAccounts(aux);
    this.selectDefaults(this.selectAccountDest);
    this.onNextBeneficiary();
  }

  selectDefaults(acountDest: AccountDto) {
    if (this.listItemAccountOri.length === 1) {
      this.entity.accountNumberOri = this.listItemAccountOri[0].value;
    }
    if (this.entity.currency == null) {
      this.entity.currency = this.listItemCurrency.find(x => x.label.includes('B')).value;
    }
    if (acountDest != null) {
      this.selectBeneficiary = new BeneficiaryModel();
      this.selectBeneficiary.accountNumber = acountDest.numAccount;
      this.selectBeneficiary.currency = acountDest.currency;
      this.selectBeneficiary.typeAccount = acountDest.typeAccount;
    }
  }

  /*************** ACCOUNT ********************/

  onNextAccount() {
   console.log("entro al next");
   const sendtxDto = this.entity.clone(this.entity);
   console.log("sendtxDto", sendtxDto);




   this.selectAccountOri = this.listAccountAll.find(x => x.numAccount === this.entity.accountNumberOri);

    var money_int = sendtxDto.currency.includes('B') ? 'Bs.' : 'USD';
    console.log("dinero entrante", money_int);

    var cuurency_acount = this.selectAccountOri.currency.includes('B') ? 'Bs.' : 'USD';
    var amount_int = sendtxDto.amount;

    var amount_account = this.selectAccountOri.available.replace(",", ""); // Elimina la coma
    var accoutn_amount = Number(amount_account); // Convierte el valor en un número

    if(money_int == cuurency_acount){
      if (accoutn_amount <  amount_int){

        //el Importe entrante no puede ser mayor al importe total de la cuenta
        this.messageService.showWarning(this.translate.instant('tra_val_amoun'));
        return;
      }
    }else{
      console.log("al elegir el dolar", cuurency_acount);
      if(cuurency_acount == 'USD'){
          var impt_conv_int =  amount_int / this.sales ;
          if(money_int != "USD"){
            impt_conv_int =  amount_int / this.buys;
          }

          if (accoutn_amount <  impt_conv_int){
            //el Importe entrante no puede ser mayor al importe total de la cuenta
            this.messageService.showWarning(this.translate.instant('tra_val_amoun'));
            return;
          }
      }else{
        var impt_conv_int =  amount_int * this.sales  ;

          if (accoutn_amount <  impt_conv_int){
            //el Importe entrante no puede ser mayor al importe total de la cuenta
            this.messageService.showError(this.translate.instant('tra_val_amoun'));
            return;
          }
      }

    }



    this.stepper.next();
    this.goTop();
    this.step = this.STEP_RESUME;
    this.addAccionBtnBack(this.STEP_RESUME);

    // PREPARED

    this.entity.accountType = this.selectAccountOri.typeAccount;
    this.entity.txType = SERVICES.ACH;

    console.log("selectAccountOri",this.selectAccountOri);
    if (this.entity.cuentaPropia) {
      this.entity.txType = SERVICES.PROPIAS;
    } else if (this.selectBeneficiary.eif === this.authService.getCurrentUser().client.codeBank) {
        this.entity.txType = SERVICES.TERCERO;
    }
  }

  onBackAccount() {
    this.stepper.previous();
    this.goTop();
    this.step = this.STEP_ACCOUNT;
    this.addAccionBtnBack(this.STEP_ACCOUNT);
  }

  /*************** SUBMIT ********************/
  onSubmit(modal: ConfirmKeyComponent) {
    const user = this.authService.getCurrentUser();
    const sendtxDto = this.entity.clone(this.entity);
    sendtxDto.accountCode = this.authService.getCurrentUser().client.accountCode;
    console.log("this.selectBeneficiary:",this.selectBeneficiary);
    sendtxDto.beneficiary = this.selectBeneficiary;
    sendtxDto.representativeCode = user.client.representativeCode;

    if(this.configApp.isShow){
        if ((this.entity.originFound === undefined || this.entity.originFound.length === 0) || (this.entity.destFound.length === undefined  || this.entity.destFound.length === 0 )){
          sendtxDto.originFound = this.configApp.msgOrigin;
          sendtxDto.destFound = this.configApp.msgDest;
        }else{
          if(this.entity.amount < this.limitAmount){
            sendtxDto.originFound = this.configApp.msgOrigin;
            sendtxDto.destFound = this.configApp.msgDest;
          }
        }

    }else{
      sendtxDto.originFound = this.configApp.msgOrigin;
      sendtxDto.destFound = this.configApp.msgDest;
    }

    const tx1 = new Tx1(this.getHeader(this.authService.getUser()), sendtxDto, modal.key);
     var typeTrans = tx1.txDto.txType;
    this.submit = true;
      console.log('submit1::', tx1);
    this.transferService.transfer(tx1)
      .subscribe((resp: Tx2) => {
          console.log('submit12', resp);
          this.submit = false;
          if (resp.header.codReturn != '0') {
            modal.onHide();
            this.messageService.showError(resp.header.txtReturn);
          } else {
            this.entity.txNumberCore = resp.txNumberCore;
            this.entity.dateTransaction = new Date(resp.txDate);
            this.completeOrder = true;

        if((typeTrans == SERVICES.PROPIAS) || (typeTrans == SERVICES.TERCERO) ){
                this.status = STATUS.Confirmada;
            }else{
              this.transfACHL=true;

	            this.status = STATUS.Procesando;
            }

            modal.onHide();
            super.registerEvent('Transferencia: se realizo la transferencia ' + resp.txNumberCore, this.router.url);
          }
        },
        (error: any) => {
          console.log(error);
          modal.onHide();
          this.submit = false;
        }
      );
      console.log("entity", this.entity);
  }


  addAccionBtnBack(step: number) {
    this.clickedElement.unsubscribe();

    switch (step) {
      case this.STEP_BENEFICIARY: {
        this.clickedElement = fromEvent(this.divBack.nativeElement, 'click').subscribe(() => this.backBack());
        break;
      }
      case this.STEP_BENEFICIARY_NEO_EDIT: {
        this.clickedElement = fromEvent(this.divBack.nativeElement, 'click').subscribe(() => this.onBackNeoEditBeneficiary());
        break;
      }
      case this.STEP_ACCOUNT: {
        this.clickedElement = fromEvent(this.divBack.nativeElement, 'click').subscribe(() => this.onBackBeneficiary());
        break;
      }
      case this.STEP_RESUME: {
        this.clickedElement = fromEvent(this.divBack.nativeElement, 'click').subscribe(() => this.onBackAccount());
        break;
      }
      default: {
        break;
      }
    }
  }

  backBack() {
    this.router.navigate(['']);
  }
  enableButton() {
    this.buttonStatus = !this.buttonStatus;
  }
  resetButton() {
    this.buttonStatus = true;

  }
  originDestFounds(entity) {
    if (this.entity.currency == 'USD') {
      if (this.limitInfo){
        return entity.amount * this.USD_SALE_VALUE > this.limitAmount;
      } else{
        return false;
      }
    }
    else {
      if (this.limitInfo){
        return entity.amount > this.limitAmount;
      } else{
        return false;
      }
    }
  }
  back_to_menu() {
    this.router.navigate(['summary']);
  }
  goTop(){
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  limitVariables() {
    const d1 = new D1(this.getHeader(this.authService.getUser()), DOMAIN.DOM_LIMIT_TRANS);

    this.domainService.listDomain(d1).subscribe(
      (data: D2) => {
        console.log("limites::",data.list);
        this.listDomain = data.list;
        this.limitVariablesInitialization();
      });
  }
  limitVariablesInitialization(){
    this.listDomain.forEach(value => {
      if ( value.domName == 'LIMIT_TX_INFO'){
        this.limitInfo = value.domValue.toLowerCase() == 'true';
      }
      else if ( value.domName == 'LIMIT_TX_INFO_DESC_DEST'){
        this.limitDescDest = value.domValue;

      }
      else if ( value.domName == 'LIMIT_TX_INFO_DESC_ORIG'){
        this.limitDescOrig = value.domValue;

      }
      else if ( value.domName == 'LIMIT_TX_INFO_AMOUNT'){
        this.limitAmount = parseFloat(value.domValue) ;
        console.log("limitAmount",this.limitAmount);
      }
    });
  }

  getCurrentUser() {
    const currentUser: AuthModel = JSON.parse(StorageUtil.get(LOCSESION.LS_CURRENT_USER));
    return currentUser;
  }

}

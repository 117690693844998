import {Config} from './config';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PH1} from '@models/phone/ph1.model';
import {PH2} from '@models/phone/ph2.model';
import {PH4} from '@models/phone/ph4.model';

@Injectable({
  providedIn: 'root'
})
export class PhoneService {

  constructor(private http: HttpClient) {
  }

  find(model) {
    return this.http.post(`${Config.settings.path.url_root}/tesabiz-ws-security/tbz/phone/list`, model);
  }

  offSession(model: PH1) {
    return this.http.post<PH2>(`${Config.settings.path.url_root}/tesabiz-ws-security/tbz/phone/offsession`, model);
  }

  checkStatusNlist(model: PH1) {
    return this.http.post<PH4>(`${Config.settings.path.url_root}/tesabiz-ws-security/tbz/phone/checknlist`, model);
  }

}

export class LimitSelectModel {

  accountNumber: string;
  typeTransact: string;
  limite: string;
  desTransact: string;
  currency: string;

  constructor(accountNumber: string, typeTransact: string, limite: string, desTransact: string, currency: string) {
    this.accountNumber = accountNumber;
    this.typeTransact = typeTransact;
    this.limite = limite;
    this.desTransact = desTransact;
    this.currency = currency;
  }
}

import {RequestHeaderModel} from "@models/requestHeader.model";

export  class Dpf1 {
  header: RequestHeaderModel;
  canal: number;
  codigoCliente: string;
  representativeCode: string;


  constructor(header: RequestHeaderModel, canal: number, codigoCliente: string, representativeCode: string) {
    this.header = header;
    this.canal = canal;
    this.codigoCliente = codigoCliente;
    this.representativeCode = representativeCode;
  }
}

<div  *ngIf="items > 0">
  <div class="col-md-12 col-lg-12" >
    <div class="row">
      <nav class="my-4">
        <ul class="pagination pagination-circle pg-primario mb-0 flex-wrap" style="display: flex; justify-content: center !important;
    align-items: center !important;">

          <!--First-->
          <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item">
            <a  (click)="setPage(1)" class="page-link">Primero</a>
          </li>

          <!--Arrow left-->
          <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item">
            <a (click)="setPage(pager.currentPage - 1)" class="page-link" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
              <span class="sr-only">Anterior</span>
            </a>
          </li>


          <!--Numbers-->
          <li *ngFor="let page of getVisiblePages()" [ngClass]="{ active: pager.currentPage === page }" class="page-item">
            <a (click)="setPage(page)" class="page-link">{{ page }}</a>
          </li>

          <!--Arrow right-->
          <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item">
            <a  (click)="setPage(pager.currentPage + 1)" class="page-link" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
              <span class="sr-only">Siguiente</span>
            </a>
          </li>

          <!--First-->
          <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item">
            <a (click)="setPage(pager.totalPages)" class="page-link">Último</a>
          </li>

        </ul>
      </nav>
    </div>
  </div>
</div>

<div class="text-center">
  <h4 class="cls_title_2" [innerHTML]="'history_title' | translate"></h4>
</div>

<div class="div_style row back_white">
  <div class=" container-fluid MB20" >
    <form #filter="ngForm">
      <div class=" MT10">
        <div class=" row MT10">
          <div class="col-6 col-md-4" >
            <div class="md-form">
              <mdb-date-picker #dateIniInput="ngModel"
                               [(ngModel)]="dateIni"
                               name="dateIni"
                               [locale]="locale"
                               class="form-control cls_select cls_select_white cls_date_picker"
                               [inline]="false"
                               label="{{'history_date_ini' | translate}} *"
                               [options]="myDatePickerOptions"
                               required>
              </mdb-date-picker>
              <div *ngIf="!dateIniInput.valid && (dateIniInput.dirty || dateIniInput.touched)">
                <mdb-error *ngIf="dateIniInput.hasError('required')">{{cmpReq}}</mdb-error>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-4" >
            <div class="md-form">
              <mdb-date-picker #dateEndInput="ngModel"
                               [(ngModel)]="dateEnd"
                               name="endStamp"
                               [locale]="locale"
                               class="form-control cls_select cls_select_white cls_date_picker"
                               [inline]="false"
                               label="{{'history_date_fin' | translate}} *"
                               [options]="myDatePickerOptions"
                               required>
              </mdb-date-picker>
              <div *ngIf="!dateEndInput.valid && (dateEndInput.dirty || dateEndInput.touched)">
                <mdb-error *ngIf="dateEndInput.hasError('required')">{{cmpReq}}</mdb-error>
              </div>
            </div>
          </div>
          <div class="button_style cls_center col-6 col-md-3 col-12 ">
            <button type="submit"
                    class="gradient-custom-3 button-class"
                    (click)="onList()"
                    style="margin-top: 10px; margin-right: 20px"
                    [disabled]="!filter.valid || dateEnd<dateIni">
              <i class="fas fa-filter pr-2 fa-lg" aria-hidden="true"></i>{{'cons_filter' | translate}}
            </button>

          </div>
        </div>
      </div>
    </form>
    <div >
      <div class="W100 border_boton_input border_top_input"
           *ngFor="let data of filteredData">
        <app-card-order
          (selectOrder)="selectedOrderView($event, detailModal)"
          [currency]="data.currency.includes('B') ? 'Bs.' : 'USD'"
          [order]="data">
        </app-card-order>
      </div>

      <div *ngIf="filteredData.length<1" class="W100 border_boton_input border_top_input">
        <img src="assets/util/images/Icono_datos_no_encontrados.png" style="display: block; margin-left: auto; margin-right: auto;">
      </div>

      <div style="height: 100px; display: flex; justify-content: center !important;
    align-items: center !important; width: 100%">
        <app-paginador [items]='cantidadTransacciones' [pageSize]='perPage' [initialPage]='1' [maxPages]='pagesToShow'
                       (changePage)='changePage($event)'       ></app-paginador>
      </div>

    </div>
  </div>


</div>



<div mdbModal #detailModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body" *ngIf="selectOrderView">
        <div>
          <app-card-comprobante
            [nombreOri]="selectOrderView.nameOrigen"
            [cuentaOri]="selectOrderView.accountOrigin"
            [bancoOri]="selectOrderView.eifOrigin"
            [nombreDes]="selectOrderView.nameDes"
            [cuentaDes]="selectOrderView.accountDes"
            [bancoDes]="selectOrderView.eifDes"
            [importe]="selectOrderView.amount"
            [moneda]="selectOrderView.currency"
            [glosa]="selectOrderView.gloss"
            [fechaTransaccion]="selectOrderView.dateTransaction"
            [txNumberCore]="selectOrderView.numTransaction"
            [numeroTransaccionACH]="selectOrderView.numTransactionACH"
            [complete]="true"
            [estado]="selectOrderView.status == null? 'null' : selectOrderView.status"
            [quotaValue]="selectOrderView.quotaValue"
            [quotaAmount]="selectOrderView.quotaAmount"
          >
          </app-card-comprobante>
        </div>
        <div class="cls_center">
          <button mdbBtn
                  class="btn-secundario"
                  (click)="detailModal.hide()"
                  mdbWavesEffect
                  type="button">{{'close' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

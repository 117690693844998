import {
  Component, Directive,
  EventEmitter,
  Input,
  Output, ViewChild
} from '@angular/core';
import {MDBModalRef, ModalDirective} from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})

export class ConfirmComponent {
  @ViewChild('confirm') public modModal: ModalDirective;
  @Input() text: string;
  @Input() det1: string;
  @Input() det2: string;
  @Output() success = new EventEmitter();
  @Output() show = new EventEmitter();
  @Output() hide = new EventEmitter();

  submit = false;

  constructor() {
    this.submit = false;
  }

  onOk(): void {
    this.submit = true;
    this.success.emit();
  }

  onShow(): void {
    this.submit = false;
    this.show.emit();
    this.modModal.show();
  }

  onHide(): void {
    this.submit = false;
    this.hide.emit();
    this.modModal.hide();
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-notfound',
  templateUrl: './app.notfound.component.html',
  styleUrls: ['./app.notfound.component.scss']
})
export class AppNotfoundComponent {

}

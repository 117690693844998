import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {LOCSESION} from '../config/config';
import {AuthModel1, AuthModel2, AuthModel, LgnModel8} from '../models/auth';
import {StorageUtil} from '../models/util';
import {Config} from '@services/config';
import {ItemsModelMenu} from '@models/util/items.model.menu';
import {AccessModel} from '@models/access';
import {DatePipe} from '@angular/common';
import {PinModel} from '@models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  redirectUrl: string;
  private currentUserSubject: BehaviorSubject<AuthModel>;
  public currentUser: Observable<AuthModel>;
  public menu: ItemsModelMenu[];

  constructor(
    private http: HttpClient
  ) {
    this.currentUserSubject = new BehaviorSubject<AuthModel>(JSON.parse(StorageUtil.get(LOCSESION.LS_CURRENT_USER)));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): AuthModel {
    return this.currentUserSubject.value;
  }

  login(r1: AuthModel1) {
    const url: string = Config.settings.path.url_root + '/tesabiz-ws-security/login';
    return this.http.post<AuthModel2>(url, r1);
  }

  updateToken(neoTk: string) {
    const currentUser = JSON.parse(StorageUtil.get(LOCSESION.LS_CURRENT_USER));
    if (currentUser == null) {
      this.logout();
      return;
    }
    currentUser.token = neoTk;

    StorageUtil.set(LOCSESION.LS_CURRENT_USER, JSON.stringify(currentUser));
    this.currentUserSubject.next(currentUser);
  }

  updateLastConsume() {
    const datePipe = new DatePipe('en-US');
    const dateString = datePipe.transform(new Date(), 'yyyy/MM/dd hh:mm:ss');
    StorageUtil.set(LOCSESION.LS_LAST_CONSUME, dateString);
  }

  getLastConsume(): string {
    const currentUser =  StorageUtil.get(LOCSESION.LS_LAST_CONSUME);
    if (currentUser == null) {
      return '';
    }
    return currentUser;
  }

  validatePin(model: AuthModel1) {
    return this.http.post<PinModel>(`${Config.settings.path.url_root}/tesabiz-ws-security/validatepin`, model);
  }

  public setUser(authModel1: AuthModel1, authModel2: AuthModel2) {
    this.loadMenu(authModel2.menu);

    const authModel = new AuthModel();
    authModel.userLogin = authModel1.header.user;
    authModel.userName = authModel2.client.fullName;
    authModel.token = authModel2.token;
    authModel.client = authModel2.client;
    authModel.menu = this.menu;
    authModel.lastLogin = authModel2.lastLogin;
    authModel.idSession = authModel2.idSession;
    authModel.timeSession = authModel2.timeSession;
    authModel.configApp = authModel2.configApp;

    StorageUtil.set(LOCSESION.LS_CURRENT_USER, JSON.stringify(authModel));
    this.currentUserSubject.next(authModel);
  }

  public updateInfo(sale: string, purhchase: string) {
    const currentUser = JSON.parse(StorageUtil.get(LOCSESION.LS_CURRENT_USER));
    console.log(`INIT -  CURRENT: ${currentUser.client.codeBank}`);
    currentUser.sale = sale;
    currentUser.purhchase = purhchase;

    StorageUtil.set(LOCSESION.LS_CURRENT_USER, JSON.stringify(currentUser));
    this.currentUserSubject.next(currentUser);
  }

  getAuthorizationToken() {
    const currentUser = JSON.parse(StorageUtil.get(LOCSESION.LS_CURRENT_USER));
    if (currentUser == null) {
      this.logout();
      return;
    }
    return currentUser.token;
  }

  public getUser() {
    const currentUser = JSON.parse(StorageUtil.get(LOCSESION.LS_CURRENT_USER));
    if (currentUser == null) {
      this.logout();
      return;
    }
    return currentUser.userLogin;
  }

  getCurrentUser() {
    const currentUser: AuthModel = JSON.parse(StorageUtil.get(LOCSESION.LS_CURRENT_USER));
    if (currentUser == null) {
      this.logout();
      return;
    }
    return currentUser;
  }

  loadMenu(access: AccessModel[]) {
    console.log(access);
    const itModMenu = new ItemsModelMenu();
    this.menu = itModMenu.accessToItem(access);
  }

  logout() {
    StorageUtil.remove(LOCSESION.LS_CURRENT_USER);
    this.currentUserSubject.next(null);
  }
  getIP() {
    return this.http.get('https://api.ipify.org/?format=json');
  }
  getInfoAsfi() {
    return this.http.get<LgnModel8>(`${Config.settings.path.url_root}/tesabiz-ws-security/login/getasfi`);
  }
}

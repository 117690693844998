import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PaymentBreadModel} from "@models/credit";

@Component({
  selector: 'app-card-row-payment',
  templateUrl: './card-row-payment.component.html',
  styleUrls: ['./card-row-payment.component.scss']
})
export class CardRowPaymentComponent implements OnInit {
  @Input() detail: PaymentBreadModel;
  @Input() currency: string;
  @Output() selectOrder = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  select() {
    this.selectOrder.emit(this.detail);
  }
}

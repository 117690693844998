export class HistoryAccountDto {
  dateTransaction: string;
  amount: number;
  currency: string;
  gloss: string;
  type: string;
  numTransaction: string;
  numTransactionACH: string;
  concept: string;
  detailConcept: string;
  balanceBefore: string;
  balanceAfter: string;
  status: string;
  sign: string;
  accountOrigin: string;
  eifOrigin: string;
  ciOrigin: string;
  nameOrigen: string;
  nameDes: string;
  eifDes: string;
  accountDes: string;
  quotaValue: string;
  quotaAmount: string;

  constructor(dateTransaction: string, amount: number, currency: string, gloss: string, type: string, numTransaction: string,
              concept: string, detailConcept: string, balanceBefore: string, balanceAfter: string, status: string, sign: string, accountOrigin: string) {
    this.dateTransaction = dateTransaction;
    this.amount = amount;
    this.currency = currency;
    this.gloss = gloss;
    this.type = type;
    this.numTransaction = numTransaction;
    this.concept = concept;
    this.detailConcept = detailConcept;
    this.balanceBefore = balanceBefore;
    this.balanceAfter = balanceAfter;
    this.status = status;
    this.sign = sign;
    this.accountOrigin = accountOrigin;
  }

}

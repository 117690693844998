import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from '@services/config';
import {Acc1, Acc2, Acc3, Dcc1, Dcc2} from '@models/account';
import {Cd1Model, Cd2Model} from '@models/qoute';
import {TC1} from '@models/titular/tc1.model';
import {TR2} from '@models/titular/tr2.model';
import {Hts1, Hts2, Hts3, Hts4, Hts5, Hts6, Hts8} from '@models/history';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    public http: HttpClient) {
  }

  list(acc1: Acc1) {
    const url: string = Config.settings.path.url_root + '/tesabiz-ws-security/tbz/account';
    return this.http.post<Acc2>(url, acc1);
  }

  find( dcc1 : Dcc1) {
    const url: string = Config.settings.path.url_root + '/tesabiz-ws-security/tbz/account/detail';
    return this.http.post<Dcc2>(url, dcc1);
  }

  qoute(cd1Model: Cd1Model) {
    const url: string = Config.settings.path.url_root + '/tesabiz-ws-security/tbz/qoute';
    return this.http.post<Cd2Model>(url, cd1Model);
  }

  titular(tc1: TC1) {
    const url: string = Config.settings.path.url_root + '/tesabiz-ws-security/tbz/titular';
    return this.http.post<TR2>(url, tc1);
  }

  listHistoryAch(hts1: Hts1) {
    const url: string = Config.settings.path.url_root + '/tesabiz-ws-security/tbz/historyAch';
    return this.http.post(url, hts1);
  }

  listHistory(hts3: Hts3) {
    const url: string = Config.settings.path.url_root + '/tesabiz-ws-security/tbz/history';
    return this.http.post<Hts2>(url, hts3);
  }
  genHistory(hts1: Hts1) {
    const url: string = Config.settings.path.url_root + '/tesabiz-ws-security/tbz/genhistory';
    return this.http.post<Hts4>(url, hts1);
  }
  libHistory(hts5: Hts5) {
    const url: string = Config.settings.path.url_root + '/tesabiz-ws-security/tbz/libhistory';
    return this.http.post<Hts6>(url, hts5);
  }
  excel(hts1: Hts1) {
    const url: string = Config.settings.path.url_root + '/tesabiz-ws-security/tbz/excel';
    return this.http.post<Hts8>(url, hts1);
  }
  pdf(hts1: Hts1) {
    const url: string = Config.settings.path.url_root + '/tesabiz-ws-security/tbz/pdf';
    return this.http.post<Hts8>(url, hts1);
  }
}

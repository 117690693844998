import {Component, Input, EventEmitter, OnInit, Output, AfterViewInit} from '@angular/core';
import {FORMS} from '@config/config';
// @ts-ignore
import {ItemsModel} from '@models/util';

@Component({
  selector: 'app-im-select-filter',
  templateUrl: './im-select-filter.component.html',
  styleUrls: ['./im-select-filter.component.scss'],
})
export class ImSelectFilterComponent implements OnInit, AfterViewInit {
  cmpReq = FORMS.FRM_REQUIRED;
  @Input() nombre: string;
  @Input() requerido: boolean;
  @Input() placeholder: string;
  @Input() valor: string;
  @Input() itemValuesAll: ItemsModel[];
  @Output() value = new EventEmitter();

  inpfil: string;

  itemValues: ItemsModel[];

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit(){
    this.itemValues = this.itemValuesAll.filter(x => x.label != null);
    console.log('init', this.itemValues);
  }

  onFilter(filter: any) {
    this.itemValues = this.itemValuesAll.filter(x => x.label.includes(filter) || x.label.includes(filter.toUpperCase()));
  }

  onSelect(value: any) {
    this.value.emit(value);
  }
}

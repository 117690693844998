import {RequestHeaderModel} from '../requestHeader.model';
import {TR1} from './tr1.model';

export class TC1 {
  header: RequestHeaderModel;
  request: TR1;

  constructor(header: RequestHeaderModel, request: TR1) {
    this.header = header;
    this.request = request;
  }
}

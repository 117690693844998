import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '@app/services/auth.service';
import {AccountDto, Acc1} from '@app/models/account';
import {map} from 'rxjs/operators';
import {BaseModel} from '@models/util/base.model';
import {HistoricalTrazeService} from '@services/historical.traze.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {SummaryModel} from '@models/summary';
import {AccountService} from '@services/account.service';
import {Config} from "@services/config";
import {AsfiComponent} from "@app/components/modal/asfi/asfi.component";
import {ToastService} from "ng-uikit-pro-standard";
import {LgnModel8} from "@models/auth/lgn.model.8";

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent extends BaseModel implements OnInit, AfterViewInit {
  acList: AccountDto[] = [];
  acSumm: SummaryModel[] = [];
  es_una_cuenta: boolean = true;

  @ViewChild('dialog') dialog: AsfiComponent;
  constructor(
    private toast: ToastService,
    private accountService: AccountService,
              protected historical: HistoricalTrazeService,
              protected deviceService: DeviceDetectorService,
              private authService: AuthService) {
    super(authService, historical, deviceService);
  }

  ngAfterViewInit(): void {
    this.authService.getInfoAsfi().subscribe((res: LgnModel8) => {
      console.log(res);
      const visible = '' + res.list[0];
      this.dialog.linkAsfi = '' + res.list[1];
      this.dialog.urlImage = Config.settings.path.url_root + res.list[2];
      if ( visible != null && visible.includes('true') ) {
        this.dialog.onShow();
      }
    });
    }

  ngOnInit() {
    this.listarActivos();
    const user = this.authService.getCurrentUser();
    if (user.configApp !== undefined) {
      if (user.configApp.msgInfo.length > 1 ) {
        this.toast.warning(user.configApp.msgInfo);
      }
    }
  }

  listarActivos(complete?: () => void) {
    const user = this.authService.getCurrentUser();
    const header = this.getHeader(this.authService.getUser());
    const acc1 = new Acc1(header, user.client.accountCode, user.client.representativeCode);
    this.accountService.list(acc1).pipe(
      map((ac: any) => ac.list)
    ).subscribe((data: AccountDto[]) => {
        const groupByName = [];
        const currencies = data.map(v => v.currency).filter((v, i, s) => s.indexOf(v) === i);
        data.forEach(function(a) {
          groupByName[a.currency] = groupByName[a.currency] || [];
          groupByName[a.currency].push(a);
          groupByName.length = groupByName.length + 1;
        });
        if (currencies.length > 1){
          this.es_una_cuenta = false;
        }
        for (let i = 0, len = currencies.length; i < len; i++) {
          const acc: AccountDto[] = groupByName[currencies[i]];
          console.log('cuenta: ' + acc[0].currency);
          acc.forEach(x => console.log(x));
          const summ = new SummaryModel();
          summ.cant = acc.length;
          summ.monto = 0.0;
          summ.titulo = acc[0].currency.includes('B') ? 'Bolivianos' : 'Dólares';
          summ.moneda = acc[0].currency.includes('B') ? 'Bs.' : 'USD';
          summ.icono = acc[0].currency.includes('B') ? 'coins' : 'dollar-sign';
          summ.color = 'back-primary';
          acc.forEach(acc => { // par cada cuenta de la moneda
             const repl = acc.available.split(',').join('');
             const num = Number.parseFloat(repl);
             summ.monto = summ.monto + num;
          });
          summ.monto = Number.parseFloat(summ.monto.toFixed(2));
          this.acSumm.push(summ);
        }
      }, error => {
        console.log(error);
      },
      complete);
  }
}

import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {VERSION} from "../../../../environments/version";

@Component({
  selector: 'app-power',
  templateUrl: './power.component.html'
})
export class PowerComponent implements OnInit {

  year: number;
  _VERSION;
  constructor(private router: Router) {
    this._VERSION =  VERSION.hash;
  }

  ngOnInit() {
    this.year = new Date().getFullYear();
  }
}

import {RequestHeaderModel} from '@models/requestHeader.model';

export class Crt9Model {
  header: RequestHeaderModel;
  codigoCliente: string;
  numeroCredito: string;
  representativeCode: string;
  canal: number;


  constructor(header: RequestHeaderModel, codigoCliente: string, numeroCredito: string, representativeCode: string , canal: number) {
    this.header = header;
    this.codigoCliente = codigoCliente;
    this.numeroCredito = numeroCredito;
    this.representativeCode = representativeCode;
    this.canal = canal;
  }
}

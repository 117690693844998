import {TxDto} from '@models/transfer/TxDto.model';
import {RequestHeaderModel} from '@models/requestHeader.model';

export class Tx1 {
  header: RequestHeaderModel;
  txDto: TxDto;
  pin: string;

  constructor(header: RequestHeaderModel, txDto: TxDto, pin: string) {
    this.header = header;
    this.txDto = txDto;
    this.pin = pin;
  }
}

import {ClientModel, ConfigAppModel} from '@models/client';
import {AccessModel} from '@models/access';
import {ResponseHeaderModel} from '@models/responseHeader.model';

export class AuthModel2 {
  header: ResponseHeaderModel;
  client: ClientModel;
  token: string;
  menu: AccessModel[];
  lastLogin: string;
  idSession: string;
  configApp: ConfigAppModel;
  timeSession: string;
}

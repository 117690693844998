import {BeneficiaryModel} from '@models/beneficiary';
import {RequestHeaderModel} from '@models/requestHeader.model';

export class Tx3 {
  header: RequestHeaderModel;
  beneficiary: BeneficiaryModel;

  constructor(header: RequestHeaderModel, beneficiary: BeneficiaryModel) {
    this.header = header;
    this.beneficiary = beneficiary;
  }
}

import {
  AfterViewInit,
  Component, Directive, ElementRef,
  EventEmitter,
  Input,
  Output, ViewChild
} from '@angular/core';
import {MDBModalRef, ModalDirective} from 'ng-uikit-pro-standard';
import {FORMS} from '@config/config';

@Component({
  selector: 'app-confirm-key',
  templateUrl: './confirm-key.component.html',
  styleUrls: ['./confirm-key.component.scss']
})

export class ConfirmKeyComponent {
  @ViewChild('confirm') public modModal: ModalDirective;
  @ViewChild('kkey') public keyy: ElementRef;
  @Input() text: string;
  @Output() success = new EventEmitter();
  @Output() show = new EventEmitter();
  @Output() hide = new EventEmitter();

  submit = false;
  key: string;
  eyePass: boolean;
  cmpReq = FORMS.FRM_REQUIRED;

  constructor() {
    this.submit = false;
  }

  onOk(): void {
    this.submit = true;
    this.success.emit(this.key);
  }

  onShow(): void {
    this.submit = false;
    this.show.emit();
    this.modModal.show();
    this.key = '';
  }

  onHide(): void {
    this.submit = false;
    this.hide.emit();
    this.modModal.hide();
    this.key = '';
  }

  clear(): void {
    this.key = '';
  }


  togogleEyePass(){
    this.eyePass = !this.eyePass;
  }
}

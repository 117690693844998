<div *ngIf="visiblePreload">
  <app-preloader></app-preloader>
</div>
<div class="login-body">
  <div>
    <div class="btn_back" (click)="backUser()" *ngIf="viewPass">
      <mdb-icon fas icon="chevron-left" class="bb_icon"></mdb-icon>
    </div>
    <section>
      <app-loader></app-loader>
      <div style="padding: 40px">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div>
            <div class="text-black">
              <div class="row g-0">
                <div class="card p-md-5 mx-md-4" style="width: 500px;">
                  <div class="text-center class_logotipo">
                    <img src="assets/util/images/tesabiz.png" alt="logo">
                  </div>


                  <form #frm2="ngForm" (ngSubmit)="onLogin()">
                    <div class="md-form mb-4">
                      <div class="md-form mb-4 login-inputs">
                        <input #user="ngModel"
                               [(ngModel)]="usu"
                               name="usu"
                               [class]="eyeUser? '' : 'secure-input'"
                               id="id_usu"
                               type="text"
                               class="form-control cls_input "
                               mdbInput
                               required
                               maxlength="50"
                               autocomplete="off"
                               validateLoginUser
                               mdbValidate/>
                        <label  for="id_usu" style="font-size: 14px;">{{'login_user' | translate}} *</label>
                        <mdb-icon *ngIf="eyeUser"
                                  (click)="togogleEyeUser()" far icon="eye"
                                  class="color_secundario cls_hover cls_eye"></mdb-icon>
                        <mdb-icon *ngIf="!eyeUser"
                                  (click)="togogleEyeUser()" far icon="eye-slash"
                                  class="color_secundario cls_hover cls_eye"></mdb-icon>
                        <div *ngIf="!user.valid && (user.dirty || user.touched)">
                          <mdb-error *ngIf="user.hasError('required')" style="top:26px">{{cmpReq}}</mdb-error>
                          <mdb-error *ngIf="user.hasError('minlength')" style="top:26px">{{cmpMin}} 4</mdb-error>
                        </div>
                      </div>


                      <div class="login-inputs" style="position: relative;">
                        <input #password="ngModel"
                               [(ngModel)]="pass"
                               name="pass"
                               [type]="eyePass? 'text' : 'password'"
                               id="idpass"
                               class="form-control cls_input"
                               mdbInput
                               required
                               maxlength="15"
                               minlength="5"
                               mdbValidate
                               autocomplete="off">
                        <label for="idpass" style="font-size: 14px;">{{'login_pass' | translate}} *</label>
                        <mdb-icon *ngIf="eyePass"
                                  (click)="togogleEyePass()" far icon="eye"
                                  class="color_secundario cls_hover cls_eye"></mdb-icon>
                        <mdb-icon *ngIf="!eyePass"
                                  (click)="togogleEyePass()" far icon="eye-slash"
                                  class="color_secundario cls_hover cls_eye"></mdb-icon>
                      </div>
                      <div *ngIf="!password.valid && (password.dirty || password.touched)">
                        <mdb-error *ngIf="password.hasError('required')" style="top:98px">{{cmpReq}}</mdb-error>
                      </div>
                    </div>

                    <div class="md-form mb-4" align="center">
                      <app-captcha #ocaptcha
                                   [ancho]="250"
                                   [alto]="80"
                                   [color]="'#122050'"
                                   [cad]="captcha">
                      </app-captcha>
                    </div>
                    <div class="md-form mb-4 login-inputs">

                      <input #icaptcha="ngModel"
                             [(ngModel)]="captcha"
                             name="captcha"
                             id="idcapcha"
                             type="text"
                             class="form-control cls_input"
                             mdbInput
                             required
                             maxlength="10"
                             validateAlphaNumeric
                             autocomplete="off"
                             mdbValidate/>
                      <label for="idcapcha" style="font-size: 14px;">Ingrese el C&oacute;digo del Captcha*</label>
                      <div *ngIf="icaptcha.invalid && (icaptcha.dirty || icaptcha.touched)">
                        <mdb-error *ngIf="icaptcha.hasError('required')" style="top:30px">{{cmpReq}}</mdb-error>


                      </div>
                    </div>

                    <div class="color_terciario class_power cls_center">
                      <button class="gradient-custom-2 W100 cls_font_bold"
                              mdbBtn
                              mdbWavesEffect
                              [disabled]="!frm2.valid || submitted"
                              type="submit">{{'login_login' | translate}}
                      </button>
                      <br>
                      <br>
                      © {{year}} {{'login_powerby' | translate}}:
                      <a href="http://www.tesabiz.com/" target="_blank" class="cls_logo_powerby" >
                        <img src="assets/util/images/tesabiz2.png" alt="GFF">
                      </a>
                      <br>
                      <label class="cls_version">V-{{_VERSION}}</label>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


  </div>
</div>



<app-notify></app-notify>

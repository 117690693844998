import {RequestHeaderModel} from '@models/requestHeader.model';

export class Hts3 {
  header: RequestHeaderModel;
  canal: number;
  clave: string;
  pagina: number;
  tamPagina: number;

  constructor(header: RequestHeaderModel, canal: number, clave: string, pagina: number, tamPagina: number) {
    this.header = header;
    this.canal = canal;
    this.clave = clave;
    this.pagina = pagina;
    this.tamPagina = tamPagina;

  }
}

import {PaymentCreditModel} from '@models/credit/payment.credit.model';
import {RequestHeaderModel} from '@models/requestHeader.model';

export class Cre1Model {
  header: RequestHeaderModel;
  paymentDto: PaymentCreditModel;
  tokenTx: string;

  constructor(header: RequestHeaderModel, paymentDto: PaymentCreditModel, tokenTx: string) {
    this.header = header;
    this.paymentDto = paymentDto;
    this.tokenTx = tokenTx;
  }
}

import {Routes, RouterModule} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import {AuthGuard} from './guard/auth.guard';
import {AppErrorComponent} from '@pages/control/app.error.component';
import {AppNotfoundComponent} from '@pages/control/app.notfound.component';
import {AppMainComponent} from '@app/app.main.component';
import {LoginComponent} from '@pages/auth/login/login.component';
import {SummaryComponent} from '@pages/summary/summary.component';
import {BeneficiaryComponent} from '@pages/beneficiary/beneficiary.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {ConsultsComponent} from '@pages/consults/consults.component';
import {TransferComponent} from '@pages/transfer/transfer.component';
import {PaycreditComponent} from "@pages/credit/paycredit/paycredit.component";
import {PaymentPlanComponent} from "@pages/credit/payment-plan/payment-plan.component";
import {DetailComponent} from "@pages/credit/detail/detail.component";
import {DpfComponent} from "@pages/dpf/dpf.component";
import {HistorialComponent} from "@pages/historial/historial.component";
import {OfficeComponent} from "@pages/office/office.component";



export const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: 'summary'},
  {
    path: 'home',
    component: AppMainComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', component: LoginComponent}
    ]
  },
  {
    path: 'summary',
    component: AppMainComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', redirectTo: 'view', pathMatch: 'full'},
      {path: 'view', component: SummaryComponent}
    ]
  },
  {
    path: 'account',
    component: AppMainComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', redirectTo: 'history', pathMatch: 'full'},
      {path: 'history', component: ConsultsComponent}
    ]
  },
  // {
  //   path: 'beneficiary',
  //   component: AppMainComponent,
  //   canActivate: [AuthGuard],
  //   children: [
  //     {path: '', redirectTo: 'list', pathMatch: 'full'},
  //     {path: 'list', component: BeneficiaryComponent}
  //   ]
  // },
  {
    path: 'profile',
    component: AppMainComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', component: ProfileComponent},
    ]
  },
  {
    path: 'transfer',
    component: AppMainComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', redirectTo: 'create', pathMatch: 'full'},
      {path: 'create', component: TransferComponent},
      {path: 'view', component: HistorialComponent},
      {path: 'beneficiary', component: BeneficiaryComponent}
    ]
  },
  {
    path: 'credit',
    component: AppMainComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', redirectTo: 'payment', pathMatch: 'full'},
      {path: 'payment', component: PaycreditComponent},
      {path: 'detail', component: DetailComponent},
      {path: 'paymentBread', component: PaymentPlanComponent}
    ]
  },
  {
    path: 'dpf',
    component: AppMainComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', redirectTo: 'list', pathMatch: 'full'},
      {path: 'list', component: DpfComponent},
    ]
  },
  {
    path: 'office',
    component: AppMainComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', redirectTo: 'list', pathMatch: 'full'},
      {path: 'list', component: OfficeComponent}
    ]
  },
  {path: 'login', component: LoginComponent},
  {path: 'error', component: AppErrorComponent},
  {path: '404', component: AppNotfoundComponent},
  {path: '**', redirectTo: '/404'},


];

export const AppRoutes: ModuleWithProviders<any> = RouterModule.forRoot(routes, {
  scrollPositionRestoration: 'enabled'
});

import {AccessModel} from '@models/access';

export class ItemsModelMenu {
    label: string;
    icon?: string;
    routerLink?: string;
    items: ItemsModelMenu[] = [];

    accessToItem(items: AccessModel[]): ItemsModelMenu[] {
      let list: ItemsModelMenu[];
      if (items === null || items.length === 0) {
        return list;
      }
      list = [];
      let model: ItemsModelMenu;
      for (const item of items) {
        if (item.parentId > 0 && (item.icon == null || item.icon === '')) {
          return null;
        }

        model = new ItemsModelMenu();
        model.label = item.name;
        model.icon = item.icon;
        model.routerLink = item.path != null ? item.path : '';
        model.items = this.accessToItem(item.list);
        list.push(model);
      }
      return list;
    }
}

import {Component, Input, OnInit} from '@angular/core';
import {FORMS} from "@config/config";
import {CreditModel} from "@models/credit";

@Component({
  selector: 'app-card-credit-detail',
  templateUrl: './card-credit-detail.component.html',
  styleUrls: ['./card-credit-detail.component.scss']
})
export class CardCreditDetailComponent implements OnInit {
  @Input() credit: CreditModel;
  @Input() selected: boolean;
  cmpReq = FORMS.FRM_REQUIRED;
  cmpMin = FORMS.FRM_MINIMO;
  submitted = false;
  constructor() { }

  ngOnInit(): void {
  }

}

<div [class.hidden]="!show">
  <div *ngIf="show" class="loader-overlay">
    <div class="loader">
      <div class="spinner-grow color_primario" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow color_secundario" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow color_terciario" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>

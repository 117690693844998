import {BeneficiaryModel} from '@models/beneficiary/beneficiary.model';

export class BankbeneModel {
  eif: string;
  eifDes: string;
  count: number;
  list: BeneficiaryModel[];

  constructor(list: BeneficiaryModel[], eif?: string, eifDes?: string, count?: number) {
    this.list = list;
    this.eif = eif;
    this.eifDes = eifDes;
    this.count = count;
  }
}

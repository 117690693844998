<app-menu *ngIf="!specialPage">
</app-menu>

<div class="body2">
  <main [ngClass]="{'py-0 px-0 my-0 mx-0': specialPage}" style="padding-top: 60px;">
    <app-loader></app-loader>
    <div [class.container-fluid]="!specialPage">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>
<app-footer *ngIf="!specialPage"></app-footer>
<app-notify></app-notify>


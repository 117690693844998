<div class="card_bene">
  <div class="card_data" (click)="select()">
    <div class="card_bene_header">
      <b>{{name}}</b><br/>
      <span class="FZ8">{{nameBank}}</span>
    </div>
    <div class="card_bene_body MT-5">
      <span>{{'c_ben_cuenta' | translate}}: </span><b>{{numberAccount}}</b>
    </div>
    <div class="card_bene_header MT-6">
      <span>{{'ben_alias' | translate}}: </span><b>{{aliasName}}</b>
    </div>
  </div>
  <div class="card_bene_accion">
    <div class="card_bene_btn">
      <svg-icon (click)="edit()" src="assets/util/images/icons/edit.svg"
                [svgStyle]="{ 'height.px':25}">
      </svg-icon>
      <svg-icon (click)="remove()" src="assets/util/images/icons/trash.svg"
                [svgStyle]="{ 'height.px':25}">
      </svg-icon>
    </div>
  </div>
</div>

import {RequestHeaderModel} from '@models/requestHeader.model';
export class Dcc1 {
  header: RequestHeaderModel;
  canal: number;
  accountCode: string;
  numeroCuenta: string;
  representativeCode: string;
  constructor(header: RequestHeaderModel, canal: number, accountCode: string, numeroCuenta: string, representativeCode: string) {
    this.header = header;
    this.canal =  canal;
    this.accountCode = accountCode;
    this.numeroCuenta =  numeroCuenta;
    this.representativeCode = representativeCode;
  }
}

<div *ngIf="show">
  <div class="notify-overlay">
    <div class="notify">
      <div class="notify_body">
        <span>{{text}}</span>
        <div style="height: 40px"></div>
        <button mdbBtn
                class="btn-notify PR10"
                (click)="hide()"
                mdbWavesEffect
                type="button">{{'ok' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="pages_title">
    <i class="fas fa-map-marker-alt col-secondary pr-2 fa-2x" aria-hidden="true"></i>
    <span class="col-secondary_dark title h4 my-4">Nuestras Oficinas</span>
  </div>

  <div class="row">
    <div class="col-md-6 col-12">
      <div class="md-form">
        <mdb-select #dpto="ngModel"  class="form-control cls_select cls_select_white" (closed)="handleDropdownChange($event)" [options]="dptoSelect"
                    [placeholder]='"Seleccione"' id="dpto" name="dpto" [(ngModel)]="dptoSelected">
        </mdb-select>
        <label for="dpto">Departamento</label>
      </div>
    </div>
    <div class="col-md-6 col-12">
      <div class="md-form">
        <mdb-select  class="form-control cls_select cls_select_white" #type="ngModel" (closed)="handleDropdownChange($event)" [options]="typeSelect"
                    [placeholder]='"Seleccione"' id="type" name="type" [(ngModel)]="typeSelected" [multiple]="true"
                    [highlightFirst]="false" selectAllLabel="Todos">
        </mdb-select>
        <label for="type">Tipo</label>
      </div>
    </div>
  </div>

  <div class="row justify-content-center my-row">
    <div class="col-sm-12 col-md-5">
      <table #mytable mdbTable mdbTableScroll scrollY="true" maxHeight="490" bordered="true" table-striped>
        <tbody>
        <tr *ngFor="let item of list; let i = index">
          <th class="flex-column" scope="row">
            <div class="d-flex w-100 justify-content-between">
              <h5>
                <mdb-icon class="col-primary" fas icon="{{getIcon(item.officeId)}}"></mdb-icon> {{item.nombre}}
              </h5>
              <small>{{getlabel(item.tipo)}}</small>
            </div>
            <div class="row">
              <div class="col-9">
                <p class="mb-1">
                  <mdb-icon fas icon="map-marker-alt"></mdb-icon> {{item.direccion}}
                </p>
                <p class="mb-1"><small>
                  <mdb-icon far icon="clock"></mdb-icon> Horarios: {{item.horario}}
                </small> <br>
                  <small>
                    <mdb-icon far icon="address-card"></mdb-icon> Contacto: {{item.contacto}}
                  </small></p>
              </div>
              <div class="col-3 align-bottom">
                <button mdbBtn (click)="setCenterOnClick(item.officeId)" mdbTooltip="Ver en Mapa" placement="left"
                        style="float: inline-end; margin-top: 25px;" class="btn btn-rounded btn-secundario btn-sm"
                        mdbWavesEffect>
                  <mdb-icon fas icon="eye" size="lg"></mdb-icon>
                </button>
              </div>
            </div>

          </th>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="col-sm-12 col-md-7">
      <agm-map style="height: 500px;" (centerChange)="centerchange($event)" [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false"
               [zoomControl]="false">
        <agm-marker *ngFor="let m of markers; let i = index" [latitude]="m.lat" [longitude]="m.lng" [label]="m.label"
                    [iconUrl]="m.icon">
        </agm-marker>
      </agm-map>
    </div>
  </div>
</div>

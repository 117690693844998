import {Component, Input, OnInit} from '@angular/core';
import {FORMS} from '@config/config';
// @ts-ignore
import {ItemsModel} from '@models/util';

@Component({
  selector: 'app-im-select',
  templateUrl: './im-select.component.html'
})
export class ImSelectComponent implements OnInit {
  cmpReq = FORMS.FRM_REQUIRED;
  @Input() filtro: string;
  @Input() nombre: string;
  @Input() requerido: boolean;
  @Input() tamano: string;
  @Input() descripcion: string;
  @Input() adicional: string;
  content: string;

  itemValues: ItemsModel[] = [];

  constructor() {
  }

  ngOnInit() {
    this.listValues();
  }

  listValues() {
    try {
      if (this.adicional.includes('|')) {
        const items = this.adicional.split(',');
        for (const item of items) {
          const objeto = item.split('|');

          const model: ItemsModel = new ItemsModel(objeto[1], objeto[0]);
          this.itemValues.push(model);
        }
      } else {
        const items = this.adicional.split(',');
        for (const item of items) {
          const model: ItemsModel = new ItemsModel(item, item);
          this.itemValues.push(model);
        }
      }
    }catch (e) {
      return new Error('Error en el listado:' + this.nombre);
    }
  }
}

import { Component, OnInit } from '@angular/core';
import {BaseModel} from "@models/util/base.model";
import {AuthService} from "@services/auth.service";
import {DomainService} from "@services/domain.service";
import {TranslateService} from "@ngx-translate/core";
import {HistoricalTrazeService} from "@services/historical.traze.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {MessageService} from "@app/error/message.service";
import {BeneService} from "@pages/beneficiary/bene.service";
import {AccountService} from "@services/account.service";
import {CreditService} from "@services/credit.service";
import {Router} from "@angular/router";
import {Acc1} from "@models/account";
import {DatePipe} from "@angular/common";
import {HistoryAccountDto, Hts1} from "@models/history";
import {CONFIG, FORMS} from "@config/config";
import {IMyOptions, ModalDirective} from "ng-uikit-pro-standard";
import * as moment from 'moment';
import { parse } from 'date-fns';

@Component({
  selector: 'app-historial',
  templateUrl: './historial.component.html',
  styleUrls: ['./historial.component.scss']
})
export class HistorialComponent extends BaseModel implements OnInit{
  cmpReq = FORMS.FRM_REQUIRED;
  cmpMin = FORMS.FRM_MINIMO;
  fecha_sdf = CONFIG.fecha_sdf;
  fecha_hora_sdf = CONFIG.fecha_hora_sdf;
  submitted = false;
  list: HistoryAccountDto[] = [];
  selectOrderView: HistoryAccountDto;
  checkTypeFilter = false;

  myDatePickerOptions: IMyOptions;
  locale;

  page = 1;
  perPage = 10;
  pagesToShow = 5;
  cantidadTransacciones: number;
  filteredData: HistoryAccountDto[] = [];

  dateAct = new Date();
  dateActString = new Date().toString();
  constructor(
    protected authService: AuthService,
    protected domainService: DomainService,
    private translate: TranslateService,
    protected historical: HistoricalTrazeService,
    protected deviceService: DeviceDetectorService,
    private messageService: MessageService,
    private beneService: BeneService,
    public accountService: AccountService,
    private router: Router
  ) {
    super(authService, historical, deviceService);
    super.registerEvent('lista de transacciones interbanarias', this.router.url);
    this.locale = 'es';
    this.datePipe = new DatePipe('en-US');
    this.dateIni = new Date();
    this.dateIni.setDate( this.dateIni.getDate() );
    this.dateIni.setHours(0, 0, 0);
    this.dateEnd = new Date();
    this.dateEnd.setHours(0, 0, 0);

  }
  dateIni = new Date();
  dateEnd = new Date();
  datePipe: DatePipe;
  isLoading: boolean;
  today = new Date();
  ngOnInit(): void {
    this.onList();
    this.load();
  }

  load() {
    this.myDatePickerOptions = {
      // Format
      dateFormat: 'dd/mm/yyyy',

      // Year limits
      minYear: new Date().getFullYear() - 20,
      maxYear: new Date().getFullYear(),

      height: '70px',
      width: '30%',
      selectionTxtFontSize: '15px'
    };
  }
  onList() {
    const user = this.authService.getCurrentUser();
    const header = this.getHeader(user.userLogin);
    if (!(typeof this.dateIni === 'object' && this.dateIni instanceof Date)) {
      this.dateIni = parse(this.dateIni, 'dd/MM/yyyy', new Date());
    }
    if (!(typeof this.dateEnd === 'object' && this.dateEnd instanceof Date)) {
      this.dateEnd = parse(this.dateEnd, 'dd/MM/yyyy', new Date());
    }
    const hts1 = new Hts1(header, '',
      user.client.accountCode, null, null, this.datePipe.transform(this.dateIni,  'yyyyMMdd'),
      this.datePipe.transform(this.dateEnd, 'yyyyMMdd'), user.client.representativeCode);

    this.isLoading = true;
    if (this.dateIni instanceof Date) {
      hts1.dateInit = this.datePipe.transform(this.dateIni, 'yyyyMMdd');
    } else {
      hts1.dateInit = moment(this.dateIni, 'DD/MM/YYYY').format('YYYYMMDD');
    }
    if (this.dateEnd instanceof Date) {
      hts1.dateEnd = this.datePipe.transform(this.dateEnd, 'yyyyMMdd');
    } else {
      hts1.dateEnd = moment(this.dateEnd, 'DD/MM/YYYY').format('YYYYMMDD');
    }
    if (hts1.dateInit > hts1.dateEnd) {
      this.messageService.showWarning(this.translate.instant('date_ini_end'));
      return;
    }
    this.dateActString = this.datePipe.transform(this.dateAct, 'yyyyMMdd');
    if (hts1.dateInit > this.dateActString || hts1.dateEnd > this.dateActString) {
      this.messageService.showWarning(this.translate.instant('date_bigger_today'));
      return;
    }
    console.log('REQUEST_HISTORY', hts1);
    this.list = null;
    this.accountService.listHistoryAch(hts1)
      .subscribe((resp: any) => {
        console.log('RESPONSE_HISTORY', resp);
        if (resp.list != null && resp.list.length > 0) {
          this.list = resp.list;

          const startIndex = (this.page - 1) * this.perPage;
          const endIndex = startIndex + this.perPage;
          this.filteredData = this.list.slice(startIndex, endIndex);

          this.cantidadTransacciones = this.list.length;
          /*  this.list.forEach(element => {
             element.status = 'TRANSACCION PROCESADA'
           }); */
        } else {
        }
      }), error => {
      this.messageService.showInfo(this.translate.instant('history_empty_list'));
      }, () => {
        this.isLoading  = false;
      };
  }
  selectedOrderView(order: HistoryAccountDto, modal: ModalDirective) {
    this.selectOrderView = order;
    console.log(this.selectOrderView);
    modal.show();
  }
  changePage(paginado: any) {
    console.log('PAGINADO: ' + paginado.currentPage);
    this.page = paginado.currentPage;
    this.perPage = paginado.pageSize;
    this.onList();
  }
}

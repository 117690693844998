<h2>Perfil</h2>

<div class="container layout_container">
  <div class="">
    <div class="justify-content-center align-items-center">
      <div class="">
        <div class="row g-0">
          <div class="card-body p-md-5 mx-md-4">
            <br/>
            <div class="W100 cls_title_pro">
              <div class="cls_border_b" style="width: 100%; display: inline-block;">
                <div style="width: 50%" class="FL color_secundario"><b>{{'prof_user' | translate}}</b></div>
                <div class="FL content_float">{{user}}</div>
              </div>
              <div class="cls_border_b" style="width: 100%; display: inline-block">
                <div style="width: 50%" class="FL color_secundario"><b>{{'prof_ci' | translate}}</b></div>
                <div class="FL content_float">{{client.ci}}</div>
              </div>
              <div class="cls_border_b" style="width: 100%; display: inline-block">
                <div style="width: 50%" class="FL color_secundario"><b>{{'prof_phone' | translate}}</b></div>
                <div class="FL content_float">{{client.phoneNumber}}</div>
              </div>
              <div class="cls_border_b" style="width: 100%; display: inline-block">
                <div style="width: 50%" class="FL color_secundario"><b>{{'prof_fecha_cont' | translate}}</b></div>
                <div class="FL content_float">{{client.contractDate | date: fecha_sdf }}</div>
              </div>
              <div class="cls_border_b" style="width: 100%; display: inline-block">
                <div style="width: 50%" class="FL color_secundario"><b>{{'prof_aniversario' | translate}}</b></div>
                <div class="FL content_float">{{client.birthDate | date: fecha_sdf }}</div>
              </div>
              <div class="cls_border_b" style="width: 100%; display: inline-block">
                <div style="width: 50%" class="FL color_secundario"><b>{{'prof_last_login' | translate}}</b></div>
                <div class="FL content_float">{{lastlogin | date: fecha_hora_sdf}}</div>
              </div>
            </div>
            <br/><br/>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

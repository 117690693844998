import {CargosModel} from "@models/credit/cargos.model";

export class CreditDetailModel {
  fecha: string;
  numeroDocumento: string;
  descripcion: string;
  capital: string;
  interes: string;
  saldo: string;
  totalPagado: string;
  totalCargos: string;
  cargos: CargosModel[];

  clone(c: CreditDetailModel): CreditDetailModel {
    const model: CreditDetailModel = new CreditDetailModel();
    model.fecha = c.fecha;
    model.numeroDocumento = c.numeroDocumento;
    model.descripcion = c.descripcion;
    model.capital = c.capital;
    model.interes = c.interes;
    model.totalPagado = c.totalPagado;
    model.totalCargos = c.totalCargos;
    model.cargos = c.cargos;
    return model;
  }
}

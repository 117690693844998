import {ClientModel, ConfigAppModel} from '@models/client';
import {ItemsModelMenu} from '@models/util/items.model.menu';

export class AuthModel {
  userLogin: string;
  userPwd?: string;
  userName: string;
  token: string;
  client?: ClientModel;
  menu?: ItemsModelMenu[];
  sale?: string;
  purhchase?: string;
  lastLogin?: string;
  idSession?: string;
  configApp?: ConfigAppModel;
  timeSession?: string;
}

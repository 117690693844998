export class ConfigAppModel {
  msgOrigin: string;
  msgDest: string;
  maxValue: number;
  isShow: boolean;
  visibleCard: string;
  validJoint: string;
  msgValidJoint: string;
  msgInfo: string;
}

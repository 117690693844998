import {Component, OnInit} from '@angular/core';
import {AuthService} from '@services/auth.service';
import {ClientModel} from '@models/client';
import {CONFIG} from '@config/config';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {

  client: ClientModel;
  user: string;
  fecha_sdf = CONFIG.fecha_sdf;
  fecha_hora_sdf = CONFIG.fecha_hora_sdf;
  lastlogin: string;
  constructor(
    protected authService: AuthService
  ) {
  }

  ngOnInit() {
    this.lastlogin = this.authService.getCurrentUser().lastLogin;
    this.client = this.authService.getCurrentUser().client;
    this.user = this.authService.getUser();
  }
}

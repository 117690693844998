<div class="col-md-12">
  <div class="md-form">
    <label class="active">{{nombre}} {{requerido ? '(*)' : ''}}</label>
    <mdb-select #imselect="ngModel"
                [options]="itemValues"
                placeholder="- Seleccione una opci&oacute;n-"
                name="input"
                [(ngModel)]="content"
                [required]="requerido">
    </mdb-select>
    <mdb-error
      *ngIf="!imselect.valid && (imselect.dirty || imselect.touched)">{{cmpReq}}
    </mdb-error>
  </div>
</div>

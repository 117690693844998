export class DomainModel {
    constructor(

        public domainTbz: string,
        public domName?: string,
        public domValue?: string,
        public description?: string,
        public encrypted?: number,
        public lastUser?: string,
        public lastTime?: Date,
        public domainId?: number
    ) {}
}

import {PaymentDto2} from './paymentDto2.model';
import {RequestHeaderModel} from './../requestHeader.model';

export class Cre17 {
  header: RequestHeaderModel;
  paymentDto: PaymentDto2;
  tokenTx: string;
  representativeCode: string;

  constructor(header: RequestHeaderModel, payment: PaymentDto2, token: string, representativeCode: string) {
    this.header = header;
    this.paymentDto = payment;
    this.tokenTx = token;
    this.representativeCode = representativeCode;
  }
}

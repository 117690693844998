export class EstadoSolicitudModel  {
  nroSolicitudCredito: string;
  fechaSolicitud: string;
  montoSolicitado: string;
  estado: string;
  glosa: string;


  constructor(nroSolicitudCredito: string, fechaSolicitud: string, montoSolicitado: string, estado: string, glosa: string) {
    this.nroSolicitudCredito = nroSolicitudCredito;
    this.fechaSolicitud = fechaSolicitud;
    this.montoSolicitado = montoSolicitado;
    this.estado = estado;
    this.glosa = glosa;
  }
}

import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgxCaptureService} from 'ngx-capture';
import {tap} from 'rxjs/operators';
import {STATUS} from '@config/config';
import { TYPE_TRANS } from '../../../config/config';

@Component({
  selector: 'app-card-comprobante',
  templateUrl: './card.comprobante.component.html',
  styleUrls: ['./card.comprobante.component.scss'],
})
export class CardComprobanteComponent implements OnInit {

  @Input() nombreOri: string;
  @Input() cuentaOri: string;
  @Input() bancoOri: string;

  @Input() nombreDes: string;
  @Input() cuentaDes: string;
  @Input() bancoDes: string;

  @Input() estado: string;
  @Input() importe: number;
  @Input() moneda: string;
  @Input() glosa: string;
  @Input() fechaTransaccion: Date;
  @Input() txNumberCore: string;
  @Input() numeroTransaccionACH: string;

  @Input() complete: boolean;

  @Input() quotaValue: string;
  @Input() quotaAmount: string;
  @Input() type_trans: string;
  @Input() sign: string;
   

  @ViewChild('screen', {static: true}) screen: any;

  RECHAZADA = STATUS.Rechazada;
  ANULADA = STATUS.Anulada;
  CONFIRMADA = STATUS.Confirmada;
  TYPETRANS = TYPE_TRANS.TTRA_ACH.toLowerCase();

  constructor(
    private captureService: NgxCaptureService,
  ) {
  }

  ngOnInit() {
    console.log("tipo:::", this.type_trans);
    if (this.fechaTransaccion == null) {
      this.fechaTransaccion = new Date();
    }
  }

  onCapture() {
    this.captureService.getImage(this.screen.nativeElement, true)
      .pipe(
        tap(img => {
          this.downloadCapture(img, 'transaction_' + this.txNumberCore);
        })
      ).subscribe();
  }

  downloadCapture(base64String, fileName) {
    const link = document.createElement('a');
    link.href = base64String;
    link.download = `${fileName}.png`;
    link.click();
  }
}

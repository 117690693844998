<div class="col-md-12">
  <div class="md-form">
    <label [for]="'idimtextarea_'+filtro" style="position: relative;">{{nombre}} {{requerido ? '(*)' : ''}}</label>
    <textarea #imtextarea="ngModel"
              type="text"
              [id]="'idimtextarea_'+filtro"
              class="form-control"
              name="imtextarea"
              [(ngModel)]="content"
              [required]="requerido"
              [mdbTooltip]="descripcion"
              [maxlength]="tamano"
              pInputTextarea
              [rows]="adicional"
              autocomplete="off">
    </textarea>
    <div *ngIf="!imtextarea.valid && (imtextarea.dirty || imtextarea.touched)">
      <mdb-error *ngIf="imtextarea.hasError('required')">{{cmpReq}}</mdb-error>
    </div>
  </div>
</div>

import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { APP_INITIALIZER, ErrorHandler, NgModule, NO_ERRORS_SCHEMA, Component } from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';
import {AppRoutes} from './app.routes';
import {NgxCaptureModule} from 'ngx-capture';


import {
  MDBSpinningPreloader,
  MDBBootstrapModulesPro,
  ToastModule,
  MDBRootModule,
  MDBBootstrapModule
} from 'ng-uikit-pro-standard';

import {RouterModule} from '@angular/router';
import {AppMainComponent} from '@app/app.main.component';
import {LoaderComponent} from '@app/components/loader/loader.component';
import {Config} from '@services/config';
import {EncryptService} from '@services/encrypt.service';
import {AuthInterceptor, ErrorInterceptor, LoaderInterceptorService} from '@app/http-interceptors';
import {GlobalErrorHandler} from '@app/error/global-error-handler';
import {ImNumberComponent} from '@app/components/input-model/im-number/im-number.component';
import {ImTextComponent} from '@app/components/input-model/im-text/im-text.component';
import {ImCheckComponent} from '@app/components/input-model/im-check/im-check.component';
import {ImSelectComponent} from '@app/components/input-model/im-select/im-select.component';
import {ConfirmComponent} from '@app/components/confirm/confirm.component';
import {AppErrorComponent} from '@pages/control/app.error.component';
import {AppNotfoundComponent} from '@pages/control/app.notfound.component';
import {
  AlphaNumericDirective,
  AlphaNumericSpaceDirective,
  AlphaSpaceDirective, ClickStopDirective,
  LoginUserDirective,
  NumericDirective
} from '@app/components/directives';
import {ImTextAreaComponent} from '@app/components/input-model/im-text-area/im-text-area.component';
import {LocationStrategy, HashLocationStrategy, registerLocaleData, DatePipe, CurrencyPipe} from '@angular/common';
import localeEs from '@angular/common/locales/es';
import {LoginComponent} from '@pages/auth/login/login.component';
import {FooterComponent} from '@pages/template/footer/footer.component';
import {MenuComponent} from '@pages/template/menu/menu.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgxCurrencyModule} from 'ngx-currency';
import {NgxPlaidLinkModule} from 'ngx-plaid-link';
import {PowerComponent} from '@pages/template/footer/power.component';
import {NotifyComponent} from '@app/components/notity/notify.component';
import {TopComponent} from '@pages/template/top/top.component';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {AngularSignaturePadModule} from '@almothafar/angular-signature-pad';
import {ImSelectFilterComponent} from '@app/components/input-model/im-select-filter/im-select-filter.component';
import {PreloaderComponent} from '@app/components/loader/preloader/preloader.component';
import {SummaryComponent} from '@pages/summary/summary.component';
import {CardResumeComponent} from '@app/components/cards/card-resume/card-resume.component';
import {CardBeneficiaryComponent} from '@app/components/cards/card_beneficiary/card.beneficiary.component';
import {BeneficiaryComponent} from '@pages/beneficiary/beneficiary.component';
import {ConfirmKeyComponent} from '@app/components/confirm/confirm-key/confirm-key.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {ConsultsComponent} from '@pages/consults/consults.component';
import {CardOrderComponent} from '@app/components/cards/card_order/card.order.component';
import {CardAccountComponent} from '@app/components/cards/card_account/card.account.component';
import {TransferComponent} from '@pages/transfer/transfer.component';
import {CardAccountWComponent} from '@app/components/cards/card_account_white/card.account.w.component';
import {CardComprobanteComponent} from '@app/components/cards/card_comprobante/card.comprobante.component';
import {CaptchaComponent} from '@app/components/captcha/captcha.component';
import { PaycreditComponent } from './pages/credit/paycredit/paycredit.component';
import { CardCreditComponent } from './components/cards/card-credit/card-credit.component';
import { CardVaucherCreditComponent } from './components/cards/card-vaucher-credit/card-vaucher-credit.component';
import { PaymentPlanComponent } from './pages/credit/payment-plan/payment-plan.component';
import { CardCreditDetailComponent } from './components/cards/card-credit-detail/card-credit-detail.component';
import { CardRowPaymentComponent } from './components/cards/card-row-payment/card-row-payment.component';
import { DetailComponent } from './pages/credit/detail/detail.component';
import { CardRowDetaicreditComponent } from './components/cards/card-row-detaicredit/card-row-detaicredit.component';
import { CardDetailCargosComponent } from './components/cards/card-detail-cargos/card-detail-cargos.component';
import { DpfComponent } from './pages/dpf/dpf.component';
import { CardDpfComponent } from './components/cards/dpf/card-dpf/card-dpf.component';
import { CardDpfDetailComponent } from './components/cards/dpf/card-dpf-detail/card-dpf-detail.component';
import { PaginadorComponent } from './components/paginador/paginador.component';
import { HistorialComponent } from './pages/historial/historial.component';
import { OfficeComponent } from './pages/office/office.component';
import {AgmCoreModule} from "@agm/core";
import {AsfiComponent} from "@app/components/modal/asfi/asfi.component";
 import { NumberFormatPipe } from './pipes/number/number-format.pipe';

import { DecimalPipe} from '@angular/common';
import {  ReactiveFormsModule } from '@angular/forms';


export function initializeApp(appConfig: Config) {
  registerLocaleData(localeEs, 'es');
  return () => appConfig.load();
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    CaptchaComponent,
    AppComponent,
    AppMainComponent,
    FooterComponent,
    MenuComponent,
    TopComponent,
    LoaderComponent,
    PreloaderComponent,
    ConfirmComponent,
    ConfirmKeyComponent,
    ImNumberComponent,
    ImSelectComponent,
    ImSelectFilterComponent,
    ImCheckComponent,
    ImTextComponent,
    ImTextAreaComponent,
    AppErrorComponent,
    AppNotfoundComponent,
    AlphaNumericDirective,
    AlphaNumericSpaceDirective,
    NumericDirective,
    AlphaSpaceDirective,

    CardResumeComponent,
    CardBeneficiaryComponent,
    CardOrderComponent,
    CardAccountComponent,
    CardAccountWComponent,
    CardComprobanteComponent,

    LoginUserDirective,
    ClickStopDirective,
    LoginComponent,
    NotifyComponent,
    AsfiComponent,
    PowerComponent,
    SummaryComponent,
    BeneficiaryComponent,
    ProfileComponent,
    ConsultsComponent,
    TransferComponent,
    PaycreditComponent,
    CardCreditComponent,
    CardVaucherCreditComponent,
    PaymentPlanComponent,
    CardCreditDetailComponent,
    CardRowPaymentComponent,
    DetailComponent,
    CardRowDetaicreditComponent,
    CardDetailCargosComponent,
    DpfComponent,
    CardDpfComponent,
    CardDpfDetailComponent,
    PaginadorComponent,
    HistorialComponent,
    OfficeComponent,
    NumberFormatPipe,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    NgxCurrencyModule,
    NgxPlaidLinkModule,
    NgxCaptureModule,
    AngularSignaturePadModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutes,
    HttpClientModule,
    ToastModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    MDBBootstrapModule.forRoot(),
    RouterModule,
    AngularSvgIconModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBPI8-4gZRuc4gt60fjOsGoQQJ-I9DAQyo',
    })
  ],
  providers: [
    Config,
    MDBSpinningPreloader,
    EncryptService,
    DatePipe,
    DecimalPipe,
    {provide: APP_INITIALIZER, useFactory: initializeApp, deps: [Config], multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseModel} from "@models/util/base.model";
import {AuthService} from "@services/auth.service";
import {DomainService} from "@services/domain.service";
import {TranslateService} from "@ngx-translate/core";
import {HistoricalTrazeService} from "@services/historical.traze.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {MessageService} from "@app/error/message.service";
import {BeneService} from "@pages/beneficiary/bene.service";
import {AccountService} from "@services/account.service";
import {TransferService} from "@services/transfer.service";
import {Router} from "@angular/router";
import {Acc1, AccountDto} from "@models/account";
import {CreditService} from "@services/credit.service";
import {Cre3Model, CreditModel} from "@models/credit";
import {DOMAIN, FORMS, SERVICES, STATUS} from "@config/config";
import {MdbStepperComponent} from "ng-uikit-pro-standard";
import {ItemsModel} from "@models/util";
import {fromEvent, Subscription} from "rxjs";
import {PaymentDto2} from "@models/credit/paymentDto2.model";
import {D1} from "@models/domain";
import {Crt9Model} from "@models/credit/Crt9.model";
import {Crt10Model} from "@models/credit/Crt10.model";
import {ConfirmKeyComponent} from "@app/components/confirm/confirm-key/confirm-key.component";
import {Cre17} from "@models/credit/Cre17.model";
import {Crt18} from "@models/credit/Cre18.model";

@Component({
  selector: 'app-paycredit',
  templateUrl: './paycredit.component.html',
  styleUrls: ['./paycredit.component.scss']
})
export class PaycreditComponent extends BaseModel implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('stepper', {static: false}) stepper: MdbStepperComponent;
  @ViewChild('div_back', {static: false}) divBack: ElementRef;
  clickedElement: Subscription = new Subscription();
  listItemAccountOri: ItemsModel[] = [];
  listItemAccountOri_clean: ItemsModel[] = [];
  listItemCurrency: ItemsModel[] = [];
  listAccountAll: AccountDto[] = [];
  selectAccountOri: AccountDto;


  STEP_LIST_CREDIT = 1;
  STEP_ACCOUNT = 2;
  STEP_RESUME = 3;

  selectCredit: CreditModel;
  list: CreditModel[] = [];
  completeOrder = false;
  entity : PaymentDto2;
  step = this.STEP_LIST_CREDIT;
  cmpReq = FORMS.FRM_REQUIRED;
  cmpMin = FORMS.FRM_MINIMO;
  nameOriginante: string;
  nameBankOrig: string;
  numberTx: string;
  dateTx: string;
  status: string;
  button_status = true;

  sales: number;
  buys : number;

  submit = false;
  constructor(
    protected authService: AuthService,
    protected domainService: DomainService,
    private translate: TranslateService,
    protected historical: HistoricalTrazeService,
    protected deviceService: DeviceDetectorService,
    private messageService: MessageService,
    private beneService: BeneService,
    public accountService: AccountService,
    public creditService: CreditService,
    private router: Router
  ) {
    super(authService, historical, deviceService);
    super.registerEvent('Pago de credito', this.router.url);

  }


  ngOnInit(): void {
    this.selectAccountOri = new AccountDto();
    this.selectCredit = new CreditModel();
    this.entity = new PaymentDto2();
    this.nameOriginante = this.authService.getCurrentUser().client.fullName;
    this.nameBankOrig = this.authService.getCurrentUser().client.nameBank;
    this.onListCredits();
    this.onListAccounts();
    this.onListCurrency();
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    this.clickedElement.unsubscribe();
  }

  onListCredits() {
    const user = this.authService.getCurrentUser();
    const creditDto = new CreditModel();
    creditDto.canal = Number(SERVICES.SER_CANAL);
    creditDto.codigoCliente = user.client.accountCode;
    const creditModel3 = new Cre3Model(this.getHeader(this.authService.getUser()), creditDto, user.client.representativeCode);
    this.creditService.list(creditModel3)
      .subscribe((resp: any) => {
        this.list = resp.list;
      });
  }
  onListAccounts() {
    const user = this.authService.getCurrentUser();
    const acc1 = new Acc1(this.getHeader(this.authService.getUser()), user.client.accountCode, user.client.representativeCode);
    this.accountService.list(acc1)
      .subscribe((resp: any) => {
        this.listItemAccountOri = new ItemsModel().getItemsAccounts(resp.list);
        this.listAccountAll = resp.list;
        console.log("listItemAccountOri_clean",this.listAccountAll);
      });
  }


  onListCurrency() {
    const d1 = new D1(this.getHeader(this.authService.getUser()), DOMAIN.DOM_CURRENCY);
    this.domainService.listDomain(d1).subscribe(
      (data: any) => {
        this.listItemCurrency = new ItemsModel().getItems(data.list);
      }
    );
  }
  onNextCouteCredits() {
    const user = this.authService.getCurrentUser();
    const creditDto = new CreditModel();
    creditDto.canal = Number(SERVICES.SER_CANAL);
    creditDto.codigoCliente = user.client.accountCode;
    const creditModel9 = new Crt9Model(this.getHeader(this.authService.getUser()), user.client.accountCode,this.entity.numeroCredito, user.client.representativeCode, Number(SERVICES.SER_CANAL) );

    console.log("user",user);
    console.log("creditModel9",creditDto);


    this.creditService.nextPayment(creditModel9)
      .subscribe((resp: Crt10Model) => {
          console.log(resp);
        this.entity.montoPago =  '0';
        this.entity.moneda =  this.selectCredit.moneda.includes('B')?'BOB':'USD';
          if(resp.detail.montoDesembolsado.length>0){
            this.entity.montoPago =  resp.detail.montoProximaCuota.replace(',','');
          }

      });
  }
  onSelectCredti(value: CreditModel) {
    this.selectCredit = value;
    const aux = this.list.filter(x => x.numeroCredito !== value.numeroCredito);
    console.log("aux",value);

    this.listItemAccountOri = new ItemsModel().getItemsAccounts(this.listAccountAll);

    this.entity.numeroCredito = value.numeroCredito;
    this.onNextBeneficiary();
    this.onNextCouteCredits();
  }
  onNextBeneficiary() {
    this.stepper.next();
    this.goTop();
    this.step = this.STEP_ACCOUNT;
    this.addAccionBtnBack(this.STEP_ACCOUNT);
  }

  goTop(){
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  onNextAccount() {
this.onSelectCredti;

const user = this.authService.getCurrentUser();
this.sales = Number(user.sale);
this.buys = Number(user.purhchase);

    // cuenta origen
    this.selectAccountOri = this.listAccountAll.find(x => x.numAccount === this.entity.numeroCC);
    console.log("aux2", this.selectAccountOri.amount);
    var cuurency_acount = this.selectAccountOri.currency.includes('B') ? 'Bs.' : 'USD';

    var amount_account = this.selectAccountOri.available.replace(",", ""); // Elimina la coma
    var accoutn_amount = Number(amount_account); // Convierte el valor en un número

    console.log("currency", cuurency_acount);
    // Dinero que ingresa monto y moneda
    console.log("entity-2",this.entity);
    var money_int = this.entity.moneda.includes('B') ? 'Bs.' : 'USD';
    var amount_int = Number(this.entity.montoPago);

    console.log("money_int",money_int);

    if(money_int == cuurency_acount){
      if (accoutn_amount <  amount_int){

        //el Importe entrante no puede ser mayor al importe total de la cuenta
        this.messageService.showWarning(this.translate.instant('tra_val_amoun'));
        return;
      }
    }else{
      if(cuurency_acount == 'USD'){
          var impt_conv_int =  amount_int / this.sales ;

          if(money_int != "USD"){
            impt_conv_int =  amount_int / this.buys;
          }
          console.log("importe de entrada", accoutn_amount);
          console.log("importe",impt_conv_int);
          console.log("convercion dolar", impt_conv_int);

          if (accoutn_amount <  impt_conv_int){
            //el Importe entrante no puede ser mayor al importe total de la cuenta
            this.messageService.showWarning(this.translate.instant('tra_val_amoun'));
            return;
          }
      }else{
        var impt_conv_int =  amount_int * this.sales ;
          console.log("convercion dolar", impt_conv_int);
          if (accoutn_amount <  impt_conv_int){
            //el Importe entrante no puede ser mayor al importe total de la cuenta
            this.messageService.showError(this.translate.instant('tra_val_amoun'));
            return;
          }
      }

    }








    this.stepper.next();
    this.goTop();
    this.step = this.STEP_RESUME;
    this.addAccionBtnBack(this.STEP_RESUME);
    this.button_status = true;
  }
  addAccionBtnBack(step: number) {
    this.clickedElement.unsubscribe();

    switch (step) {
      case this.STEP_LIST_CREDIT: {
        this.clickedElement = fromEvent(this.divBack.nativeElement, 'click').subscribe(() => this.backBack());
        break;
      }
      case this.STEP_ACCOUNT: {
        this.clickedElement = fromEvent(this.divBack.nativeElement, 'click').subscribe(() => this.onBackBeneficiary());
        break;
      }
      case this.STEP_RESUME: {
        this.clickedElement = fromEvent(this.divBack.nativeElement, 'click').subscribe(() => this.onBackAccount());
        break;
      }
      default: {
        break;
      }
    }
  }
  backBack() {
    this.router.navigate(['']);
  }

  onBackBeneficiary() {
    this.stepper.previous();
    this.goTop();
    this.step = this.STEP_LIST_CREDIT;
    this.addAccionBtnBack(this.STEP_LIST_CREDIT);
  }
  onBackAccount() {
    this.stepper.previous();
    this.goTop();
    this.step = this.STEP_ACCOUNT;
    this.addAccionBtnBack(this.STEP_ACCOUNT);
  }
  getCurencyLabel(value : string ): string {
    const aux = this.listItemCurrency.find(x => x.value === value);
    return aux.label;
  }
  onSubmit(modal: ConfirmKeyComponent) {
    const user = this.authService.getCurrentUser();
    const sendPayCredit = new Cre17(this.getHeader(this.authService.getUser()), this.entity, modal.key, user.client.representativeCode);
    sendPayCredit.paymentDto.credit = new CreditModel();
    sendPayCredit.paymentDto.credit.numeroCredito = this.entity.numeroCredito;
    sendPayCredit.paymentDto.credit.codigoCliente = user.client.accountCode;
    console.log(sendPayCredit);
    this.submit = true;
    console.log('submit1::', sendPayCredit);
    this.creditService.pay(sendPayCredit).subscribe((resp: Crt18) => {
        console.log('submit12', resp);
        this.submit = false;
        if (resp.header.codReturn != '0') {
          modal.onHide();
          this.messageService.showError(resp.header.txtReturn);
        } else {
          this.numberTx = resp.numeroTransaccion;
          this.dateTx = resp.fecha;
          this.completeOrder = true;
          this.status = 'procesado';
          modal.onHide();
          super.registerEvent('Pago de credito: se realizo la transferencia ' + resp.numeroTransaccion, this.router.url);
        }
      },
      (error: any) => {
        console.log(error);
        modal.onHide();
        this.submit = false;
      }
    );
  }
  enableButton() {
    this.button_status = !this.button_status;
  }
  back_to_menu() {
    this.router.navigate(['summary']);
  }
}

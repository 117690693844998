import {Component, Input, OnInit} from '@angular/core';
import {FORMS} from '@config/config';

@Component({
    selector: 'app-im-textarea',
    templateUrl: './im-text-area.component.html'
})
export class ImTextAreaComponent implements OnInit {
    cmpReq = FORMS.FRM_REQUIRED;
    @Input() filtro: string;
    @Input() nombre: string;
    @Input() requerido: boolean;
    @Input() tamano: string;
    @Input() descripcion: string;
    @Input() adicional: string;
    content: string;

    constructor() {
    }
    ngOnInit() {
    }
}

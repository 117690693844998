import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[click-stop-propagation]'
})
export class ClickStopDirective {

  @HostListener('click', ['$event'])
  public onClick(event: Event): void {
    console.log('-Stop click-');
    event.preventDefault();
    event.stopPropagation();
  }

}


import { Pipe, PipeTransform } from '@angular/core';
import {DecimalPipe} from '@angular/common';
import * as _ from 'lodash';


@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

  constructor(private _decimalPipe: DecimalPipe) {

  }



  transform(value: any, args?: any): any {
      console.log("value", value);
      console.log("formateado",_.isNumber(value)); // Outputs '1,000.00' in the en-US locale
          /************************************ */

        if(_.isNumber(value)){
            return value;
        } else{
          if (value !== undefined && value !== null) {
            return value.replace(/,/g, "");
          } else {
            return "";
          }

        }

        }


}

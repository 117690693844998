import {RequestHeaderModel} from '@models/requestHeader.model';

export class Acc1 {
  header: RequestHeaderModel;
  accountCode: string;
  representativeCode: string;
  constructor(header: RequestHeaderModel, accountCode: string, representativeCode: string) {
    this.header = header;
    this.accountCode = accountCode;
    this.representativeCode = representativeCode;
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {FORMS} from '@config/config';


@Component({
  selector: 'app-card-account',
  templateUrl: './card.account.component.html',
  styleUrls: ['./card.account.component.scss'],
})
export class CardAccountComponent implements OnInit {

  @Input() accountNumber: string;
  @Input() moneda: string;
  @Input() monto: number;
  @Input() conjunta: string;
  @Input() type: string;
  @Input() pignorado: number;
  @Input() selected: boolean;

  @Input() nroCuota: string;
  @Input() valorCuota: string;
  @Input() disponible: string;

  cmpReq = FORMS.FRM_REQUIRED;
  cmpMin = FORMS.FRM_MINIMO;
  submitted = false;

  constructor(
  ) {}

  ngOnInit() {
  }
}

<div class="row back_unico cls_header_list">
  <div class="container cls_center">
    <h4 class="cls_title_2 color_white" [innerHTML]="'com_crt_title_payments' | translate"></h4>

    <div class="row back_unico">
      <mdb-carousel [isControls]="true"
                    (activeSlideChange)="onCoruselActive($event)"
                    [interval]="0"
                    class="carousel slide W100"
                    [type]="'carousel'"
                    [animation]="'slide'">
        <mdb-carousel-item *ngFor="let data of list">
          <div class="W100">
            <div style="width: 15% !important; float: left; height: 100%;"></div>
            <div style="width: 70% !important; float: left;">
              <app-card-credit-detail
                [credit]="data"
                [selected]="true"
              ></app-card-credit-detail>
            </div>
            <div style="width: 15% !important; float: left; height: 100%;"></div>
          </div>
        </mdb-carousel-item>
      </mdb-carousel>
    </div>
  </div>
</div>
<div class="row cls_body_list" style="height: 50px">

</div>
<div class="row ">
  <div style="width: 100%;display: flex; text-align: center; background: #FFFFFF">
    <div style="width: 25%">
      <a>Cuota número</a>
    </div>
    <div style="width: 25%">
      <a>Fecha</a>
    </div>
    <div style="width: 25%">
      <a>Cuota</a>
    </div>
    <div style="width: 25%">
      <a>Saldo Capital</a>
    </div>
  </div>
  <div class="W100 border_boton_input border_top_input"
       *ngFor="let data of listDetail">
    <app-card-row-payment
      [currency]=currency
      [detail]="data">
    </app-card-row-payment>
  </div>

  <div style="width: 100%;display: flex; text-align: center; background: #FFFFFF">
    <div class="W100 border_boton_input border_top_input"
         *ngIf="listDetail.length==0">
      <img src="assets/util/images/Icono_datos_no_encontrados.png" style="display: block; margin-left: auto; margin-right: auto;">
    </div>
  </div>

  <div style="height: 100px;">

  </div>
</div>

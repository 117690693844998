export class PaymentBreadModel  {
  numeroCuota: string;
  fechaCuota: string;
  capital: number;
  interes: number;
  saldoCapital: number;
  cargos: number;

  constructor(numeroCuota: string, fechaCuota: string, capital: number, interes: number, saldoCapital: number, cargos: number) {
    this.numeroCuota = numeroCuota;
    this.fechaCuota = fechaCuota;
    this.capital = capital;
    this.interes = interes;
    this.saldoCapital = saldoCapital;
    this.cargos = cargos;
  }
}

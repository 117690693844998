import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {STATUS} from "@config/config";
import {NgxCaptureService} from "ngx-capture";
import {tap} from "rxjs/operators";

@Component({
  selector: 'app-card-vaucher-credit',
  templateUrl: './card-vaucher-credit.component.html',
  styleUrls: ['./card-vaucher-credit.component.scss']
})
export class CardVaucherCreditComponent implements OnInit {
  @Input() nombreOri: string;
  @Input() cuentaOri: string;
  @Input() bancoOri: string;

  @Input() numeroCredito: string;

  @Input() estado: string;
  @Input() importe: number;
  @Input() moneda: string;
  @Input() fechaTransaccion: Date;
  @Input() txNumberCore: string;

  @Input() complete: boolean;


  @ViewChild('screen', {static: true}) screen: any;

  RECHAZADA = STATUS.Rechazada;
  ANULADA = STATUS.Anulada;
  CONFIRMADA = STATUS.Confirmada;

  constructor(
    private captureService: NgxCaptureService,
  ) {
  }

  ngOnInit() {
    if (this.fechaTransaccion == null) {
      this.fechaTransaccion = new Date();
    }
  }

  onCapture() {
    this.captureService.getImage(this.screen.nativeElement, true)
      .pipe(
        tap(img => {
          this.downloadCapture(img, 'transaction_' + this.txNumberCore);
        })
      ).subscribe();
  }

  downloadCapture(base64String, fileName) {
    const link = document.createElement('a');
    link.href = base64String;
    link.download = `${fileName}.png`;
    link.click();
  }
}


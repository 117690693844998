import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from '@services/auth.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from '@app/error/message.service';
import {NotifyService} from '@app/components/notity/notify.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private message: MessageService,
    private router: Router,
    private translate: TranslateService,
    private notify: NotifyService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let errorMessage = '';
    let dialogMessage = '';
    const errores: string[] = ['-101', '-102'];
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log('INTERCEPTOR_ERROR', error);
        if (error.status === 400 && error.error != null) {
          console.log(error.error.toString());
          if (errores.includes(error.error.toString())) {
            dialogMessage = this.translate.instant(error.error.toString());
          } else {
            errorMessage = this.translate.instant(error.error.toString());
          }
        } else {
          switch (error.status) {
            case 500: {
              errorMessage = this.translate.instant('500');
              break;
            }
            case 401: {
              errorMessage = this.translate.instant('error_session_time');
              this.router.navigate(['/login']);
              break;
            }
            case 403: {
              this.router.navigate(['/accessdenied']);
              break;
            }
            case 407: {
              errorMessage = this.translate.instant('error_login');
              this.router.navigate(['/login']);
              break;
            }
            case 429: {
              errorMessage = this.translate.instant('error_session_time');
              this.router.navigate(['/login']);
              break;
            }
            default: {
              if (!navigator.onLine) {
                errorMessage = this.translate.instant('error_sci');
              } else {
                if (error.status != 0) {
                  errorMessage = this.translate.instant('error_conexion');
                } else {
                  errorMessage = '';
                }
              }
            }
          }
        }
        if (errorMessage) {
          this.message.showError(errorMessage);
          return throwError(errorMessage);
        }
        if (dialogMessage) {
          this.notify.show(dialogMessage);
          return throwError(dialogMessage);
        }
        return throwError(error);
      }));
  }
}

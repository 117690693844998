import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CreditDetailModel, PaymentBreadModel} from "@models/credit";

@Component({
  selector: 'app-card-row-detaicredit',
  templateUrl: './card-row-detaicredit.component.html',
  styleUrls: ['./card-row-detaicredit.component.scss']
})
export class CardRowDetaicreditComponent implements OnInit {
  @Input() detail: CreditDetailModel;
  @Input() currency: string;
  @Output() selectDetail = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  select() {
    this.selectDetail.emit(this.detail);
  }
}

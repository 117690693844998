import {Component, Input, OnInit} from '@angular/core';
import {CreditDetailModel} from "@models/credit";
import {DetailleDpfDto} from "@models/dpf";

@Component({
  selector: 'app-card-dpf-detail',
  templateUrl: './card-dpf-detail.component.html',
  styleUrls: ['./card-dpf-detail.component.scss']
})
export class CardDpfDetailComponent implements OnInit {
  @Input() detailDpf: DetailleDpfDto;
  @Input() moneda: string;
  @Input() certificado: string;
  @Input() deposito: string;
  constructor() { }

  ngOnInit(): void {
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {FORMS} from '@config/config';

@Component({
    selector: 'app-im-check',
    templateUrl: './im-check.component.html'
})
export class ImCheckComponent implements OnInit {
    cmpReq = FORMS.FRM_REQUIRED;
    @Input() filtro: string;
    @Input() nombre: string;
    @Input() requerido: boolean;
    @Input() tamano: string;
    @Input() descripcion: string;
    @Input() adicional: string;
    content: string;

    constructor() {
    }
    ngOnInit() {
    }
}

import { RequestHeaderModel } from '@models/requestHeader.model';

export class Off3{
    header : RequestHeaderModel;
    dpto : string;
    types : string[];

    constructor(header : RequestHeaderModel,dpto:string, tipos : string[] ){
        this.header = header;
        this.dpto = dpto;
        this.types = tipos;
    }
}
import { Component, OnInit } from '@angular/core';
import {BaseModel} from "@models/util/base.model";
import {Router} from "@angular/router";
import {DomainService} from "@services/domain.service";
import {AuthService} from "@services/auth.service";
import {HistoricalTrazeService} from "@services/historical.traze.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {ToastService} from "ng-uikit-pro-standard";
import {OfficesService} from "@services/offices.service";
import {Subject} from "rxjs";
import {LatLngLiteral} from "@agm/core";
import {ItemsModel} from "@models/util";
import {OfficeDto} from "@models/office/officeDto.model";
import {D1, DomainModel} from "@models/domain";
import {CONFIG, DOMAIN} from "@config/config";
import {Off3} from "@models/office/Off3";
import {Config} from "@services/config";
import {debounceTime} from "rxjs/operators";

@Component({
  selector: 'app-office',
  templateUrl: './office.component.html',
  styleUrls: ['./office.component.scss']
})
export class OfficeComponent extends BaseModel implements  OnInit {
  subject: Subject<LatLngLiteral> = new Subject();
  dptoSelected: string;

  dptoSelect: ItemsModel[] = [];

  typeSelected: string[] = [];

  typeSelect: ItemsModel[] = [];

  //typeSelect4: ItemsModel[] = [];

  list: OfficeDto[] = [];

  markers: Marker[];

  typeDomains: DomainModel[];

  // google maps zoom level
  zoom: number = 14;

  // initial center position for the map
  lat: number = -17.783727;
  lng: number = -63.180673;
  constructor(private officeService: OfficesService,
              private router : Router,
              private domainService : DomainService,
              protected authService: AuthService,
              protected historical: HistoricalTrazeService,
              protected deviceService: DeviceDetectorService,
              private toast: ToastService) {
    super( authService, historical, deviceService);
  }

  ngOnInit(): void {
    this.loadCity(() => this.loadTipos(() => this.listOffices()));
    this.subject.pipe(debounceTime(300)).subscribe(details => {
      this.lat = details.lat;
      this.lng = details.lng;
    });
  }
  loadCity(complete?: () => void){
    const header = this.getHeader(this.authService.getUser());
    const d1 = new D1(header, DOMAIN.DOM_DPTO_TBZ);
    this.domainService.listDomain(d1).subscribe(
      (data: any) => {
        console.log('DPTO LOADED: ', data);
        this.dptoSelect = new ItemsModel().getItemsDomName(data.list);
        this.dptoSelected = 'DPTO_SCZ';
        console.log('DPTO LOADED: ', this.dptoSelect);
      },
      error => { },
      complete
    );
  }
  loadTipos(complete?: () => void){
    const header = this.getHeader(this.authService.getUser());
    const d1_2 = new D1(header, DOMAIN.DOM_OFFICE_TYPE);
    this.domainService.listDomain(d1_2)
      .subscribe((resp: any) => {
          this.typeDomains = resp.list;
          this.typeSelect = new ItemsModel().getItems(resp.list);
          this.typeSelected.push(this.typeSelect[0].value);
          console.log('TYPES LOADED: ', this.typeSelect);
        },
        error => { },
        complete
      );
  }

  listOffices(): void {
    this.markers = [];
    if (this.typeSelected.length > 0) {
      const header = this.getHeader(this.authService.getCurrentUser().userLogin);
      const off3 = new Off3(header, this.dptoSelected, this.typeSelected);
      this.officeService.listbyparam(off3).subscribe(data => {
        if (data.list.length > 0) {
          this.list = data.list;
          // this.toDtoList();
          this.dtoToMarkers();
          console.log('OFFICES LISTED: ' + this.list.toString());
        } else {
          this.list = [];
         // this.toast.info(MESSAGE.EMPTY_FIND);
        }
        this.setCenter();
      });
    } else {
      console.log('NO TYPE SELECT');
    //  this.toast.warning("Selecciones al menos una categoria")
      this.list = []

      this.setCenter();
    }
  }
  centerchange(e){
    this.subject.next(e);
  }
  setCenter() {
    if (this.list.length > 0) {
      this.lat = Number(this.list[0].lat);
      this.lng = Number(this.list[0].lng);
    } else {
      this.lat = -17.783727;
      this.lng = -63.180673;
    }
  }
  dtoToMarkers() {
    this.markers = this.list.map((x) => {

      const icon: Icon = {
        url:  `${Config.settings.path.url_root}${ CONFIG.ROOT_MARKER_URL}${this.typeDomains.find( t => t.domValue == x.tipo).domName}`,
        labelOrigin :{x:15,y:43},
        scaledSize: {
          width: 35,
          height: 35
        }
      };
      let marker: Marker = {
        lat: Number(x.lat),
        lng: Number(x.lng),
        draggable: false,
        label: x.nombre,
        icon: icon,
        id: x.officeId,
      };
      return marker;
    })
  }
  setCenterOnClick(id:number){
    let dto =  this.list.find(x => x.officeId == id);
    this.lat = Number(dto.lat);
    this.lng = Number(dto.lng);
    console.log(dto.nombre + ': ' +this.lat + ' - ' + this.lng);
  }
  getlabel(s:string){
    return this.typeSelect.find(x => x.value == s).label;
  }
  getIcon(id:number){
    let dto =  this.list.find(x => x.officeId == id);
    return dto.tipo == 'TYPE_AGENCIA' ? 'university' :
      dto.tipo == 'TYPE_ATM' ? 'credit-card' :
        dto.tipo == 'TYPE_CENTRAL' ? 'building' : 'dollar-sign'; // tipo_externo
  }
  handleDropdownChange(e){
    console.log(e);
    this.listOffices();
  }

}
interface Marker {
  lat: number;
  lng: number;
  label?: string;
  icon?: string | Icon;
  draggable: boolean;
  id: number;
}

interface Icon {
  url: string;
  labelOrigin : {
    x:number,
    y:number};
  scaledSize : {
    width: number;
    height: number;
  }
}

import {AuthService} from '@services/auth.service';
import {RequestHeaderModel} from '@models/requestHeader.model';
import {HistoricalTrazeService} from '@services/historical.traze.service';
import {HistoricalTrazeModel, Ht1Model} from '@models/historical';
import {DeviceHeader} from '@models/device_header';
import {StorageUtil} from '@models/util/StorageUtil';
import {LOCSESION} from '@config/config';
import {DeviceDetectorService} from 'ngx-device-detector';

declare let ClientIP: any;

export class BaseModel {

  protected auth: AuthService;
  protected historical: HistoricalTrazeService;
  protected deviceService: DeviceDetectorService;
  protected privateIP;

  constructor(auth: AuthService, historical: HistoricalTrazeService, deviceService: DeviceDetectorService) {
    this.auth = auth;
    this.historical = historical;
    this.deviceService = deviceService;
    this.privateIP = ClientIP;
  }

  registerEvent(action: string, path: string) {
    const model = new HistoricalTrazeModel();
    model.action = action;
    model.platform = 'Web';
    model.os = this.deviceService.userAgent;
    model.ip =  StorageUtil.get(LOCSESION.LS_IP);
    model.path = path;
    model.token = this.auth.getCurrentUser().idSession;
    model.userName = this.auth.getCurrentUser().client.accountCode;
    const header = this.getHeader(this.auth.getUser());
    const ht1 = new Ht1Model(header, model);
    console.log('MODEL ::', ht1);
    this.historical.register(ht1).subscribe((data) => {
        console.log('REGISTER ::', data);
      },
      error => {
        console.log('ERROR REGISTER ::', error);
      }
    );
  }

  public getHeader(userName: string): RequestHeaderModel {

    const header = new RequestHeaderModel(userName);
    if (this.auth.getCurrentUser() !== undefined){
      header.idSession = this.auth.getCurrentUser().idSession;
    }
    const deviceInfo = new DeviceHeader();
    deviceInfo.os = this.deviceService.os;
    deviceInfo.ip =  StorageUtil.get(LOCSESION.LS_IP);
    deviceInfo.browserName = this.deviceService.browser;
    deviceInfo.infoDevice = this.deviceService.userAgent;
    deviceInfo.deviceVersion = this.deviceService.browser_version;
    header.deviceInfo = deviceInfo;
    return header;
  }
}

import {RequestHeaderModel} from '@models/requestHeader.model';

export class Crt11Model {
  header: RequestHeaderModel;
  canal: number;
  codigoCliente: string;
  numeroCredito: string;


  constructor(header: RequestHeaderModel, canal: number, codigoCliente: string, numeroCredito: string) {
    this.header = header;
    this.canal = canal;
    this.codigoCliente = codigoCliente;
    this.numeroCredito = numeroCredito;
  }
}

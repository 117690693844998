import {RequestHeaderModel} from "@models/requestHeader.model";

export  class Dpf3 {
  header: RequestHeaderModel;
  canal: number;
  codigoCliente: string;
  numeroDeposito: string;
  representativeCode: string;

  constructor(header: RequestHeaderModel, canal: number, codigoCliente: string, numeroDeposito: string, representativeCode: string) {
    this.header = header;
    this.canal = canal;
    this.codigoCliente = codigoCliente;
    this.numeroDeposito = numeroDeposito;
    this.representativeCode = representativeCode;
  }
}

<div class="cls_diclosure" #screen>
  <div class="cls_center cls_header">
    <div class="header_des">
      <img src="assets/util/images/tesabiz.png" style="width: 200px;">
    </div>
  </div>
  <br>
  <div class="cls_center">
    <h4 class="color_primario"><b>{{'com_crt_detail' | translate}}</b></h4>
  </div>
  <div class="W100 FZ8" >
    <hr class="back_primario cl_hr">
    <div style="display: table; width: 100%; clear: both;">
      <div style="display: block; width: 50%; float: left;">
        <span>{{'com_crt_detail_numberCore' | translate}}:</span><br/>
        <span><b>{{detailModel.numeroDocumento}}</b></span><br/>
      </div>
      <div style="display: block; width: 50%; float: left; text-align: right;">
        <span>{{'com_crt_detail_date' | translate}}:</span><br/>
        <span><b>{{detailModel.fecha | date: 'dd/MM/yyyy HH:mm:ss'}}</b></span>
      </div>
    </div>
  </div>

  <hr class="back_primario cl_hr">
  <div class="W100 FZ8">
    <div style="display: table; width: 100%; clear: both;">
      <div style="display: block; width: 50%; float: left;">
        <span>{{'com_crt_detail_total_pay' | translate}}:</span><br/>
        <span><b>{{detailModel.totalPagado}} {{moneda}}</b></span>
      </div>
      <div style="display: block; width: 50%; float: left; text-align: right;">
        <span>{{'com_crt_detail_interes' | translate}}:</span><br/>
        <span><b>{{detailModel.interes}} {{moneda}} </b></span>
      </div>
    </div>
    <div style="display: table; width: 100%; clear: both;">
      <span>{{'com_crt_detail_capital' | translate}}:</span><br/>
      <span><b>{{detailModel.capital}} {{moneda}} </b></span>
    </div>
  </div>
  <hr class="back_primario cl_hr">
  <div class="W100 FZ8">
    <div style="display: table; width: 100%; clear: both;">
      <div style="display: block; width: 50%; float: left;">
        <span>{{'com_crt_detail_numberCredit' | translate}}:</span><br/>
        <span><b>{{numeroCredito}}</b></span>
      </div>
    </div>
  </div>
  <hr class="back_primario cl_hr">
  <div class="W100 FZ8">
    <div style="display: table; width: 100%; clear: both;">
      <div style="display: block; width: 100%; text-align: center;">
        <span><b>{{'com_crt_detail_title_other' | translate}}</b></span>
      </div>
    </div>
  </div>

  <div style="display: table; width: 100%; clear: both;">
    <div *ngFor="let data of detailModel.cargos">
      <div  style="display: block; width: 50%; float: left;">
        <span>{{data.descripcion}}:</span><br/>
      </div>
      <div style="display: block; width: 50%; float: left; text-align: right;">
        <span><b>{{data.monto}} {{moneda}} </b></span>
      </div>
    </div>

  </div>

  <hr class="back_primario cl_hr">
  <div class="W100 FZ8">
    <div style="display: table; width: 100%; clear: both;">
      <div style="display: block; width: 50%; float: left;">
        <span>{{'com_crt_detail_amount' | translate}}:</span><br/>
        <span><b>{{detailModel.saldo}} {{moneda}}</b></span><br/>
      </div>

    </div>
  </div>
  <hr class="back_primario cl_hr">
</div>

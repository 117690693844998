import { RequestHeaderModel } from '../requestHeader.model';

export class Cd1Model{
  header: RequestHeaderModel ;
  date: string;

  constructor(header: RequestHeaderModel, date: string) {
    this.header = header;
    this.date = date;
  }
}

<div mdbModal #confirm="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true" [config]="{ignoreBackdropClick: true}">
  <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Body-->
      <div class="modal-body">
        <div class="cls_title_confirm" [innerHTML]="text"></div>
        <div class="md-form mb-4">
          <div style="position: relative;">
            <input #kkey="ngModel"
                   [(ngModel)]="key"
                   name="pass"
                   [type]="eyePass? 'text' : 'password'"
                   id="idpass"
                   class="form-control cls_input"
                   mdbInput
                   placeholder=""
                   required
                   maxlength="6"
                   minlength="6"
                   length="6"
                   mdbCharCounter
                   mdbValidate
                   autocomplete="off">
            <label for="idpass">{{'input_key' | translate}} *</label>
            <mdb-icon *ngIf="eyePass"
                      (click)="togogleEyePass()" far icon="eye"
                      class="color_secundario cls_hover cls_eye"></mdb-icon>
            <mdb-icon *ngIf="!eyePass"
                      (click)="togogleEyePass()" far icon="eye-slash"
                      class="color_primario cls_hover cls_eye"></mdb-icon>
          </div>
          <div *ngIf="!kkey.valid && (kkey.dirty || kkey.touched)">
            <mdb-error *ngIf="kkey.hasError('required')">{{cmpReq}}</mdb-error>
          </div>
        </div>
      </div>
      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <button mdbBtn
                class="btn-secundario btn_confirm"
                (click)="confirm.hide()"
                mdbWavesEffect
                type="button">{{'not' | translate}}</button>
        <button mdbBtn
                class="gradient-custom-2 btn_confirm"
                [disabled]="!kkey.valid"
                (click)="onOk()"
                mdbWavesEffect
                type="button">{{'yes' | translate}}</button>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>


import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {IConfig, StorageUtil} from '../models/util';
import {LOCSESION, MESSAGE} from '../config/config';

@Injectable()
export class Config {
  static settings: IConfig;

  constructor(private http: HttpClient) {
  }

  load() {
    const jsonFile = `assets/config/config.${environment.name}.json`;
    return new Promise<void>((resolve, reject) => {
      this.http.get(jsonFile).toPromise().then((response: IConfig) => {
        Config.settings = <IConfig> response;
        resolve();
      }).catch((response: any) => {
        reject(`${MESSAGE.ERROR_FILE_CONFIG} '${jsonFile}': ${JSON.stringify(response)}`);
      });
    });
  }
}

import {RequestHeaderModel} from '../requestHeader.model';
import {FilterModel} from '@models/filter.model';

export class BeneficiaryModel3 {
  header: RequestHeaderModel;
  bank: string;
  filter: FilterModel;

  constructor(header: RequestHeaderModel, bank?: string, filter?: FilterModel) {
    this.header = header;
    this.bank = bank;
    this.filter = filter;
  }
}

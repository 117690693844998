import {HistoricalTrazeModel} from '@models/historical/HistoricalTraze.model';
import {RequestHeaderModel} from '@models/requestHeader.model';

export class Ht1Model {
  header: RequestHeaderModel;
  historicalTrazeDto: HistoricalTrazeModel;
  constructor(header: RequestHeaderModel, historicalTrazeDto: HistoricalTrazeModel) {
    this.header = header;
    this.historicalTrazeDto = historicalTrazeDto;
  }
}

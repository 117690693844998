<div mdbModal #confirm="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true" [config]="{ignoreBackdropClick: true}">
  <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Body-->
      <div class="modal-body">
        <h5 class="cls_title_confirm text-center"><b>{{text}}</b></h5>
        <p *ngIf="det1" class="mt-1 mb-2 text-center">{{det1}} </p>
        <p *ngIf="det2" class="mt-1 mb-2 text-center">{{det2}} </p>
      </div>
      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <button mdbBtn
                class="btn-secundario btn_confirm"
                (click)="confirm.hide()"
                mdbWavesEffect
                type="button">{{'not' | translate}}</button>
        <button mdbBtn
                class="gradient-custom-2 btn_confirm"
                (click)="onOk()"
                mdbWavesEffect
                type="button">{{'yes' | translate}}</button>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>


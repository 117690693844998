export class AccountDetailModel {
  accountNumber: string;
  accountName: string;
  ci: string;
  currency: string;
  typeAccount: string;

  constructor() {
  }
}

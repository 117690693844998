export class BeneficiaryModel {
  beneficiaryId: number;
  alias: string;
  accountName: string;
  accountNumber: string;
  ci: string;
  eif: string;
  typeAccount: string;
  currency: string;
  isEmployed: boolean;
  bankName: string;
  enabled: boolean;

  _isEmployed: boolean;

    clone(c: BeneficiaryModel): BeneficiaryModel {
        const model: BeneficiaryModel = new BeneficiaryModel();
        model.beneficiaryId = c.beneficiaryId;
        model.alias = c.alias;
        model.accountName = c.accountName;
        model.accountNumber = c.accountNumber;
        model.ci = c.ci;
        model.eif = c.eif;
        model.typeAccount = c.typeAccount;
        model.currency = c.currency;
        model.isEmployed = c.isEmployed;
        model.bankName = c.bankName;
        model.enabled = c.enabled;
        return model;
    }
}

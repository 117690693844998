
<footer class="page-footer center-on-small-only pt-0 mt-5">
</footer>
<nav class="tabbar cls_visible_tab">
  <div>
    <label (click)="go('')">
      <div class="cls_icon_var">
        <svg-icon src="assets/util/images/icons/home.svg"
                  [svgStyle]="{ 'height.px':25}">
        </svg-icon>
      </div>
      <span>{{'menu_inicio' | translate}}</span>
    </label>
    <label (click)="go('transfer')">
      <div class="cls_icon_var">
        <svg-icon src="assets/util/images/icons/transfer.svg"
                  [svgStyle]="{ 'height.px':25}">
        </svg-icon>
      </div>
      <span>{{'menu_transferencias_nueva' | translate}}</span>
    </label>
    <label (click)="go('account')">
      <div class="cls_icon_var">
        <svg-icon src="assets/util/images/icons/payment.svg"
                  [svgStyle]="{ 'height.px':25}">
        </svg-icon>
      </div>
      <span>{{'menu_pagos' | translate}}</span>
    </label>
    <label (click)="go('profile')">
      <div class="cls_icon_var">
        <svg-icon src="assets/util/images/icons/profile.svg"
                  [svgStyle]="{ 'height.px':25}">
        </svg-icon>
      </div>
      <span>{{'menu_perfil' | translate}}</span>
    </label>
  </div>
</nav>

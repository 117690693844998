import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModalDirective} from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-asfi',
  templateUrl: './asfi.component.html',
  styleUrls: ['./asfi.component.scss']
})
export class AsfiComponent implements OnInit {
  @ViewChild('modal') public modModal: ModalDirective;
  @Input() linkAsfi: string;
  @Input() urlImage: string;
  @Output() show = new EventEmitter();
  @Output() hide = new EventEmitter();
  constructor() { }
  ngOnInit() {
  }
  onShow(): void {
    this.show.emit();
    this.modModal.show();
  }

  onHide(): void {
    this.hide.emit();
    this.modModal.hide();
  }
}

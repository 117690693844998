<div #div_back class="btn_back btn_back_top FL" *ngIf="!completeOrder">
  <mdb-icon fas icon="chevron-left" class="bb_icon"></mdb-icon>
  <span>{{'login_back' | translate}}</span>
</div>
<div class="row" style="overflow-x: hidden !important;">
  <div class="container">
    <mdb-stepper #stepper>
      <mdb-step name="st_1" [stepForm]="frm1" class="cls_stepper">
        <div class="container">
          <div class="text-center">
            <h4 class="cls_title_2"
                [innerHTML]="('crt_select' | translate)"></h4>
          </div>
          <div class="W100 border_boton_input border_top_input"
               *ngIf="list.length==0">
            <img src="assets/util/images/Icono_datos_no_encontrados.png" style="display: block; margin-left: auto; margin-right: auto;">
          </div>
          <form #frm1="ngForm">
            <div class="row" style="display: flex; justify-content: center;">
              <div
                class="col-12 col-md-6 MB20 "
                *ngFor="let data of list"  (click)="onSelectCredti(data)" >
                <app-card-credit
                  [credit]="data"
                  [selected]="selectCredit.numeroCredito == data.numeroCredito">
                </app-card-credit>
              </div>
            </div>
          </form>
        </div>
      </mdb-step>
      <mdb-step name="st_2" [stepForm]="frm2" >
        <div class="container">
          <div class="text-center">
            <h4 class="cls_title_2"
                [innerHTML]="('crt_pay_values' | translate)"></h4>
          </div>
          <form #frm2="ngForm" style="background: #FFFFFF; padding: 20px; border-radius: 20px;">
            <div class="container container_layout">
              <div class="md-form mb-4 select_layout">
                <mdb-select #accountNumberOri="ngModel"
                            [(ngModel)]="entity.numeroCC"
                            name="accountNumber"
                            id="idaccountNumberOri"
                            [options]="listItemAccountOri"
                            [disabled]="listItemAccountOri.length===0"
                            class="form-control cls_select cls_select_white"
                            required
                            placeholder="{{'crt_pay_acounto_select' | translate}}">
                </mdb-select>
                <label for="idaccountNumberOri">{{'crt_pay_account' | translate}} *</label>
                <div *ngIf="!accountNumberOri.valid && (accountNumberOri.dirty || accountNumberOri.touched)">
                  <mdb-error *ngIf="accountNumberOri.hasError('required')">{{cmpReq}}</mdb-error>
                </div>
              </div>

              <div class="md-form mb-4 amount_layout">
                <input #amount="ngModel"
                       [(ngModel)]="entity.montoPago"
                       currencyMask
                       type="text"
                       id="idamount"
                       name="amount"
                       mdbInput
                       required
                       mdbValidate
                       placeholder="0.00"
                       class="form-control cls_input cls_input_white"
                       validate
                       maxlength="12"
                       minlength="2"
                       autocomplete="off"
                       [options]="{ prefix: '',  thousands: ',', decimal: '.',allowNegative:false }">
                <label class="cls_label_top" for="idamount">{{'crt_pay_amount' | translate}} *</label>
                <div *ngIf="!amount.valid && (amount.dirty || amount.touched)">
                  <mdb-error *ngIf="amount.hasError('required')">{{cmpReq}}</mdb-error>
                  <mdb-error *ngIf="amount.hasError('minlength')">{{cmpMin}} 2</mdb-error>
                </div>
              </div>
            </div>

            <div class="W100">
              <div class="cls_center">
                <button mdbBtn class="gradient-custom-2"
                        mdbWavesEffect
                        (click)="onNextAccount()"
                        [disabled]="!frm2.valid "
                        type="submit">{{'crt_pay_next' | translate}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </mdb-step>
      <mdb-step name="st_3" [stepForm]="frm3">
        <div class="container">
          <div class="text-center">
            <h4 class="cls_title_2" [innerHTML]="'crt_pay_title_resume' | translate"></h4>
          </div>
          <form #frm3="ngForm">
            <div *ngIf="step==3">
              <div class="crt_padding">
                <div *ngIf="completeOrder" class="col-14 col-md-9  mx-auto cls_message">
                  {{'crt_pay_succes' | translate}}
                </div>
              </div>
              <br>

              <div class="col-15 col-md-10  mx-auto">
                <app-card-vaucher-credit
                  [nombreOri]="nameOriginante"
                  [cuentaOri]="entity.numeroCC"
                  [bancoOri]="nameBankOrig"
                  [numeroCredito]="entity.numeroCredito"
                  [importe]="entity.montoPago"
                  [moneda]="getCurencyLabel(entity.moneda)"
                  [fechaTransaccion]="dateTx"
                  [txNumberCore]="numberTx"
                  [complete]="completeOrder"
                  [estado]="status"
                >
                </app-card-vaucher-credit>
              </div>

              <div *ngIf="!completeOrder" class="W100 FZ8 col-12 col-md-8 mx-auto confirmation_layout" >
                <div style="display: table; width: 100%; clear: both;">
                  <div style="display: block; width: 85%; float: left;">
                    <span>{{'com_crt_confirmation_message' | translate}}</span>
                  </div>
                  <div style=" width: 15%; float: left; text-align: right; padding-top: 5px;">
                    <label class="switch">
                      <input type="checkbox" id="switch" (change)="enableButton()">
                      <span class="slider" ></span>
                    </label>
                  </div>
                </div>
              </div>


              <div class="cls_center PT20" *ngIf="!completeOrder" style="display: flex; justify-content: center; align-items: center;">

                <div style="padding-right: 20px">
                  <button mdbBtn class="gradient-custom-2"
                          style="padding-right: 20px;"
                          mdbWavesEffect
                          (click)="back_to_menu()"
                          [disabled]="!frm3.valid || submit"
                          type="submit">{{'crt_pay_cancel_button' | translate}}
                  </button>
                </div>
                <div>
                  <button mdbBtn class="gradient-custom-2"
                          mdbWavesEffect
                          (click)="confirm.onShow()"
                          [disabled]="!frm3.valid || submit || button_status"
                          type="submit">{{'crt_pay_end_button' | translate}}
                  </button>
                </div>

              </div>
            </div>
          </form>
        </div>
      </mdb-step>
    </mdb-stepper>


  </div>
</div>

<app-confirm-key #confirm
                 text="{{'crt_pay_submit_confirm' | translate}}"
                 (success)="onSubmit(confirm)"></app-confirm-key>


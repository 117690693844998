<div class="md-form" *ngIf="itemValues">
  <label class="active">{{nombre}} {{requerido ? '*' : ''}}</label>
  <mdb-select-2 #imselect="ngModel"
                [placeholder]="placeholder"
                (ngModelChange)="onSelect($event)"
                mdbValidate
                class="form-control cls_select2 cls_select_white"
                [(ngModel)]="valor"
                [required]="requerido">
    <div class="cls_filter_div">
      <input mdbInput type="text" #inpfilt="ngModel" [(ngModel)]="inpfil"
             class="form-control cls_input cls_input_white cls_filter_select form-control"
             (keyup)="onFilter(inpfilt.value)">
    </div>
    <mdb-select-option *ngFor="let item of itemValues"
                       [value]="item.value">
      {{ item.label }} <span class="flag {{'flag-'+item.icon}} FL PR10"></span></mdb-select-option>
  </mdb-select-2>
  <mdb-error
    *ngIf="!imselect.valid && (imselect.dirty || imselect.touched)">{{cmpReq}}
  </mdb-error>
</div>


import {Component, Input, OnInit} from '@angular/core';
import {CreditDetailModel} from "@models/credit";

@Component({
  selector: 'app-card-detail-cargos',
  templateUrl: './card-detail-cargos.component.html',
  styleUrls: ['./card-detail-cargos.component.scss']
})
export class CardDetailCargosComponent implements OnInit {
  @Input() detailModel: CreditDetailModel;
  @Input() moneda: string;
  @Input() numeroCredito: string;
  constructor() { }

  ngOnInit(): void {
  }

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HistoryAccountDto} from '@models/history';
import {CONFIG, STATUS} from '@config/config';

@Component({
  selector: 'app-card-order',
  templateUrl: './card.order.component.html',
  styleUrls: ['./card.order.component.scss'],
})

export class CardOrderComponent implements OnInit {

  @Input() order: HistoryAccountDto;
  @Input() currency: string;
  @Output() selectOrder = new EventEmitter();

  RECHAZADA = STATUS.Rechazada;
  ANULADA = STATUS.Anulada;
  CONFIRMADA = STATUS.Confirmada;

  fecha_sdf = CONFIG.fecha_sdf;
  fecha_hora_sdf = CONFIG.fecha_hora_sdf;

  constructor() {
  }

  ngOnInit() {
  }

  select() {
    this.selectOrder.emit(this.order);
  }
}

import { Crt18 } from './../models/credit/Cre18.model';
import { Cre17 } from './../models/credit/Cre17.model';
import { NextCuotaRes } from './../models/credit/NextCuotaRes.model';
import { NextCuotaReq } from './../models/credit/NextCuotaReq.model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from '@services/config';
import {
  Cre1Model,
  Cre2Model,
  Cre3Model,
  Cre4Model,
  Cre5Model,
  Cre6Model,
  Crt11Model,
  Crt12Model,
  Crt13Model,
  Crt14Model
} from '../models/credit';
import {Crt9Model} from '@models/credit/Crt9.model';
import {Crt10Model} from '@models/credit/Crt10.model';
import {Crt15Model} from '@models/credit/Crt15.model';
import {Crt16Model} from '@models/credit/Crt16.model';

@Injectable({
  providedIn: 'root'
})

export class CreditService {

  constructor(private http: HttpClient) {
  }

  nextPayment(model: Crt9Model) {
    return this.http.post<Crt10Model>(`${Config.settings.path.url_root}/tesabiz-ws-security/tbz/credit/nextPayment`, model).pipe();
  }
  list(model: Cre3Model) {
    return this.http.post<Cre4Model>(`${Config.settings.path.url_root}/tesabiz-ws-security/tbz/credit/list`, model).pipe();
  }

  find(model: Cre3Model) {
    return this.http.post<Cre5Model>(`${Config.settings.path.url_root}/tesabiz-ws-security/tbz/credit/detail`, model).pipe();
  }

  pay(model: Cre17) {
    return this.http.post<Crt18>(`${Config.settings.path.url_root}/tesabiz-ws-security/tbz/credit/pay`, model).pipe();
  }

  nextQuote(model: NextCuotaReq) {
    return this.http.post<NextCuotaRes>(`${Config.settings.path.url_root}/tesabiz-ws-security/tbz/credit/nextquote`, model).pipe();
  }
  paymentBread(model: Crt11Model) {
    return this.http.post<Crt12Model>(`${Config.settings.path.url_root}/tesabiz-ws-security/tbz/credit/paymentBread`, model).pipe();
  }
  solutionStatus(model: Crt13Model) {
    return this.http.post<Crt14Model>(`${Config.settings.path.url_root}/tesabiz-ws-security/tbz/credit/solutionStatus`, model).pipe();
  }
  simulate(model: Crt15Model) {
    return this.http.post<Crt16Model>(`${Config.settings.path.url_root}/tesabiz-ws-security/tbz/credit/simulate`, model).pipe();
  }

}

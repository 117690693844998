<div class="card-detail2 {{selected? 'select_account2' : ''}}">
  <div class="header">
    <div class="accountNumber">Numero de crédito <b>{{credit.numeroCredito}}</b></div>
  </div>
  <div class="body">
    <div class="monto"><b><span>{{credit.moneda.includes('B') ? 'Bs.' : 'USD'}}</span>&nbsp;{{credit.montoDesembolsado}}</b></div>
  </div>
  <div class="footer">
    <div class="pignorado">Monto pagado  <b>{{credit.moneda.includes('B') ? 'Bs.' : 'USD'}} {{credit.montoPagado}}</b></div>
    <div class="valorCuota">Saldo crédito   <b>{{credit.moneda.includes('B') ? 'Bs.' : 'USD'}} {{credit.saldoCredito}}</b></div>
  </div>
</div>


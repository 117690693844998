<header>
  <div class="cls_menu_container">
    <div class="cls_menu_item1">
      <div class="cls_menu_top cls_visible_tab">
        <a (click)="sidenav.toggle()" mdbBtn class="cls_icon_menu" style="box-shadow: none;">
          <span class="fa fa-bars" style="font-size: 30px;"></span>
        </a>
        <div class="cls_compra_venta4">
          <a class="waves-light" mdbWavesEffect (click)="user()" >
            <mdb-icon far icon="user" class="cls_compra_logo"></mdb-icon>
          </a>
        </div>
      </div>
      <div class="cls_menu_top_web">
        <div class="cls_compra_venta4 cls_visible_tab_web">
          <a class="waves-light" mdbWavesEffect (click)="user()" >
            <mdb-icon far icon="user" class="mr-1"></mdb-icon>
            <span class="cls_name">{{currentUser.client.fullName}}</span>
          </a>
        </div>
      </div>
    </div>

    <div class="cls_menu_item2">
      <div class="cls_menu_top cls_visible_tab">
        <div class="text-center class_logo cls_visible_tab">
          <img src="assets/util/images/tesabiz.png" alt="logo">
        </div>
      </div>
    </div>

    <div class="cls_menu_item3">
      <div class="cls_menu_top cls_visible_tab">
        <div class="cls_compra_venta">
          <span><b>C:</b> {{currentUser.purhchase}}</span><br>
          <span><b>V:</b> {{currentUser.sale}}</span>
        </div>
        <div class="cls_compra_venta3">
          <a class="nav-link waves-light" mdbWavesEffect (click)="geo()" >
            <mdb-icon fas icon="map-marker-alt" class="fa-lg"></mdb-icon>
          </a>
        </div>
      </div>
      <div class="cls_menu_top_web cls_visible_tab_web">
        <div class="cls_compra_venta2">
          <span><b>Compra:</b> {{currentUser.purhchase}}</span><br>
          <span><b>Venta:</b> {{currentUser.sale}}</span>
        </div>
        <div class="cls_compra_venta3">
          <a class="nav-link waves-light" mdbWavesEffect (click)="geo()" >
            <mdb-icon fas icon="map-marker-alt" class="mr-1 fa-lg"></mdb-icon>
            <span class="clearfix d-none d-sm-inline-block">{{'Geolocalización'}}</span>
          </a>
        </div>
      </div>

    </div>
  </div>
  <!--/. SideNav slide-out button -->
  <!-- Sidebar navigation -->
  <mdb-side-nav class="fixed cls_menu_border" #sidenav [fixed]="true">
    <br/>
    <li class="MT-20 MB20 logo_layout">
      <div class="logo-wrapper">
        <img src="assets/util/images/logotipow.png" alt="logo">
      </div>
    </li>
    <!--/. Logo -->
    <!--Profile-->
    <li>

    <li>

      <ul class="collapsible collapsible-accordion cls_button_color">
<!--        <mdb-accordion [multiple]="false" aria-multiselectable="false">-->
<!--          <mdb-accordion-item [ngClass]="'no-collase'" class="cls_visible_tab_web cls_button_color">-->
<!--            <div>-->
<!--              <mdb-accordion-item-head [routerLink]="''" routerLinkActive="active" mdbWavesEffect>-->
<!--                <div class="cls_icon_var_menu">-->
<!--                  <svg-icon src="assets/util/images/icons/home.svg"-->
<!--                            [svgStyle]="{ 'height.px':20,'width.px':22}">-->
<!--                  </svg-icon>-->
<!--                  <span class="PL10">{{'menu_inicio' | translate}}</span>-->
<!--                </div>-->
<!--              </mdb-accordion-item-head>-->
<!--              <mdb-accordion-item-body></mdb-accordion-item-body>-->
<!--            </div>-->
<!--          </mdb-accordion-item>-->

<!--          <mdb-accordion-item [ngClass]="'no-collase'" class="cls_visible_tab_web">-->
<!--            <div>-->
<!--              <mdb-accordion-item-head [routerLink]="'/account'" routerLinkActive="active" mdbWavesEffect>-->
<!--                <div class="cls_icon_var_menu">-->
<!--                  <mdb-icon fas icon="money-check-alt" ></mdb-icon>-->
<!--                  <span >{{'menu_pagos' | translate}}</span>-->
<!--                </div>-->
<!--              </mdb-accordion-item-head>-->
<!--            </div>-->
<!--          </mdb-accordion-item>-->

<!--          <mdb-accordion-item>-->
<!--            <mdb-accordion-item-head mdbWavesEffect>-->
<!--              <div class="cls_icon_var_menu">-->
<!--                <mdb-icon fas icon="hand-holding-usd" ></mdb-icon>-->
<!--                <span>{{'menu_transferencias' | translate}}</span>-->
<!--              </div>-->
<!--            </mdb-accordion-item-head>-->
<!--            <mdb-accordion-item-body>-->
<!--              <ul>-->
<!--                <li><a [routerLink]="'/transfer'" routerLinkActive="active" >{{'menu_transferencias_nueva' | translate}}</a></li>-->
<!--                <li><a [routerLink]="'/transfer/history'" routerLinkActive="active" >{{'menu_transferencias_consulta' | translate}}</a></li>-->
<!--                <li><a [routerLink]="'/transfer/beneficiary'" routerLinkActive="active" >{{'menu_transferencias_beneficiario' | translate}}</a></li>-->
<!--              </ul>-->
<!--            </mdb-accordion-item-body>-->
<!--          </mdb-accordion-item>-->




<!--          <mdb-accordion-item>-->
<!--            <mdb-accordion-item-head mdbWavesEffect>-->
<!--              <div class="cls_icon_var_menu">-->
<!--                <mdb-icon fas icon="hotel" ></mdb-icon>-->
<!--                <span>{{'menu_credit' | translate}}</span>-->
<!--              </div>-->
<!--            </mdb-accordion-item-head>-->
<!--            <mdb-accordion-item-body>-->
<!--              <ul>-->
<!--                <li><a [routerLink]="'/credit'" routerLinkActive="active" >{{'menu_credit_pay' | translate}}</a></li>-->
<!--                <li><a [routerLink]="'/credit/detail'" routerLinkActive="active" >{{'menu_credit_detail' | translate}}</a></li>-->
<!--                <li><a [routerLink]="'/credit/paymentplan'" routerLinkActive="active" >{{'menu_credit_plan' | translate}}</a></li>-->
<!--              </ul>-->
<!--            </mdb-accordion-item-body>-->
<!--          </mdb-accordion-item>-->
<!--          <mdb-accordion-item [ngClass]="'no-collase'">-->
<!--            <div>-->
<!--              <mdb-accordion-item-head [routerLink]="'/dpf'" routerLinkActive="active" mdbWavesEffect>-->
<!--                <div class="cls_icon_var_menu">-->
<!--                  <svg-icon src="assets/util/images/icons/payment.svg"-->
<!--                            [svgStyle]="{ 'height.px':20, 'width.px':22}">-->
<!--                  </svg-icon>-->
<!--                  <span class="PL10">{{'menu_pdf' | translate}}</span>-->
<!--                </div>-->
<!--              </mdb-accordion-item-head>-->
<!--            </div>-->
<!--          </mdb-accordion-item>-->
<!--&lt;!&ndash;          <mdb-accordion-item [ngClass]="'no-collase'">&ndash;&gt;-->
<!--&lt;!&ndash;            <div>&ndash;&gt;-->
<!--&lt;!&ndash;              <mdb-accordion-item-head [routerLink]="'/beneficiary'" routerLinkActive="active" mdbWavesEffect>&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="cls_icon_var_menu">&ndash;&gt;-->
<!--&lt;!&ndash;                  <i class="fas fa-users" style="font-size: 1rem"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                  <span>{{'menu_beneficiaries' | translate}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;              </mdb-accordion-item-head>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </mdb-accordion-item>&ndash;&gt;-->

<!--          <mdb-accordion-item [ngClass]="'no-collase'" class="cls_visible_tab_web">-->
<!--            <div>-->
<!--              <mdb-accordion-item-head [routerLink]="'/profile'" routerLinkActive="active" mdbWavesEffect>-->
<!--                <div class="cls_icon_var_menu">-->
<!--                  <svg-icon src="assets/util/images/icons/profile.svg"-->
<!--                            [svgStyle]="{ 'height.px':20, 'width.px':22}">-->
<!--                  </svg-icon>-->
<!--                  <span class="PL10">{{'menu_perfil' | translate}}</span>-->
<!--                </div>-->
<!--              </mdb-accordion-item-head>-->
<!--            </div>-->
<!--          </mdb-accordion-item>-->

<!--          <mdb-accordion-item [ngClass]="'no-collase'">-->
<!--            <div (click)="logout()">-->
<!--              <mdb-accordion-item-head  routerLinkActive="active" mdbWavesEffect>-->
<!--                <div class="cls_icon_var_menu">-->
<!--                  <mdb-icon fas icon="fas fa-sign-out-alt" ></mdb-icon>-->
<!--                  <span >{{'logout' | translate}}</span>-->
<!--                </div>-->
<!--              </mdb-accordion-item-head>-->
<!--            </div>-->
<!--          </mdb-accordion-item><-->
<!--        </mdb-accordion>-->
        <mdb-accordion [multiple]="false" aria-multiselectable="false">
          <mdb-accordion-item [ngClass]="'no-collase'" class="cls_visible_tab_web cls_button_color">
            <div>
              <mdb-accordion-item-head [routerLink]="''" routerLinkActive="active" mdbWavesEffect>
                <div class="cls_icon_var_menu">
                  <svg-icon src="assets/util/images/icons/home.svg"
                            [svgStyle]="{ 'height.px':20,'width.px':22}">
                  </svg-icon>
                  <span class="PL10">{{'menu_inicio' | translate}}</span>
                </div>
              </mdb-accordion-item-head>
              <mdb-accordion-item-body></mdb-accordion-item-body>
            </div>
          </mdb-accordion-item>
          <mdb-accordion-item  [ngClass]="item.items != undefined && item.items.length == 0?'no-collase':''"  *ngFor="let item of model; let i = index">
            <div *ngIf="(item.items == undefined || item.items.length == 0) && !item.routerLink.includes('legal')">
              <mdb-accordion-item-head [routerLink]="item.routerLink" routerLinkActive="active" mdbWavesEffect>
                <mdb-icon fas [icon]="item.icon" class="mr-1"></mdb-icon>
                {{ item.label }}
              </mdb-accordion-item-head>
              <mdb-accordion-item-body></mdb-accordion-item-body>
            </div>
            <div *ngIf="(item.routerLink.includes('legal') )&& (currentUser.client.rol != '3' &&  currentUser.client.rol != '4') ">
              <mdb-accordion-item-head [routerLink]="item.routerLink" routerLinkActive="active" mdbWavesEffect>
                <mdb-icon fas [icon]="item.icon" class="mr-1"></mdb-icon>
                {{ item.label }}
              </mdb-accordion-item-head>
              <mdb-accordion-item-body></mdb-accordion-item-body>
            </div>
            <div *ngIf="item.items != undefined && item.items.length > 0">
              <mdb-accordion-item-head mdbWavesEffect>
                <mdb-icon fas [icon]="item.icon" class="mr-1"></mdb-icon>
                {{ item.label }}
              </mdb-accordion-item-head>

              <mdb-accordion-item-body>
                <ul *ngIf="item.items != undefined && item.items.length > 0">
                  <li *ngFor="let menu of item.items; let i = index ">
                    <a [routerLink]="menu.routerLink" routerLinkActive="active" href="#"
                       mdbWavesEffect>{{ menu.label }} </a>
                  </li>
                </ul>
              </mdb-accordion-item-body>
            </div>
          </mdb-accordion-item>

          <mdb-accordion-item [ngClass]="'no-collase'" class="cls_visible_tab_web">
            <div>
              <mdb-accordion-item-head [routerLink]="'/profile'" routerLinkActive="active" mdbWavesEffect>
                <div class="cls_icon_var_menu">
                  <svg-icon src="assets/util/images/icons/profile.svg"
                            [svgStyle]="{ 'height.px':20, 'width.px':22}">
                  </svg-icon>
                  <span class="PL10">{{'menu_perfil' | translate}}</span>
                </div>
              </mdb-accordion-item-head>
            </div>
          </mdb-accordion-item>

          <mdb-accordion-item [ngClass]="'no-collase'">
            <div (click)="logout()">
              <mdb-accordion-item-head  routerLinkActive="active" mdbWavesEffect>
                <div class="cls_icon_var_menu">
                  <mdb-icon fas icon="fas fa-sign-out-alt" ></mdb-icon>
                  <span >{{'logout' | translate}}</span>
                </div>
              </mdb-accordion-item-head>
            </div>
          </mdb-accordion-item>
        </mdb-accordion>
      </ul>
    </li>
    <div class="color_white class_power cls_center copyrights">
      <div class="container">
        <div class="item1">
          © {{year}} {{'login_powerby' | translate}}:
        </div>
      </div>
      <div class="container item2">
        <a href="http://www.tesabiz.com/" target="_blank" >
          <img src="assets/util/images/logotipow2.png" alt="GFF">
        </a>
      </div>
      <label class="cls_version">V-{{_VERSION}}</label>
    </div>
    <!-- Logo -->

    <!--/. Side navigation links -->
  </mdb-side-nav>
  <!--/. Sidebar navigation -->
</header>

export class HistoricalTrazeModel {
  historicalTrazeId: number;
  token: string;
  userName: string;
  path: string;
  action: string;
  dateRegister: Date;
  platform: string;
  os: string;
  ip: string;
  _dateRegister: string;
}

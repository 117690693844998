<div #div_back class="btn_back btn_back_top FL" *ngIf="!completeOrder">
  <mdb-icon fas icon="chevron-left" class="bb_icon"></mdb-icon>
</div>

<div  >
  <div >
    <mdb-stepper #stepper >
      <mdb-step name="st_1" [stepForm]="frm1" >
        <div  class="transfer">
          <div class="text-center" *ngIf="!neoEditBeneficiary">
            <h4 class="cls_title_2"
                [innerHTML]="this.entity.cuentaPropia? ('tra_titulo_acc' | translate) : ('tra_titulo_ben' | translate)"></h4>
          </div>
          <form #frm1="ngForm"  >
            <div class="md-form mb-4"  *ngIf="this.listAccountAll.length > 1 && !neoEditBeneficiary">
              <div class="cls_radio">
                <label class="cls_label_radio" style="float: left; margin-top: -10px">{{'tra_i_cuenta_propia' | translate}} *</label>
                <div class="clas_r PL10">
                  <input
                    [(ngModel)]="entity.cuentaPropia"
                    type="radio"
                    class="form-check-input cls_input_radio"
                    id="materialInline10"
                    [value]="true"
                    name="currency"
                    mdbInput>
                  <label class="form-check-label"
                         for="materialInline10">{{'yes' | translate}}</label>
                </div>
                <div class="clas_r PL20">
                  <input
                    [(ngModel)]="entity.cuentaPropia"
                    type="radio"
                    class="form-check-input cls_input_radio"
                    id="materialInline11"
                    [value]="false"
                    name="currency"
                    mdbInput>
                  <label class="form-check-label"
                         for="materialInline11">{{'not' | translate}}</label>
                </div>
              </div>
              <br/>
            </div>
            <div class="MT50" *ngIf="step == 1">
              <div *ngIf="!entity.cuentaPropia && this.listAccountAll.length > 0" >
                <app-beneficiary
                  [hideTitle]="true"
                  [useSelect]="true"
                  [sinagrupar]="false"
                  (onSelectBeneficiary)="onSelectBeneficiary($event)"
                  (onNeoEdit)="actionNeoEditBeneficiary($event)">
                </app-beneficiary>
              </div>

              <div class="propia" *ngIf="entity.cuentaPropia">
                <div
                  class="col-12 col-md-6 col-lg-4 MB20"
                  *ngFor="let data of listAccountAll" (click)="onSelectAccountDest(data)">
                  <app-card-account-w
                    [account]="data"
                    [selected]="selectAccountDest.numAccount == data.numAccount">
                  </app-card-account-w>
                </div>
              </div>

              <input [(ngModel)]="selectBeneficiary.beneficiaryId"
                     name="beneficiaryId"
                     type="number"
                     hidden
                     mdbInput
                     required
                     autocomplete="off">
            </div>
          </form>
        </div>

      </mdb-step>

      <mdb-step name="st_2" [stepForm]="frm2" >
        <div class="transfer2">
          <div class="text-center">
            <h4 class="cls_title_2" [innerHTML]="'tra_title_data' | translate"></h4>
          </div>
          <form #frm2="ngForm" (ngSubmit)="onNextAccount()">
            <div class="container">
              <div class="column-container">
                <div class="column md-form ">
                  <mdb-select #accountNumberOri="ngModel"
                              [(ngModel)]="entity.accountNumberOri"
                              name="accountNumberOri"
                              id="idaccountNumberOri"
                              [options]="listItemAccountOri"
                              [disabled]="listItemAccountOri.length===1"
                              class="form-control cls_select cls_select_white"
                              required
                              placeholder="{{'tra_i_acounto_select' | translate}}">
                  </mdb-select>
                  <label class="labelStyle" for="idaccountNumberOri" style="margin-top: 10px;">{{'tra_i_acounto' | translate}} *</label>
                  <div *ngIf="!accountNumberOri.valid && (accountNumberOri.dirty || accountNumberOri.touched)">
                    <mdb-error *ngIf="accountNumberOri.hasError('required')"style="margin-top: 5px;">{{cmpReq}}</mdb-error>
                  </div>
                </div>
                <div class="column md-form ">
                  <input #amount="ngModel"
                         [(ngModel)]="entity.amount"
                         currencyMask
                         type="text"
                         id="idamount"
                         name="amount"
                         mdbInput
                         required
                         mdbValidate
                         placeholder="0.00"
                         class="form-control cls_input cls_input_white"
                         validate
                         maxlength="12"
                         minlength="2"
                         autocomplete="off"
                         [options]="{ prefix: '',  thousands: ',', decimal: '.',allowNegative:false  }">
                  <label class="cls_label_top" for="idamount" style="margin-top: 10px;">{{'tra_i_amount' | translate}} *</label>
                  <div *ngIf="!amount.valid && (amount.dirty || amount.touched)">
                    <mdb-error *ngIf="amount.hasError('required')">{{cmpReq}}</mdb-error>
                    <mdb-error *ngIf="amount.hasError('minlength')">{{cmpMin}} 2</mdb-error>
                  </div>
                </div>
              </div>
              <div class="container-fluid cls_center">
                <div class="md-form mb-4">
                  <div class="cls_radio">
                    <label class="cls_label_radio" style="float: left; margin-top: -10px">{{'tra_i_currency' | translate}} *</label>
                    <div class="clas_r PL20" *ngFor="let currency of listItemCurrency">
                      <input
                        [(ngModel)]="entity.currency"
                        type="radio"
                        class="form-check-input cls_input_radio"
                        id="materialInline{{currency.value}}"
                        [value]="currency.value"
                        name="currency"
                        required
                        mdbInput>
                      <label class="form-check-label"
                             for="materialInline{{currency.value}}">{{currency.label}}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="md-form mb-4">
                <input #reason="ngModel"
                       [(ngModel)]="entity.reason"
                       name="reason"
                       type="text"
                       id="idreason"
                       class="form-control cls_input cls_input_white"
                       mdbInput
                       required
                       maxlength="100"
                       minlength="3"
                       mdbValidate
                       autocomplete="off">
                <label for="idreason">{{'tra_i_reason' | translate}} *</label>
                <div *ngIf="!reason.valid && (reason.dirty || reason.touched)">
                  <mdb-error *ngIf="reason.hasError('required')">{{cmpReq}}</mdb-error>
                  <mdb-error *ngIf="reason.hasError('minlength')">{{cmpMin}} 3</mdb-error>
                </div>
              </div>

              <div class="md-form mb-4" *ngIf="originDestFounds(entity)">
                <input #originFound="ngModel"
                       [(ngModel)]="entity.originFound"
                       name="originFound"
                       type="text"
                       id="idoriginFound"
                       class="form-control cls_input cls_input_white"
                       mdbInput
                       required
                       maxlength="100"
                       minlength="6"
                       mdbValidate
                       autocomplete="off">
                <label for="idoriginFound">{{this.limitDescOrig}} *</label>
                <div *ngIf="!originFound.valid && (originFound.dirty || originFound.touched)">
                  <mdb-error *ngIf="originFound.hasError('required')">{{cmpReq}}</mdb-error>
                  <mdb-error *ngIf="originFound.hasError('minlength')">{{cmpMin}} 6</mdb-error>
                </div>
              </div>

              <div class="md-form mb-4" *ngIf="originDestFounds(entity)">
                <input #destFound="ngModel"
                       [(ngModel)]="entity.destFound"
                       name="destFound"
                       type="text"
                       id="iddestFound"
                       class="form-control cls_input cls_input_white"
                       mdbInput
                       required
                       maxlength="100"
                       minlength="6"
                       mdbValidate
                       autocomplete="off">
                <label for="iddestFound">{{this.limitDescDest}} *</label>
                <div *ngIf="!destFound.valid && (destFound.dirty || destFound.touched)">
                  <mdb-error *ngIf="destFound.hasError('required')">{{cmpReq}}</mdb-error>
                  <mdb-error *ngIf="destFound.hasError('minlength')">{{cmpMin}} 6</mdb-error>
                </div>
              </div>
              <div class="md-form mb-4" *ngIf="originDestFounds(entity)">
                <div class="text-center">
                  <h4 class="cls_title_2"
                      [innerHTML]="('tra_limit_amout' | translate)"></h4>
                </div>
              </div>
            </div>

            <div class="W100">
              <div class="cls_center">
                <button mdbBtn class="gradient-custom-2"
                        mdbWavesEffect
                        [disabled]="!frm2.valid || submit"
                        (click)="resetButton()"
                        type="submit">{{'tra_next' | translate}}
                </button>
              </div>
              <br>
            </div>
          </form>
        </div>
      </mdb-step>

      <mdb-step name="st_3" [stepForm]="frm3" >
        <div class="transfer3">
          <div class="text-center">
            <h4 class="cls_title_2" [innerHTML]="'tra_title_resume' | translate"></h4>
          </div>
          <div class="container">
            <form #frm3="ngForm" (ngSubmit)="confirm.onShow()">
              <div *ngIf="step==3">
                <div class="cls_padding">
    <div *ngIf="completeOrder && transfACHL==false" class="col-14 col-md-9  mx-auto cls_message">
                    {{'tran_succes_propios' | translate}}
                  </div>
                  <div *ngIf="completeOrder && transfACHL" class="col-14 col-md-9  mx-auto cls_message">
                    {{'tran_succes' | translate}}
                  </div>
                </div>

                <br>
                <div class="col-15 col-md-10  mx-auto">
                  <app-card-comprobante
                    [nombreOri]="nameOriginante"
                    [cuentaOri]="selectAccountOri.numAccount"
                    [bancoOri]="nameBankOrig"
                    [nombreDes]="entity.cuentaPropia? nameOriginante:selectBeneficiary.accountName"
                    [cuentaDes]="selectBeneficiary.accountNumber"
                    [bancoDes]="entity.cuentaPropia? nameBankOrig:selectBeneficiary.bankName"
                    [importe]="entity.amount"
                    [moneda]="getCurencyLabel(entity.currency)"
                    [glosa]="entity.reason"
                    [fechaTransaccion]="entity.dateTransaction"
                    [txNumberCore]="entity.txNumberCore"
                    [complete]="completeOrder"
                    [estado]="completeOrder? status:null"
                    [type_trans]="entity.txType.toLowerCase()">
                  </app-card-comprobante>
                </div>

                <div *ngIf="!completeOrder" class="W100 FZ8 col-12 col-md-8 mx-auto confirmation_layout_Transfer" >
                  <div style="display: table; width: 100%; clear: both;">
                    <div style="margin-bottom: 100px">
                      <div style="display: block; width: 85%; float: left">
                        <span>{{'com_crt_confirmation_message' | translate}}</span>
                      </div>
                      <div style=" width: 15%; float: left; text-align: right; padding-top: 5px;">
                        <label class="switch">
                          <input type="checkbox" id="switch" (change)="enableButton()">
                          <span class="slider" ></span>
                        </label>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="cls_center PT20" *ngIf="!completeOrder">
                  <button mdbBtn class="gradient-custom-2"
                          mdbWavesEffect
                          [disabled]="!frm3.valid || submit || buttonStatus"
                          type="submit">{{'tra_end' | translate}}
                  </button>
                </div>
                <div class="cls_center PT20" *ngIf="completeOrder">
                  <button mdbBtn class="gradient-custom-2"
                          style="padding-right: 20px;"
                          mdbWavesEffect
                          (click)="back_to_menu()"
                          [disabled]="!frm3.valid || submit"
                          type="submit">{{'close' | translate}}
                  </button>
                </div>

              </div>
            </form>
          </div>
        </div>
      </mdb-step>
    </mdb-stepper>
  </div>
</div>
<app-confirm-key #confirm
             text="{{'tra_submit_confirm' | translate}}"
             (success)="onSubmit(confirm)"></app-confirm-key>

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-card-beneficiary',
  templateUrl: './card.beneficiary.component.html',
  styleUrls: ['./card.beneficiary.component.scss'],
})

export class CardBeneficiaryComponent implements OnInit {

  @Input() idBeneficiary: number;
  @Input() fullName: string;
  @Input() nameBank: string;
  @Input() logoBank: string;
  @Input() numberAccount: string;
  @Input() identityType: string;
  @Input() aliasName: string;
  @Output() editBeneficiary = new EventEmitter();
  @Output() removeBeneficiary = new EventEmitter();
  @Output() selectBeneficiary = new EventEmitter();

  name: string;

  constructor(
  ) {
  }

  ngOnInit() {
    this.name = this.fullName;
  }

  edit(){
    this.editBeneficiary.emit(this.idBeneficiary);
  }
  remove(){
    this.removeBeneficiary.emit(this.idBeneficiary);
  }
  select(){
    this.selectBeneficiary.emit(this.idBeneficiary);
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {FORMS} from '@config/config';
import {AccountDto} from '@models/account';


@Component({
  selector: 'app-card-account-w',
  templateUrl: './card.account.w.component.html',
  styleUrls: ['./card.account.w.component.scss'],
})
export class CardAccountWComponent implements OnInit {

  @Input() account: AccountDto;
  @Input() selected: boolean;

  cmpReq = FORMS.FRM_REQUIRED;
  cmpMin = FORMS.FRM_MINIMO;
  submitted = false;
  currency = '';

  constructor(
  ) {

  }

  ngOnInit() {
    this.currency = this.account.currency.includes('B') ? 'Bs.' : 'USD';
  }

  onSelected(): any {
    this.selected = true;
  }
}

<header>
  <section class="view cls_page_control">
    <div class="row">
      <div class="col-md-6 col-sm-12 cls_page_content">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h1 class="heading display-3 text-danger">Ocurri&oacute; un error</h1>
          <img src="assets/util/images/pages/icon-error.svg">
          <h4 class="subheading font-weight-bold text-danger">Algo salió mal.</h4><br/><br/>
          <button type="button" [routerLink]="['/']" class="btn btn-danger btn-rounded">Ir al Inicio<i class="fas fa-caret-right ml-3"></i></button>
        </div>
      </div>
    </div>
  </section>
</header>

import {DOMAIN, FORMS, SERVICES} from './../../config/config';
import {Component, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChild} from '@angular/core';
import {BeneficiaryModel, BeneficiaryModel1, BeneficiaryModel2, BeneficiaryModel3} from '@models/beneficiary';
import {AuthService} from '@services/auth.service';
import {BaseModel} from '@models/util/base.model';
import {BeneficiaryService} from '@services/beneficiary.service';
import {TranslateService} from '@ngx-translate/core';
import {MenuService} from '@pages/template/menu/menu.service';
import {BeneService} from '@pages/beneficiary/bene.service';
import {Observable, Subscription} from 'rxjs';
import {HistoricalTrazeService} from '@services/historical.traze.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Router} from '@angular/router';
import {D1, DomainModel} from '@models/domain';
import {ItemsModel} from '@models/util';
import {DomainService} from '@services/domain.service';
import {MessageService} from '@app/error/message.service';
import {ConfirmKeyComponent} from '@app/components/confirm/confirm-key/confirm-key.component';
import {BankbeneModel} from '@models/beneficiary/bankbene.model';
import {TR1} from '@models/titular/tr1.model';
import {TC1} from '@models/titular/tc1.model';
import {AccountService} from '@services/account.service';
import {TR2} from '@models/titular/tr2.model';
import { ConstantPool } from '@angular/compiler';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { FormsModule } from '@angular/forms'; // Asegúrate de importar FormsModule
import { MdbSelectFilterComponent } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-beneficiary',
  templateUrl: './beneficiary.component.html',
  styleUrls: ['./beneficiary.component.scss'],
})
export class BeneficiaryComponent extends BaseModel implements  OnInit, OnDestroy {
  @ViewChild('filter', { static: false }) filter: MdbSelectFilterComponent;

  @Input() useSelect: boolean;
  @Input() hideTitle: boolean;
  @Input() sinagrupar: boolean;
  @Input() back: boolean;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onSelectBeneficiary = new EventEmitter();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onNeoEdit = new EventEmitter();

  cmpReq = FORMS.FRM_REQUIRED;
  cmpMin = FORMS.FRM_MINIMO;
  submit = false;

  listAll: BeneficiaryModel[] = [];
  list: BankbeneModel[] = [];
  entity: BeneficiaryModel;
  listBancos: ItemsModel[] = [];
  listTipos: ItemsModel[] = [];
  listCurrency: ItemsModel[] = [];

  selectEntity = new BeneficiaryModel();
  deleteBenefyEntity = new BeneficiaryModel();

  edit: boolean = false;
  new: boolean;
  key = '';

  nroCtaBenef : string;

  totalRecords: number;
  filterBene: string;
  codeBankUser: string;

  private subscription: Subscription;

  constructor(
    protected authService: AuthService,
    protected domainService: DomainService,
    protected beneficiaryService: BeneficiaryService,
    private translate: TranslateService,
    private menuService: MenuService,
    protected historical: HistoricalTrazeService,
    protected deviceService: DeviceDetectorService,
    private beneService: BeneService,
    private messageService: MessageService,
    private accountService: AccountService,
    private router: Router

  ) {
    super(authService, historical, deviceService);
    super.registerEvent('Beneficiarios', this.router.url);
    this.codeBankUser = this.authService.getCurrentUser().client.codeBank;
  }


opcionesFiltradas: any;

searchInput = new FormControl('');

  filteredOptions: Observable<any[]>;
  options = [
    { value: '1', label: '--Seleccione--' },
  ];




  ngOnInit() {
    this.listBanks(true);

    this.subscription = this.beneService.beneficiaryState
      .subscribe((back: boolean) => {
        this.onBack();
      });

    this.entity = new BeneficiaryModel();

    this.filteredOptions = this.searchInput.valueChanges.pipe(
      startWith(''),
      map((value: string) => this.filtro(value))
    );
    console.log("filteredOptions",this.filteredOptions);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  filtro(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option: any) => option.label.toLowerCase().includes(filterValue));
  }

  onSelected(selectEntity: BeneficiaryModel): any {
    if (this.useSelect) {
      this.selectEntity = selectEntity;
      this.onSelectBeneficiary.emit(selectEntity);
    }
  }

  listBanks(loadBene: boolean) {
    this.listBancos = [];
    const d1 = new D1(this.getHeader(this.authService.getUser()), DOMAIN.DOM_BANCK);

    this.domainService.listDomain(d1).subscribe(
      (data: any) => {
        //this.listBancos.push(new ItemsModel(this.authService.getCurrentUser().client.nameBank, this.codeBankUser));

        this.listBancos = this.listBancos.concat(new ItemsModel().getItems(data.list));
        this.options = this.listBancos;
        console.log("Listado de bancos", this.listBancos);
        console.log("Listado de options", this.options);
        if (loadBene) {
          this.onListBeneficiaries();
        }
      }
    );
  }

  private listarTipos() {
    this.listTipos = [];
    const d1 = new D1(this.getHeader(this.authService.getUser()), DOMAIN.DOM_TIPO_CUENTA);

    this.domainService.listDomain(d1).subscribe(
      (data: any) => {
        this.listTipos = new ItemsModel().getItems(data.list);
        if (this.entity.beneficiaryId > 0) {
          this.entity.typeAccount = this.listTipos.find(x => x.label.toLowerCase() === this.entity.typeAccount.toLowerCase()).value;
        }
      }
    );
  }

  private listarMonedas() {
    this.listCurrency = [];
    const d1 = new D1(this.getHeader(this.authService.getUser()), DOMAIN.DOM_CURRENCY);

    this.domainService.listDomain(d1).subscribe(
      (data: any) => {
        this.listCurrency = new ItemsModel().getItems(data.list);
      }
    );
  }

  onListBeneficiaries() {
    this.totalRecords = 0;
    this.list = [];
    const beneficiaryModel3 = new BeneficiaryModel3(this.getHeader(this.authService.getUser()), null, null);

    this.submit = true;
    console.log('OnListBeneficiary1::', beneficiaryModel3);
    this.beneficiaryService.list(beneficiaryModel3).subscribe(data => {
        console.log('OnListBeneficiary2::', data);
        this.submit = false;
        this.listAll = data.listBeneficiary;
        this.onChangeFilterBene(null);
        this.totalRecords = data.count;
      },
      error => {
        this.submit = false;
      });
  }

  newBeneficiary() {
    this.entity = new BeneficiaryModel();
    if (this.listCurrency.length === 0) {
      this.listarMonedas();
    }
    if (this.listTipos.length === 0) {
      this.listarTipos();
    }
    if (this.listBancos.length === 0) {
      this.listBanks(false);
    }
    this.new = true;
    this.actionNeoEdit();
  }

  onConfirm(modal: ConfirmKeyComponent) {
    this.key = '';
    modal.onShow();
  }

  onSubmit(key: string, modal: ConfirmKeyComponent) {


    const enty = this.entity.clone(this.entity);
    const beneficiaryModel1 = new BeneficiaryModel1(this.getHeader(this.authService.getUser()), enty, key);
    beneficiaryModel1.beneficiary.enabled = true;

    var cantCI = beneficiaryModel1.beneficiary.ci.replace( /\D+/g, "").length;
    console.log("digitos", cantCI);
    if(cantCI < 4){
      this.messageService.showWarning(this.translate.instant('ben_ci_cant'));
      return;
    }


    this.submit = true;

    this.nroCtaBenef = beneficiaryModel1.beneficiary.accountNumber;

  if(this.edit == false){
    if (this.validarNumeroCuentaFront(beneficiaryModel1.beneficiary, this.listAll)){
      this.messageService.showError(this.translate.instant('ben_double_inf'));
      this.submit=false;
      return;
    }
  }

    if (enty.beneficiaryId > 0) {
      this.beneficiaryService.merge(beneficiaryModel1).subscribe(
        (data: BeneficiaryModel2) => {
          this.submit = false;
          if (data.header.codReturn !== '0') {
            this.messageService.showError(data.header.txtReturn);
          } else {
            console.log(this.translate.instant('save_success'));
            this.messageService.showSuccess(this.translate.instant('save_success'));
            super.registerEvent('Se modifico Beneficiario : ' + enty.alias, this.router.url);

            this.edit = false;
            this.new = false;
            modal.onHide();
            this.onListBeneficiaries();
          }
        },
        fin => {
          this.submit = true;
        }
      );
      return;
    }
    this.beneficiaryService.persist(beneficiaryModel1).subscribe(
      (data: BeneficiaryModel2) => {
        this.submit = false;
        if (data.header.codReturn !== '0') {
          this.messageService.showError(data.header.txtReturn);
        } else {
          this.messageService.showSuccess(this.translate.instant('save_success'));
          super.registerEvent('Se registro de nuevo Beneficiario : ' + this.entity.alias, this.router.url);

          this.edit = false;
          this.new = false;
          modal.onHide();
          this.onListBeneficiaries();
        }
      },
      error => {
        this.submit = false;
      }
    );
  }

  onEditBene(bene: BeneficiaryModel) {
    this.entity = new BeneficiaryModel().clone(bene);
    console.log('onEditBene', this.entity);
    this.edit = true;

    if (this.listCurrency.length === 0) {
      this.listarMonedas();
    }
    this.listarTipos();
    if (this.listBancos.length === 0) {
      this.listBanks(false);
    }

    this.actionNeoEdit();
  }

  onConfirmDelete(bene: BeneficiaryModel, modal: ConfirmKeyComponent) {
    this.deleteBenefyEntity = new BeneficiaryModel().clone(bene);
    this.key = '';
    modal.onShow();
  }

  onSubmitDelete(key: string, modal: ConfirmKeyComponent) {
    const header = this.getHeader(this.authService.getUser());
    const beneficiaryModel1 = new BeneficiaryModel1(header, this.deleteBenefyEntity, key);
    console.log(beneficiaryModel1);
    beneficiaryModel1.beneficiary.enabled = false;
    console.log(beneficiaryModel1);
    this.beneficiaryService.merge(beneficiaryModel1).subscribe(
      (data: BeneficiaryModel2) => {
        if (data.header.codReturn !== '0') {
          modal.clear();
          this.key = '';
          this.messageService.showError(data.header.txtReturn);
        } else {
          modal.onHide();
          this.key = '';
          this.messageService.showSuccess(this.translate.instant('remove_success'));
          super.registerEvent('Se elimino Beneficiario : ' + this.deleteBenefyEntity.alias, this.router.url);
          this.onListBeneficiaries();
        }
      }
    );
  }

  onSelectBene(idBeneficiary: number, selectBene: BeneficiaryModel) {
    this.onSelected(selectBene);
  }

  onBack() {
    this.new = false;
    this.edit = false;
    this.actionNeoEdit();
  }

  actionNeoEdit(): any {
    if (this.new || this.edit) {
      this.onNeoEdit.emit(true);
    } else {
      this.onNeoEdit.emit(false);
    }
  }

  onChangeFilterBene(value: any) {
    this.list = [];
    let listAux = [];
    if (value != null && value.trim().length > 0) {
      listAux = this.listAll.filter(x => x.accountName.toUpperCase().includes(value.toUpperCase()) || x.alias.toUpperCase().includes(value.toUpperCase()));
    } else {
      listAux = this.listAll.filter(x => 1 === 1);
    }

    if (listAux.length > 0) {
      if (this.listBancos.length > 0) {
        const eifs = listAux.map(item => item.eif).filter((value, index, self) => self.indexOf(value) === index);

        for (const eif of eifs) {
          const bank = this.listBancos.find(x => x.value === eif);
          const benes = listAux.filter(x => x.eif === eif);
          this.list.push(new BankbeneModel(benes, benes[0].eif, bank.label, benes.length));
        }
      } else {
        this.list.push(new BankbeneModel(listAux, null, null, listAux.length));
      }
    }
  }

  onChangeBank(valor: string) {
    console.log('onChangeBank', valor);


    this.searchInput.setValue('');
    if (valor === this.codeBankUser) {
      const header = this.getHeader(this.authService.getUser());
      const tr1 = new TR1();
      tr1.canal = SERVICES.CANAL_WEB;
      tr1.numeroCuenta = this.entity.accountNumber;
      const tc1 = new TC1(header, tr1);
      this.submit = true;
      console.log(tc1);
      this.accountService.titular(tc1).subscribe(
        (tr2: TR2) => {
          console.log('RESPONSE::', tr2);
          this.submit = false;
          if (tr2.header.codReturn !== '0') {
            this.messageService.showWarning(this.translate.instant('find_beneficiary') + ' ' + tr1.numeroCuenta);
          } else {
            this.entity.accountName = `${tr2.details.nombre} ${tr2.details.apellidoPaterno} ${tr2.details.apellidoMaterno}`;
            this.entity.typeAccount = tr2.details.tipoCuenta;
            this.entity.ci = tr2.details.numeroCI;
            this.entity.currency = tr2.details.moneda;
            this.entity.bankName = (this.listBancos.find(x => x.value === this.codeBankUser)).label;
            this.entity.eif = (this.listBancos.find(x => x.value === this.codeBankUser)).value;
          }
        },
        error => {
          this.messageService.showError(this.translate.instant('find_error'));
        });
    }
    else {
      this.submit = false;
    }

    this.clearFilter();

  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  validarNumeroCuenta() {
    if (this.entity.eif != null){
      this.onChangeBank(this.entity.eif);
    }
}

validarNumeroCuentaFront(benef: BeneficiaryModel, listCta : BeneficiaryModel[]) {
  var sw = false;
  if (benef.accountNumber != '' && listCta.length > 0){

    listCta.forEach(function(a) {
      if(benef.eif == a.eif){
          if(benef.accountNumber == a.accountNumber){
            sw = true;
            return sw;
          }
      }
    });
  }
  return sw;
}
clearFilter() {
  console.log("filter:", this.filter );
  if (this.filter) {
    const filterInput = this.filter.input.nativeElement;
    filterInput.value = '';  // Establece el valor del filtro a una cadena vacía
    filterInput.dispatchEvent(new Event('input'));  // Dispara un evento de entrada para que se actualice el filtro
  }
}
}

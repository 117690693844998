<div class="card_order" (click)="select()">
  <div style="width: 100%;display: flex; text-align: center;">
    <div style="width: 25%">
      <b>{{detail.numeroCuota}}</b>
    </div>
    <div style="width: 25%">
      <div class="card_order_body color_primario">
        <span>{{detail.fechaCuota }}</span>
      </div>
    </div>
    <div style="width: 25%">
      <div class="card_order_body color_primario">
        <span>{{detail.capital}} {{currency}}</span>
      </div>
    </div>
    <div style="width: 25%">
      <div class="card_order_body color_primario">
        <span>{{detail.saldoCapital}} {{currency}}</span>
      </div>
    </div>
  </div>

</div>

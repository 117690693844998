import { Component, OnInit } from '@angular/core';
import {BaseModel} from "@models/util/base.model";
import {
  Cre3Model,
  Cre5Model,
  CreditDetailModel,
  CreditModel,
  Crt11Model,
  Crt12Model,
  PaymentBreadModel
} from "@models/credit";
import {AuthService} from "@services/auth.service";
import {DomainService} from "@services/domain.service";
import {TranslateService} from "@ngx-translate/core";
import {HistoricalTrazeService} from "@services/historical.traze.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {MessageService} from "@app/error/message.service";
import {BeneService} from "@pages/beneficiary/bene.service";
import {AccountService} from "@services/account.service";
import {CreditService} from "@services/credit.service";
import {Router} from "@angular/router";
import {SERVICES} from "@config/config";
import {HistoryAccountDto} from "@models/history";
import {ModalDirective} from "ng-uikit-pro-standard";

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent extends BaseModel implements OnInit {
  list: CreditModel[] = [];
  listDetail: CreditDetailModel[] = [];
  currency : string;
  numeroCredito : string;
  selectCreditView: CreditDetailModel;
  constructor(
    protected authService: AuthService,
    protected domainService: DomainService,
    private translate: TranslateService,
    protected historical: HistoricalTrazeService,
    protected deviceService: DeviceDetectorService,
    private messageService: MessageService,
    private beneService: BeneService,
    public accountService: AccountService,
    public creditService: CreditService,
    private router: Router
  ) {
    super(authService, historical, deviceService);
    super.registerEvent('Detalle de creditos', this.router.url);

  }
  ngOnInit(): void {
    this.onListCredits();
  }
  onListCredits() {
    const user = this.authService.getCurrentUser();
    const creditDto = new CreditModel();
    creditDto.canal = Number(SERVICES.SER_CANAL);
    creditDto.codigoCliente = user.client.accountCode;
    const creditModel3 = new Cre3Model(this.getHeader(this.authService.getUser()), creditDto, user.client.representativeCode);
    this.creditService.list(creditModel3)
      .subscribe((resp: any) => {
        this.list = resp.list;
        if(this.list.length>0){
          this.onLoadDetail(this.list[0]);
          this.currency = this.list[0].moneda.includes('B')?'Bs':'USD';
          this.numeroCredito = this.list[0].numeroCredito;
        }
      });
  }
  onCoruselActive(event: any) {
    this.onLoadDetail(this.list[event.relatedTarget]);
  }
  onLoadDetail(credit: CreditModel) {
    this.currency = credit.moneda;
    this.numeroCredito = credit.numeroCredito;
    const user = this.authService.getCurrentUser();
    const creditDto = new CreditModel();
    creditDto.canal = Number(SERVICES.SER_CANAL);
    creditDto.codigoCliente = user.client.accountCode;
     creditDto.numeroCredito =credit.numeroCredito;
    this.listDetail = [];
    const creditModel3 = new Cre3Model(this.getHeader(this.authService.getUser()), creditDto, user.client.representativeCode);
    this.creditService.find(creditModel3)
      .subscribe((resp: Cre5Model) => {
        console.log(resp);
         if(resp){
          this.listDetail = resp.list;

        }else{
          console.log("Lista Vacia");
          this.listDetail = [];
          console.log("Lista de detail -->",);
        }
        this.currency = credit.moneda.includes('B')?'Bs':'USD';
      },
      (error: any) => {
        console.log("Error -->",error);

      });
  }
  selectedDetailView(order: CreditDetailModel, modal: ModalDirective) {
     this.selectCreditView = order;
     console.log(order);
     modal.show();
  }
}

import {CONFIG} from '@config/config';
import {DeviceHeader} from '@models/device_header';

export class RequestHeaderModel {
  public appVersion: string = CONFIG.APP_VERSION;
  public token: string = null;
  public user: string;
  public deviceInfo: DeviceHeader;
  public idSession: string;

  constructor(user: string, deviceInfo?: DeviceHeader) {
    this.user = user;
    this.deviceInfo = deviceInfo;
  }
}

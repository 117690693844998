<div class="text-center">
  <h4 class="cls_title_2" [innerHTML]="'summary_title' | translate"></h4>
</div>

<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div *ngIf="es_una_cuenta" style="display: flex; justify-content: center;">
        <div *ngFor="let ac of acSumm" style="width: 650px;">
          <app-card-resume [codMoneda]="ac.moneda"
                           [monto]="ac.monto"
                           [cantidad]="ac.cant"
                           [moneda]="ac.titulo">
          </app-card-resume>
        </div>
      </div>
      <div *ngIf="!es_una_cuenta" class="row">
        <div *ngFor="let ac of acSumm" class="col col-md-6 col-sm-6">
          <app-card-resume [codMoneda]="ac.moneda"
                           [monto]="ac.monto"
                           [cantidad]="ac.cant"
                           [moneda]="ac.titulo">
          </app-card-resume>
        </div>
      </div>
    </div>
  </div>
</div>

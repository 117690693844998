import {Component, OnInit} from '@angular/core';
@Component({
  selector: 'app-top',
  templateUrl: './top.html',
  styleUrls: ['./top.component.scss']
})
export class TopComponent implements OnInit {

  constructor() {}

  ngOnInit() {
  }
}

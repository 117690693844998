
<div class="color_terciario class_power cls_center">
  © {{year}} {{'login_powerby' | translate}}:
  <a href="http://www.tesabiz.com/" target="_blank" class="cls_logo_powerby" >
    <img src="assets/util/images/tesabiz.png" alt="GFF">
  </a>
  <br>
  <label class="cls_version">V-{{_VERSION}}</label>
</div>


import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '@services/auth.service';
import {VERSION} from "../../../../environments/version";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private router: Router,
              private authService: AuthService) {

  }

  uri = '';

  ngOnInit() {
    this.uri = window.location.hash;
  }

  go(url: string) {
    this.router.navigate([url]);
  }

  logout(){
    this.authService.logout();
    this.router.navigate(['/login']);
  }

}

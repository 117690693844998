import {Component, Input, OnInit} from '@angular/core';
import {CreditModel} from "@models/credit";
import {FORMS} from "@config/config";

@Component({
  selector: 'app-card-credit',
  templateUrl: './card-credit.component.html',
  styleUrls: ['./card-credit.component.scss']
})
export class CardCreditComponent implements OnInit {
  @Input() credit: CreditModel;
  @Input() selected: boolean;

  cmpReq = FORMS.FRM_REQUIRED;
  cmpMin = FORMS.FRM_MINIMO;
  submitted = false;

  constructor() { }

  ngOnInit(): void {
  }
  onSelected(): any {
    this.selected = true;
  }
}

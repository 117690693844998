<div class="card-account2 {{selected? 'select_account2' : ''}}" (click)="onSelected()">
  <div class="header">
    <div class="cuenta-pay"><b>{{account.numAccount}}</b></div>
    <div class="type-pay">{{account.typeAccount}}</div>
  </div>
  <div class="body">
    <div class="amount-pay color_secundario"><b>{{account.amount+ ' ' +currency}}</b></div>
  </div>
  <div class="footer">
    <div class="bank-pay">{{'conjunta' | translate}}: {{account.state}}</div>
  </div>
</div>


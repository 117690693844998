import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {AuthService} from '@services/auth.service';
import {Observable} from 'rxjs';
import {S1Model} from '@models/security/S1.model';
import {CONFIG, URL_SECURITY} from '@config/config';
import {Config} from '@services/config';
import {map} from 'rxjs/operators';
import {S2Model} from '@models/security/S2.model';
import {StorageUtil} from '@models/util';
import {MessageService} from '@app/error/message.service';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService,
              private message: MessageService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let hasTok = false;
    let authToken;

    if (this.authService.currentUserValue) {
      authToken = this.authService.getAuthorizationToken();

      req = req.clone({
          setHeaders:
            {Authorization: `Token ${authToken}`}
        }
      );
      hasTok = true;
    }

    let tk = '';

    // @ts-ignore
    if (CONFIG.NG_SEC == 'true') {
      if (req.body != null && req.body !== '') {
        const s1 = new S1Model();
        let newUrl = '';
        if (hasTok) {
          newUrl = Config.settings.path.url_root + URL_SECURITY.CIFWT;
          tk = authToken;
          s1.dir2 = StorageUtil.encryptService.enc2(window.location.href.toString(), tk);
        } else {
          newUrl = Config.settings.path.url_root + URL_SECURITY.CIFNT;
          tk = StorageUtil.encryptService.sha(new Date().getTime().toString());
          s1.tk = tk;
        }
        s1.payload = StorageUtil.encryptService.enc2(JSON.stringify(req.body), tk);
        s1.dir = StorageUtil.encryptService.enc2(req.url, tk);
        req = req.clone({url: newUrl, body: s1});
      }
    }

    return next.handle(req).pipe(
      map(evt => {
        if (evt instanceof HttpResponse) {

          // UPDATE TOK
          const neoTk = evt.headers.get('TokenR');
          if (neoTk != null) {
            this.authService.updateToken(neoTk);
          }

          // @ts-ignore
          if (CONFIG.NG_SEC == 'true') {
            let s2 = new S2Model();
            s2 = evt.body;

            if (s2.codReturn != null) {
              if (s2.codReturn === '0') {
                const des = JSON.parse((StorageUtil.encryptService.des2(s2.payload.toString(), tk)));
                console.log('DECRIPT::', des);
                if (des.header.codReturn > 0) {
                  return evt = evt.clone({
                      body: ''
                    }
                  );
                }
                return evt = evt.clone({
                    body: des
                  }
                );
              } else {
                this.message.showError(s2.txtReturn);
              }
            }
          }
        }
        return evt;
      })
    );
  }
}

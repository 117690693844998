import {DomainModel} from '@models/domain';
import {AccountDto} from '@models/account/AccountDto.model';
import {BeneficiaryModel} from '@models/beneficiary';
import {CreditModel} from '@models/credit';
import {TarjetasDto} from '@models/card';

export class ItemsModel {
  label: string;
  value: string;

  constructor(label?: string, value?: string) {
    this.label = label;
    this.value = value;
  }

  domainToItem(c: DomainModel): ItemsModel {
    const model: ItemsModel = new ItemsModel();
    model.label = c.description;
    model.value = c.domValue;
    return model;
  }
  domainToItemDonName(c: DomainModel): ItemsModel {
    const model: ItemsModel = new ItemsModel();
    model.label = c.description;
    model.value = c.domName;
    return model;
  }
  accountToItem(c: AccountDto): ItemsModel {
    const curr = c.currency.includes('B') ? 'Bs.' : 'USD';
    const model: ItemsModel = new ItemsModel();
    model.label = c.numAccount + ' (' + curr + ' ' + c.available + ')';
    model.value = c.numAccount;
    return model;
  }

  nuevo(label: string, values: string): ItemsModel {
    const model: ItemsModel = new ItemsModel();
    model.label = label;
    model.value = values;
    return model;
  }

  getItems(items: DomainModel[]): ItemsModel[] {
    const list: ItemsModel[] = [];
    const im: ItemsModel = new ItemsModel();
    items.forEach(element => {
      list.push(im.domainToItem(element));
    });
    return list;
  }
  getItemsDomName(items: DomainModel[]): ItemsModel[] {
    const list: ItemsModel[] = [];
    const im: ItemsModel = new ItemsModel();
    items.forEach(element => {
      list.push(im.domainToItemDonName(element));
    });
    return list;
  }
  getItemsAccounts(items: AccountDto[]): ItemsModel[] {
    const list: ItemsModel[] = [];
    const im: ItemsModel = new ItemsModel();
    items.forEach(element => {
      list.push(im.accountToItem(element));
    });
    return list;
  }

  beneficiaryToItem(c: BeneficiaryModel): ItemsModel {
    const model: ItemsModel = new ItemsModel();
    model.label = c.accountName;
    model.value = c.accountNumber + '|' + c.ci;
    return model;
  }

  beneficiaryBankToItem(c: BeneficiaryModel): ItemsModel {
    const model: ItemsModel = new ItemsModel();
    model.label = c.accountName + ' - ' + c.eif + ' [' + c.accountNumber + ']' + ' - ' + c.currency;
    model.value = c.accountNumber + '|' + c.ci;
    return model;
  }

  getItemsBeneficiary(items: BeneficiaryModel[]): ItemsModel[] {
    const list: ItemsModel[] = [];
    items.forEach(element => {
      list.push(this.beneficiaryToItem(element));
    });
    return list;
  }

  beneficiaryToItemAutoc(c: BeneficiaryModel): ItemsModel {
    const model: ItemsModel = new ItemsModel();
    model.label = c.accountName + ' (' + c.accountNumber + ')';
    model.value = c.accountNumber + '|' + c.ci;
    return model;
  }

  getItemsBeneficiaryBanck(items: BeneficiaryModel[]): ItemsModel[] {
    const list: ItemsModel[] = [];
    if (items === null) {
      return list;
    }
    items.forEach(element => {
      list.push(this.beneficiaryBankToItem(element));
    });
    return list;
  }

  getItemsBeneficiaryAutoc(items: BeneficiaryModel[]): ItemsModel[] {
    const list: ItemsModel[] = [];
    if (items === null) {
      return list;
    }
    items.forEach(element => {
      list.push(this.beneficiaryToItemAutoc(element));
    });
    return list;
  }

  getItemsCredit(items: CreditModel[]): ItemsModel[] {
    const list: ItemsModel[] = [];
    items.forEach(element => {
      list.push(this.creditToItem(element));
    });
    return list;
  }

  creditToItem(c: CreditModel): ItemsModel {
    const model: ItemsModel = new ItemsModel();
    model.label = c.numeroCredito + ' (' + c.montoDesembolsado + ' ' + c.moneda + ')';
    model.value = c.numeroCredito;
    return model;
  }

  findByLabel(items: ItemsModel[], label: string): ItemsModel {
    return items.find(x => x.label === label);
  }

  getItemsTC(items: TarjetasDto[]): ItemsModel[] {
    const list: ItemsModel[] = [];
    items.forEach(element => {
      list.push(this.tcToItem(element));
    });
    return list;
  }

  tcToItem(c: TarjetasDto): ItemsModel {
    const model: ItemsModel = new ItemsModel();
    model.label = c.numeroTd + ' (' + c.numeroCuenta + ' )';
    model.value = c.codigoTd;
    return model;
  }

}

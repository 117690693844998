<div class="col-md-12">
  <div class="md-form">
    <input #imtext="ngModel"
           mdbInput
           type="text"
           [id]="'idimtext_'+filtro"
           class="form-control"
           name="input"
           [(ngModel)]="content"
           [required]="requerido"
           [mdbTooltip]="descripcion"
           [maxlength]="tamano"
           autocomplete="off">
    <label [for]="'idimtext_'+filtro" class="">{{nombre}} {{requerido? '(*)': ''}}</label>
    <div *ngIf="!imtext.valid && (imtext.dirty || imtext.touched)">
      <mdb-error *ngIf="imtext.hasError('required')">{{cmpReq}}</mdb-error>
    </div>
  </div>
</div>

<div class="row back_unico cls_header_list">
  <div class="container cls_center">
    <h4 class="cls_title_2 color_white" [innerHTML]="'dpf_title' | translate"></h4>

    <div class="W100 border_boton_input border_top_input"
         *ngIf="list.length==0">
      <img src="assets/util/images/Icono_datos_no_encontrados.png" style="display: block; margin-left: auto; margin-right: auto;">
    </div>

    <div class="row back_unico">
      <mdb-carousel [isControls]="true"
                    [interval]="0"
                    (activeSlideChange)="onCoruselActive($event)"
                    class="carousel slide W100"
                    [type]="'carousel'"
                    [animation]="'slide'">
        <mdb-carousel-item *ngFor="let data of list">
          <div class="W100">
            <div style="width: 15% !important; float: left; height: 100%;"></div>
            <div style="width: 70% !important; float: left;">
              <app-card-dpf
                [dpfDto]="data"
                [selected]="true"
              ></app-card-dpf>
            </div>
            <div style="width: 15% !important; float: left; height: 100%;"></div>
          </div>
        </mdb-carousel-item>
      </mdb-carousel>
    </div>
  </div>
</div>
<div class="row cls_body_list" style="height: 50px; display: none;">
</div>

<div class="row ">
  <div  *ngIf="detail!=null && selectDpfView != null" class="W100 border_boton_input border_top_input" >
    <app-card-dpf-detail
      [moneda]="currency"
      [certificado]="selectDpfView.numeroCertificado"
      [deposito]="selectDpfView.numeroDeposito"
      [detailDpf]="detail"
    ></app-card-dpf-detail>
    <div style="height: 100px;">

    </div>
  </div>
</div>

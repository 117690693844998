import {RequestHeaderModel} from '@models/requestHeader.model';

export class Hts5 {
  header: RequestHeaderModel;
  canal: number;
  clave: string;

  constructor(header: RequestHeaderModel, canal: number, clave: string) {
    this.header = header;
    this.canal = canal;
    this.clave = clave;
  }
}

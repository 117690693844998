import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {MenuTitle} from '@pages/template/menu/menu.title';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private menuSubject = new Subject<MenuTitle>();
  menuState = this.menuSubject.asObservable();

  constructor() {
  }

  setTitle(titulo: string) {
    this.menuSubject.next(<MenuTitle> {title: titulo});
  }
}


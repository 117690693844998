import {Component, OnInit, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {LoaderService} from '@services/loader.service';
import {LoaderState} from '@models/loader/loader.model';
import {NotifyService} from '@app/components/notity/notify.service';
import {NotifyModel} from '@app/components/notity/notify.model';

@Component({
  selector: 'app-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss']
})
export class NotifyComponent implements OnInit, OnDestroy {

  show = false;
  text = '';

  private subscription: Subscription;

  constructor(private notifyService: NotifyService) {
  }

  ngOnInit() {
    this.subscription = this.notifyService.notifyModel
      .subscribe((state: NotifyModel) => {
        this.text = state.text;
        this.show = state.show;
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  hide() {
    this.show = false;
  }

}

import { Component, OnInit } from '@angular/core';
import {BaseModel} from "@models/util/base.model";
import {Cre3Model, CreditDetailModel, CreditModel, Crt11Model, Crt12Model} from "@models/credit";
import {AuthService} from "@services/auth.service";
import {DomainService} from "@services/domain.service";
import {TranslateService} from "@ngx-translate/core";
import {HistoricalTrazeService} from "@services/historical.traze.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {MessageService} from "@app/error/message.service";
import {BeneService} from "@pages/beneficiary/bene.service";
import {AccountService} from "@services/account.service";
import {CreditService} from "@services/credit.service";
import {Router} from "@angular/router";
import {DetailleDpfDto, Dpf1, Dpf3, Dpf4, DpfDto} from "@models/dpf";
import {SERVICES} from "@config/config";
import {DpfService} from "@services/dpf.service";

@Component({
  selector: 'app-dpf',
  templateUrl: './dpf.component.html',
  styleUrls: ['./dpf.component.scss']
})
export class DpfComponent extends BaseModel implements OnInit {

  list: DpfDto[] = [];
  currency : string;
  selectDpfView: DpfDto;
  detail: DetailleDpfDto;
  constructor(
    protected authService: AuthService,
    protected domainService: DomainService,
    private translate: TranslateService,
    protected historical: HistoricalTrazeService,
    protected deviceService: DeviceDetectorService,
    private messageService: MessageService,
    private beneService: BeneService,
    public accountService: AccountService,
    public dpfService: DpfService,
    private router: Router
  ) {
    super(authService, historical, deviceService);
    super.registerEvent('Lista de DPF', this.router.url);

  }
  ngOnInit(): void {
    this.onListDpf();
  }
  onListDpf() {
    const user = this.authService.getCurrentUser();
    const dpf1 = new Dpf1(this.getHeader(this.authService.getUser()), Number(SERVICES.SER_CANAL), user.client.accountCode, user.client.representativeCode);
    console.log(dpf1);
    this.dpfService.list(dpf1)
      .subscribe((resp: any) => {
        this.list = resp.list;
        if(this.list.length>0){
          this.selectDpfView = this.list[0];
          this.onLoadDetail(this.list[0]);
          this.currency = this.list[0].moneda;
        }
      });
  }
  onCoruselActive(event: any) {
    this.onLoadDetail(this.list[event.relatedTarget]);
  }
  onLoadDetail(dpfDto: DpfDto) {
    const user = this.authService.getCurrentUser();
    const dpf3 = new Dpf3(this.getHeader(this.authService.getUser()), Number(SERVICES.SER_CANAL), user.client.accountCode,dpfDto.numeroDeposito,user.client.representativeCode);
    this.dpfService.detail(dpf3)
      .subscribe((resp: Dpf4) => {
        let aux = parseFloat(resp.detail.tasa).toFixed(2);
        let value = parseFloat(aux) / 100;
        this.detail = resp.detail;
        this.detail.tasa = '' + value.toFixed(1) + ' %';
      });
  }
}

import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ng-uikit-pro-standard';

  constructor(
    private translate: TranslateService
  ) {
    this.setAppLanguage();
  }

  setAppLanguage() {
    this.translate.setDefaultLang('es');
  }
}

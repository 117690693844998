export class AccountDto {
  numAccount: string;
  currency: string;
  amount: string;
  available: string;
  amountPledged: string;
  typeAccount: string;
  state: string;

  accountCode: string;
  openingDate: string;
  date: string;
  nroCuotas: string;
  fondoInversion: string;
  valorCuota: string;

  constructor(numAccount?: string, currency?: string, amount?: string, available?: string, amountPledged?: string, typeAccount?: string, state?: string) {
    this.numAccount = numAccount;
    this.currency = currency;
    this.amount = amount;
    this.available = available;
    this.amountPledged = amountPledged;
    this.typeAccount = typeAccount;
    this.state = state;
  }
}

import {RequestHeaderModel} from '@models/requestHeader.model';

export class Crt13Model {
  header: RequestHeaderModel;
  canal: number;
  codigoCliente: number;


  constructor(header: RequestHeaderModel, canal: number, codigoCliente: number) {
    this.header = header;
    this.canal = canal;
    this.codigoCliente = codigoCliente;
  }
}

<div class="card-account2 {{selected? 'select_account2' : ''}}">
  <div class="header">
    <div class="accountNumber"> Número de cuenta : <b>{{accountNumber}}</b></div>
  </div>
  <div class="body">

    <div class="nroCuota"><b><span>Monto Disponible</span></b></div>
    <div class="monto">
      <b><span>{{moneda}}</span>&nbsp;{{monto}}</b>
    </div>
    <div class="nroCuota"><b>{{type.toUpperCase().includes('CA') ? 'Caja de Ahorro' : 'Cuenta Corriente'}}</b></div>
  </div>
  <div class="footer">
    <div class="disponible">Saldo Total {{disponible}}  <span>{{moneda}}</span></div>
    <div class="pignorado">Monto Pignorado: {{pignorado}} <span>{{moneda}}</span></div>
  </div>
</div>


export enum CONFIG {
  APP_VERSION = 'APP_WEB',
  CANAL = '556',
  ROWS_TABLE = '10',
  KEY_AES = 'Tiz@B1z2oo9S78b0',
  NG_SEC = 'true',
  ROOT_MARKER_URL = '/files/marker/',
  SYS_ORIGEN = 'PANAMERICAM',
  fecha_sdf = 'dd/MM/yyyy',
  fecha_hora_sdf = 'dd/MM/yyyy HH:mm:ss',
}

/*DOM*/
export enum DOMAIN {
  DOM_BANCK = 'PARTY',
  DOM_TIPO_CUENTA = 'TYPE_ACCOUNT',
  DOM_CURRENCY = 'CURRENCY',
  DOM_TIPO_CREDITO = 'TYPE_CREDIT',
  DOM_COUNTRY = 'COUNTRY',
  DOM_TYPE_LIMIT = 'TYPE_LIM',
  DOM_DPTO_TBZ = 'DPTO_TBZ',
  DOM_OFFICE_TYPE = 'OFFICE_TYPE',
  DOM_LIMIT_TRANS = 'LIMIT_TX'
}

/*LOCAL SESSION*/
export enum LOCSESION {
  LS_CURRENT_USER = 'tbzw_currentUser',
  LS_HISTORY_NUM = 'tbzw_history_num',
  LS_BENEFICIARY = 'tbzw_beneficiary',
  LS_CREDIT = 'tbzw_credit',
  LS_LIMIT = 'tbzw_limit',
  LS_COMSER = 'tbzw_comser',
  LS_LAST_CONSUME = 'tbzw_last_consumer',
  LS_IP = 'tbzw_ip',
  LS_USER_LEGAL = 'tbzw_user_legal',
}

export enum FORMS {
  FRM_REQUIRED = '(*) Requerido',
  FRM_MINIMO = 'Mínimo de caracteres',
}

export enum SERVICES {
  SER_CANAL = '552',
  PROPIAS = 'propia',
  TERCERO = 'terceros',
  ORIGEN = 'Banca móvil origen',
  DESTINO = 'Banca móvil destino',
  ACH = 'ach',
  CANAL_WEB = 552
}

export enum TYPE_TRANS {
  TTRA_TDEBITO = 'TDEBITO',
  TTRA_ACH = 'ACH',
  TTRA_SIMPLE = 'SIMPLE',
}

export enum URL_SECURITY {
  CIFWT = '/tbz-template-security-ng/scng/securityng/cifwt',
  CIFNT = '/tbz-template-security-ng/scng/securityng/cifnt'
}

export enum ETAPA_ONBOARD {
  ET_INICIAL = 'ET_INICIAL',
  ET_BIOMETRICO = 'ET_BIOMETRICO',
  ET_OCR = 'ET_OCR',
  ET_TERM_COND = 'ET_TERM_COND',
  ET_CONTRATO = 'ET_CONTRATO',
}

/* MENSAJES */
export enum MESSAGE {
  ERROR_SERVER = 'Ocurrio un error en el Servidor, favor intente mas tarde.',
  ERROR_FILE_CONFIG = 'Error al leer el archivo de configuración.',
  EMPTY_FIND = 'Datos no encontrados',
}

export enum STATUS {
  Procesando = 'Procesando',
  Confirmada = 'Confirmada',
  Rechazada = 'Rechazada',
  Anulada = 'Anulada'
}



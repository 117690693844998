import {Component, OnDestroy, OnInit} from '@angular/core';
import {CONFIG, FORMS, MESSAGE, SERVICES} from '@config/config';
import {AuthService} from '@services/auth.service';
import {BaseModel} from '@models/util/base.model';
import {IMyOptions, LocaleService, ModalDirective, ToastService} from 'ng-uikit-pro-standard';
import {MessageService} from '@app/error/message.service';
import {TranslateService} from '@ngx-translate/core';
import {DatepickerModel} from '@models/util/datepicker.model';
import {Acc1, AccountDto, Dcc1} from '@models/account';
import {DeviceDetectorService} from 'ngx-device-detector';
import {HistoricalTrazeService} from '@services/historical.traze.service';
import {HistoryAccountDto, Hts1, Hts3, Hts5} from '@models/history';
import {AccountService} from '@services/account.service';
import {DatePipe} from '@angular/common';
import * as moment from 'moment';
import {ItemsModel, StorageUtil} from '@models/util';
import {parse} from "date-fns";

@Component({
  selector: 'app-consults',
  templateUrl: './consults.component.html',
  styleUrls: ['./consults.component.scss']
})
export class ConsultsComponent extends BaseModel implements OnInit, OnDestroy {

  cmpReq = FORMS.FRM_REQUIRED;
  cmpMin = FORMS.FRM_MINIMO;
  submitted = false;
  listOrder: HistoryAccountDto[] = [];
  listOrderAll: HistoryAccountDto[] = [];
  orderFilter: Hts1;
  selectOrderCancel: HistoryAccountDto;
  selectOrderView: HistoryAccountDto;
  today = new Date();

  checkTypeFilter = true;
  myDatePickerOptions: IMyOptions;
  locale;

  listAccount: AccountDto[] = [];
  selectAccountDto: AccountDto;

  dateIni = new Date();
  dateEnd = new Date();
  dateFiltIini: Date;
  dateFiltIFin: Date;
  dateAct = new Date();
  dateActString = new Date().toString();

  fecha_sdf = CONFIG.fecha_sdf;
  fecha_hora_sdf = CONFIG.fecha_hora_sdf;
  nameOriginante: string;
  CCP = 'CCP';
  VCP = 'VCP';

  filterType: string;
  listTypeTx: ItemsModel[] = [];

  currency: string;

  page = 1;
  perPage = 5;
  pagesToShow = 5;
  isInit = true;
  clave: string = '';
  cantidadTransacciones: number;
  accountPosition = 0;
  isData: boolean = false;

  constructor(
    private toast: ToastService,
    protected authService: AuthService,
    protected messageService: MessageService,
    private translate: TranslateService,
    private localeService: LocaleService,
    protected historical: HistoricalTrazeService,
    protected deviceService: DeviceDetectorService,
    public accountService: AccountService,
    public datePipe: DatePipe
  ) {
    super(authService, historical, deviceService);
    this.locale = 'es';
    this.localeService.setLocaleOptions(new DatepickerModel().locales);
  }

  ngOnInit() {
    this.nameOriginante = this.authService.getCurrentUser().client.fullName;
    this.orderFilter = new Hts1();
    this.clearDates();

    this.listAccounts();
    this.load();
    this.loadTypes();
    this.dateFiltIini = new Date();
    this.dateFiltIFin = new Date();
  }

  load() {
    this.myDatePickerOptions = {
      // Format
      dateFormat: 'dd/mm/yyyy',

      // Year limits
      minYear: new Date().getFullYear() - 1,
      maxYear: new Date().getFullYear(),

      height: '70px',
      width: '30%',
      selectionTxtFontSize: '15px'
    };
  }
  ngOnDestroy(): void {
    this.onLib();
  }
  onGen(complete?: () => void) {

    const user = this.authService.getCurrentUser();
    const header = this.getHeader(user.userLogin);
    if (!(typeof this.dateIni === 'object' && this.dateIni instanceof Date)) {
      this.dateIni = parse(this.dateIni, 'dd/MM/yyyy', new Date());
    }
    if (!(typeof this.dateEnd === 'object' && this.dateEnd instanceof Date)) {
      this.dateEnd = parse(this.dateEnd, 'dd/MM/yyyy', new Date());
    }
    if (!this.checkTypeFilter )
    {
       this.dateIni = this.dateFiltIini;
       this.dateEnd = this.dateFiltIFin;
    }
    let hts1 = new Hts1(header,  this.selectAccountDto.numAccount, user.client.accountCode,
      this.perPage, this.page,
      this.datePipe.transform(this.dateIni, 'yyyyMMdd'),
      this.datePipe.transform(this.dateEnd, 'yyyyMMdd'),
      user.client.representativeCode
    );
    console.log('REQUEST_GEN_HISTORY', hts1);
    this.accountService.genHistory(hts1).subscribe(hts4 => {
        console.log('RESPONSE_GEN_HISTORY', hts4);
        this.clave = hts4.clave;
        this.cantidadTransacciones = hts4.cantidadTransacciones;
      },
      error => {
      },
      complete);
  }

  onList(complete?: () => void): void {
    const user = this.authService.getCurrentUser();
    const header = this.getHeader(user.userLogin);
    const hts3 = new Hts3(header, null, this.clave, this.page, this.perPage);
    const hts1 = new Hts1(header, '',
      user.client.accountCode, null, null, this.datePipe.transform(this.dateIni,  'yyyyMMdd'),
      this.datePipe.transform(this.dateEnd, 'yyyyMMdd'), user.client.representativeCode);
    if (hts1.dateInit > this.dateActString || hts1.dateEnd > this.dateActString) {
      this.messageService.showWarning(this.translate.instant('date_bigger_today'));
      return;
    }

    this.onCleanTable();
    console.log('REQUEST_LIST_HISTORY', hts3);

    this.accountService.listHistory(hts3)
      .subscribe((resp: any) => {
          console.log('RESPONSE_LIST_HISTORY', resp);
          if (resp.list != null && resp.list.length > 0) {
            this.listOrder = resp.list;
            this.isData=true;
          }
          this.currency =  this.selectAccountDto.currency;
          console.log('CURRENCY:: ' + this.currency);
        },
        error => {
        },
        complete);
  }

  onLib(complete?: () => void) {
    const user = this.authService.getCurrentUser();
    if (user != null) {
      const hts5 = new Hts5(this.getHeader(this.authService.getUser()), Number(SERVICES.SER_CANAL), this.clave);
      console.log('REQUEST_LIB_HISTORY', hts5);
      this.accountService.libHistory(hts5)
        .subscribe(resp => {
            console.log('RESPONSE_LIB_HISTORY', resp);
            this.clave = '';
            this.cantidadTransacciones = 0;
            this.onCleanTable();
          },
          error => {
          },
          complete);
    }
  }
  onCleanTable() {
    this.listOrder = [];
  }
  onListFilter() {
    this.isData=false;
    this.isInit = false;
    this.page = 1;
    if (!(typeof this.dateIni === 'object' && this.dateIni instanceof Date)) {
      this.dateIni = parse(this.dateIni, 'dd/MM/yyyy', new Date());
    }
    if (!(typeof this.dateEnd === 'object' && this.dateEnd instanceof Date)) {
      this.dateEnd = parse(this.dateEnd, 'dd/MM/yyyy', new Date());
    }
    if (this.dateIni > this.dateEnd) {
      this.messageService.showWarning(this.translate.instant('date_ini_end'));
      return;
    }
    this.dateActString = this.datePipe.transform(this.dateAct, 'yyyyMMdd');
    if (this.clave.length == 0) {
      this.onGen(() => this.onList());
    } else {
      this.onLib(() => this.onGen(() => this.onList()));
    }
  }
  changePage(paginado: any) {
    console.log('PAGINADO: ' + paginado.currentPage);
    this.page = paginado.currentPage;
    this.perPage = paginado.pageSize;
    this.onList();
  }

  loadTypes() {
    this.listTypeTx.push(new ItemsModel('Todos', null));
    this.listTypeTx.push(new ItemsModel('Procesando', 'Procesando'));
    this.listTypeTx.push(new ItemsModel('Confirmada', 'Confirmada'));
    this.listTypeTx.push(new ItemsModel('Rechazada', 'Rechazada'));
    this.listTypeTx.push(new ItemsModel('Anulada', 'Anulada'));
  }

  listAccounts() {
    const user = this.authService.getCurrentUser();
    this.listAccount = [];
    const acc1 = new Acc1(this.getHeader(this.authService.getUser()), user.client.accountCode, user.client.representativeCode);


    this.accountService.list(acc1).subscribe(data => {

        if (data.list != null) {
          this.listAccount = this.reorderList(data.list, this.accountPosition);
          if (data.list.length > 0){
             this.selectAccountDto = this.listAccount[0];
             this.onListFilter();
             this.getDetailAccount();
          }
        }
      },
      error => {
      }, () => {
        if (this.listAccount.length > 0) {
          this.orderFilter.numAccount = this.listAccount[0].numAccount;
          this.currency = this.listAccount[0].currency;
       //   this.listarOrdenes();
        } else {
          this.messageService.showInfo(this.translate.instant('empty_list'));
        }

      });
  }

  getDetailAccount() {
    const user = this.authService.getCurrentUser();
    const dcc1 = new Dcc1(this.getHeader(this.authService.getUser()), Number(SERVICES.SER_CANAL), user.client.accountCode, this.selectAccountDto.numAccount, user.client.representativeCode);
    console.log('dcc1::', dcc1);
    this.accountService.find(dcc1).subscribe(data => {
        console.log('dcc2::', data);
        this.selectAccountDto.amountPledged = data.pignoracion;
        this.selectAccountDto.available = data.disponible;
        this.selectAccountDto.amount = data.saldo;
      },
      error => {
      }, () => {
      });
  }

  changeType() {
    if (this.filterType == null) {
      this.listOrder = this.listOrderAll.filter(x => x.status !== this.filterType);
    } else {
      this.listOrder = this.listOrderAll.filter(x => x.status === this.filterType);
    }
  }

  selectedOrderCancel(order: HistoryAccountDto) {
    this.selectOrderCancel = order;
  }

  clearDates() {
    this.dateIni = new Date();
    this.dateEnd = new Date();
    this.dateEnd.setDate(this.dateEnd.getDate());
    this.dateIni.setHours(0, 0, 0, 0);
    this.dateEnd.setHours(0, 0, 0, 0);
  }

  selectedOrderView(order: HistoryAccountDto, modal: ModalDirective) {
     order.currency= this.currency;
    this.selectOrderView = order;
    console.log('orderView',this.selectOrderView);
    modal.show();
  }

  onCoruselActive(event: any) {
    this.updateAccountPosition(event);
    this.orderFilter.numAccount = this.listAccount[event.relatedTarget].numAccount;
    this.currency = this.listAccount[event.relatedTarget].currency;
    this.selectAccountDto = this.listAccount[event.relatedTarget];
    this.onLib(() => this.onGen(() => this.onList(() => this.getDetailAccount())));
  //  this.listarOrdenes();
  }
  onExcelReport(isExcel: boolean) {
    const user = this.authService.getCurrentUser();
    const header = this.getHeader(user.userLogin);
    let hts1 = new Hts1(header, this.selectAccountDto.numAccount, user.client.accountCode,
      this.perPage, this.page,
      this.isInit ? null : this.datePipe.transform(this.dateIni, 'yyyyMMdd'),
      this.isInit ? null : this.datePipe.transform(this.dateEnd, 'yyyyMMdd'),
      user.client.representativeCode,
      this.selectAccountDto.typeAccount,
      this.selectAccountDto.currency);
    console.log('REQUEST_EXCEL_HISTORY', hts1);
    if(isExcel){
      this.accountService.excel(hts1).subscribe(hts8 => {
          console.log('RESPONSE_EXCEL_HISTORY', hts8);
          this.cantidadTransacciones = hts8.cantidadTransacciones;
          const blob = this.base64ToBlob(hts8.file, 512, isExcel );


          if(this.cantidadTransacciones > 0) {
            this.download(blob, isExcel);
          }else{
            this.messageService.showWarning(this.translate.instant('history_empty_list'));
          }

        },
        error => {
        });
    } else {
      this.accountService.pdf(hts1).subscribe(hts8 => {
          console.log('RESPONSE_PDF_HISTORY', hts8);
          this.cantidadTransacciones = hts8.cantidadTransacciones;
          const blob = this.base64ToBlob(hts8.file, 512, isExcel );
          console.log("cantidad de tran",this.cantidadTransacciones);
          if(this.cantidadTransacciones > 0) {
            this.download(blob, isExcel);
          }else{
            this.messageService.showWarning(this.translate.instant('history_empty_list'));
          }

        },
        error => {
        });
    }

  }
  public base64ToBlob(b64Data, sliceSize , isExcel: boolean) {
    let byteCharacters = atob(b64Data); //data.file there
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    if(isExcel){
      return new Blob(byteArrays, {type: 'application/vnd.ms-excel'});
    } else {
      return new Blob(byteArrays, {type: 'application/pdf'});
    }

  }

  download(blob: Blob, excel : boolean) {
    console.log("blob", blob);
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = excel ? 'historico.xls' : 'historico.pdf' ;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
  reorderList(list: AccountDto[], num: number): AccountDto[] {
    return [...list.slice(num), ...list.slice(0, num)];
  }
  updateAccountPosition(event: any){
    if (event.direction == 'Next') {
      this.accountPosition = this.accountPosition + 1;
    }
    else if (event.direction == 'Prev') {
      this.accountPosition = this.accountPosition - 1;
    }
    if (this.accountPosition < 0) {
      this.accountPosition = this.listAccount.length - 1;
    }
    else if (this.accountPosition > this.listAccount.length - 1) {
      this.accountPosition = 0;
    }
  }

}

<div mdbModal #modal="mdbModal" class="modal fade right modal-scrolling" id="frameModal" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-side modal-bottom-right modal-notify modal-info" role="document">
    <div class="modal-content">
      <div class="modal-header back-primary white-text">
        <h4 class="title">
          Informaci&oacute;n</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div style="width: 100%;text-align: center;position: relative">
          <a [href]="linkAsfi" target="_blank">
            <img style="width: 80%" [src]="urlImage">
          </a>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="row back_unico cls_header_list">
  <div class="container cls_center">
    <h4 class="cls_title_2 color_white" [innerHTML]="'cons_title' | translate"></h4>

    <div class="row back_unico">
      <mdb-carousel [isControls]="true"
                    (activeSlideChange)="onCoruselActive($event)"
                    [interval]="0"
                    class="test slide W100"
                    [type]="'carousel'"
                    [animation]="'slide'">
        <mdb-carousel-item *ngFor="let data of listAccount">
          <div class="W100">
            <div style="width: 15% !important; float: left; height: 100%; color: black !important"></div>
            <div style="width: 70% !important; float: left;">
              <app-card-account
                [moneda]="data.currency.includes('B') ? 'Bs.' : 'USD' "
                [monto]="data.available"
                [accountNumber]="data.numAccount"
                [type]="data.typeAccount"
                [conjunta]="data.state"
                [pignorado]="data.amountPledged"
                [valorCuota]="data.valorCuota"
                [nroCuota]="data.nroCuotas"
                [disponible]= "data.amount"
              ></app-card-account>
            </div>
            <div style="width: 15% !important; float: left; height: 100%;"></div>
          </div>
        </mdb-carousel-item>
      </mdb-carousel>
    </div>
  </div>
</div>
<div class="row back_white" style="border-radius: 30px 30px 0 0">
  <div class="container-fluid MB20" *ngIf="listAccount.length>0">
    <form #filter="ngForm">
      <div class="MT10">
        <div class="row MT10">
          <div class="col-6 col-md-4 display-dates">
            <div class="md-form">
              <mdb-date-picker #dateIniInput="ngModel"
                               [(ngModel)]="dateIni"
                               name="dateIni"
                               [locale]="locale"
                               class="form-control cls_select cls_select_white cls_date_picker"
                               [inline]="false"
                               label="{{'cons_date_ini' | translate}} *"
                               [options]="myDatePickerOptions"
                               required>
              </mdb-date-picker>
              <div *ngIf="!dateIniInput.valid && (dateIniInput.dirty || dateIniInput.touched)">
                <mdb-error *ngIf="dateIniInput.hasError('required')">{{cmpReq}}</mdb-error>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-4 display-dates">
            <div class="md-form" >
              <mdb-date-picker #dateEndInput="ngModel"
                               [(ngModel)]="dateEnd"
                               name="endStamp"
                               [locale]="locale"
                               class="form-control cls_select cls_select_white cls_date_picker"
                               [inline]="false"
                               label="{{'cons_date_fin' | translate}} *"
                               [options]="myDatePickerOptions"
                               required>
              </mdb-date-picker>
              <div *ngIf="!dateEndInput.valid && (dateEndInput.dirty || dateEndInput.touched)">
                <mdb-error *ngIf="dateEndInput.hasError('required')">{{cmpReq}}</mdb-error>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-4 display-buttoms" style="padding-top: 24px;text-align: center;">
            <button type="submit"
                    class="gradient-custom-3 button-class"
                    (click)="listAccounts()"
                    style="margin-right: 10px !important"
                    [disabled]="!filter.valid ">
              <i class="fas fa-filter pr-2 fa-lg" aria-hidden="true"></i>{{'cons_filter' | translate}}
            </button>
            <button type="submit"
                    class="gradient-custom-3 button-class"
                    (click)="onExcelReport(false)"
                    [disabled]="!filter.valid || listOrder.length==0">
              <i class="fas fa-file-pdf pr-2 fa-lg" aria-hidden="true"></i>{{'cons_export_pdf' | translate}}
            </button>
          </div>
<!--          <div class="col-12 col-md-4" *ngIf="checkTypeFilter">-->
<!--            <div class="md-form">-->
<!--              <mdb-select #type="ngModel"-->
<!--                          [(ngModel)]="filterType"-->
<!--                          name="filterType"-->
<!--                          (ngModelChange)="changeType()"-->
<!--                          id="idtype"-->
<!--                          mdbValidate-->
<!--                          class="form-control cls_select cls_select_white"-->
<!--                          [options]="listTypeTx"-->
<!--                          placeholder="{{'cons_type_select' | translate}}">-->
<!--              </mdb-select>-->
<!--              <label for="idtype">{{'cons_type' | translate}}</label>-->
<!--            </div>-->
<!--          </div>-->
          <div class="col-12 cls_center display-buttoms-2" style="text-align: center;">
            <button type="submit"
                    class="gradient-custom-3 button-class"
                    (click)="listAccounts()"
                    style="margin-right: 10px !important"
                    [disabled]="!filter.valid ">
              <i class="fas fa-filter pr-2 fa-lg" aria-hidden="true"></i>{{'cons_filter' | translate}}
            </button>
            <button type="submit"
                    class="gradient-custom-3 button-class"
                    (click)="onExcelReport(false)"

                    [disabled]="listOrder.length==0 ">
              <i class="fas fa-file-pdf pr-2 fa-lg" aria-hidden="true"></i>{{'cons_export_pdf' | translate}}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="row back_white" *ngIf="listOrder.length==0" style="text-align: center">
  <img src="assets/util/images/Icono_datos_no_encontrados.png" style="display: block; margin-left: auto; margin-right: auto;">
</div>

<div class="row back_white" style="border-radius: 0 0 30px 30px">
  <div class="W100 border_boton_input border_top_input"
       *ngFor="let data of listOrder">
    <app-card-order
      (selectOrder)="selectedOrderView($event, detailModal)"
      [currency]="currency.includes('B') ? 'Bs.' : 'USD'"
      [order]="data">
    </app-card-order>
  </div>

    <div *ngIf="isData==true" style="height: 100px; display: flex; justify-content: center !important;
      align-items: center !important; width: 100%">
  <div style="height: 100px; display: flex; justify-content: center !important;
    align-items: center !important; width: 100%">
    <app-paginador [items]='cantidadTransacciones' [pageSize]='perPage' [initialPage]='1' [maxPages]='pagesToShow'
                   (changePage)='changePage($event)'       ></app-paginador>
  </div>
</div>
  </div>

<div mdbModal #detailModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body" *ngIf="selectOrderView">
        <div>
          <app-card-comprobante
            [nombreOri]="selectOrderView.type===VCP ? nameOriginante:selectOrderView.nameDes"
            [cuentaOri]="selectOrderView.type===VCP ?orderFilter.numAccount:selectOrderView.accountDes"
            [bancoOri]="selectOrderView.eifOrigin"
            [nombreDes]="selectOrderView.type===VCP ? selectOrderView.nameDes:nameOriginante"
            [cuentaDes]="selectOrderView.type===VCP ? selectOrderView.accountDes:orderFilter.numAccount"
            [bancoDes]="selectOrderView.eifDes"
            [importe]="selectOrderView.amount"
            [moneda]="selectOrderView.currency"
            [glosa]="selectOrderView.gloss"
            [fechaTransaccion]="selectOrderView.dateTransaction"
            [txNumberCore]="selectOrderView.numTransaction"
            [numeroTransaccionACH]="selectOrderView.numTransactionACH"
            [complete]="true"
            [estado]="selectOrderView.status == null? 'null' : selectOrderView.status"
            [quotaValue]="selectOrderView.quotaValue"
            [quotaAmount]="selectOrderView.quotaAmount"
            [sign]="selectOrderView.sign">
          </app-card-comprobante>
        </div>
        <div class="cls_center">
          <button mdbBtn
                  class="btn-secundario"
                  (click)="detailModal.hide()"
                  mdbWavesEffect
                  type="button">{{'close' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>




<div class="card_order" (click)="select()">
  <div class="card_data">
    <svg-icon class="cls_icon {{order.sign=='+'? 'fill_secundario':'fill_primario'}}"
              src="assets/util/images/icons/{{order.sign}}.svg"
              [svgStyle]="{ 'height.px':25}">
    </svg-icon>
    <div class="card_order_header">
      <b>{{order.type}}</b>
    </div>
    <div class="card_order_header">
      {{order.nameDes}} - {{order.gloss}}
    </div>

   <!-- <div class="card_order_body {{order.sign=='+'? 'color_terciario':'color_secundario'}}">
      <span>{{order.sign}} {{order.amount}} {{currency}}</span><br/>
    </div> -->
    <div class="card_order_body color_primario">
      <span>{{order.sign}} {{order.amount}} {{currency}}</span><br/>
    </div>
  </div>
  <div class="card_order_accion">
    <div class="card_order_btn">
<!--      <span class="{{order.status.includes(RECHAZADA)|| order.status.includes(ANULADA)? 'color_red':'color_primario'}}">-->
<!--        <b>{{order.status}}</b>-->
<!--      </span><br/>-->
       <span
        class="{{order.sign=='+'? 'color_terciario':'color_terciario'}}">{{order.dateTransaction | date: fecha_hora_sdf}}</span>&nbsp;

    </div>
  </div>
</div>

<hr class="{{order.sign=='+'? 'linea_posit':'linea_negat'}}"/>

import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-card-resume',
  templateUrl: './card-resume.component.html',
  styleUrls: ['./card-resume.component.scss']
})
export class CardResumeComponent implements OnInit {

  @Input() codMoneda: string;
  @Input() moneda: string;
  @Input() monto: string;
  @Input() cantidad: number;


  constructor() {
  }

  ngOnInit() {
  }
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss']
})
export class CaptchaComponent implements OnInit, AfterViewInit {

  @Input() ancho: number;
  @Input() alto: number;
  @Input() cad: string;
  @Input() color: string;

  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('canvas2') canvas2: ElementRef;
  cx: CanvasRenderingContext2D;
  cd: string;

  constructor() {

  }

  ngAfterViewInit(): void {
    this.createCapcha();
  }

  clearCanvas() {
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.cx = canvasEl.getContext('2d');
    canvasEl.width = this.ancho;
    canvasEl.height = this.alto;
    canvasEl.className = 'capcode';
    canvasEl.id = 'CapCode';
  }

  getStyles() {
    const styles = {
      'width': this.ancho + 'px',
      'height': this.alto + 'px',
    };
    return styles;
  }

  getColor() {
    const styles = {
      'background': this.color,
    };
    return styles;
  }

  ngOnInit() {

  }

  createCapcha() {
    const alpha = new Array('A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q',
      'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n',
      'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9');
    let i;
    let a;
    const limit = this.getLimit(4 , 5);
    this.cd = '';
    for (i = 0; i < limit; i++) {
      a = alpha[Math.floor(Math.random() * alpha.length)];
      this.cd = this.cd + ' ' + a;
    }
    const cd2 = this.cd;

    this.clearCanvas();
    const x = this.ancho / 2;
    const ctx = this.cx;
    const patron = ctx.createPattern(this.getPather(), 'repeat');
    ctx.fillStyle = patron;
    ctx.fillRect(0, 0, this.ancho, this.alto);

    ctx.font = '35px Roboto Slab';
    ctx.fillStyle = '#fff';
    ctx.textAlign = 'center';
    ctx.setTransform(1, -0.12, 0, 1, 0, 15);
    ctx.fillText(cd2, x, 55);
  }

  getPather() {
    const canvasP: HTMLCanvasElement = this.canvas2.nativeElement;
    const ctxP = canvasP.getContext('2d');
    canvasP.width = this.ancho / 5;
    canvasP.height = this.alto / 2;

    ctxP.lineWidth = 1;
    ctxP.strokeStyle = '#ddd';
    ctxP.beginPath();
    ctxP.moveTo(this.getLimit(1, canvasP.width), this.getLimit(1, canvasP.width));
    ctxP.lineTo(this.getLimit(1, canvasP.height), this.getLimit(1, canvasP.height));
    ctxP.moveTo(this.getLimit(1, canvasP.width), this.getLimit(1, canvasP.width));
    ctxP.lineTo(this.getLimit(1, canvasP.height), this.getLimit(1, canvasP.height));
    ctxP.moveTo(this.getLimit(1, canvasP.width), this.getLimit(1, canvasP.width));
    ctxP.lineTo(this.getLimit(1, canvasP.height), this.getLimit(1, canvasP.height));
    ctxP.moveTo(this.getLimit(1, canvasP.width), this.getLimit(1, canvasP.width));
    ctxP.lineTo(this.getLimit(1, canvasP.height), this.getLimit(1, canvasP.height));
    ctxP.stroke();
    return canvasP;
  }

  public getValidar(): boolean {
    const val = this.cd.toString().split(' ').join('');
    return val === this.cad ? true : false;
  }

  getLimit(ini: number, fin: number) {
    return Math.floor(Math.random() * (Math.floor(fin) - Math.ceil(ini) + 1)) + Math.ceil(ini);
  }
}

import {CreditDetailModel} from '@models/credit/credit.detail.model';

export class CreditModel {
  canal: number;
  codigoCliente: string;
  numeroCredito: string;
  tipo: string;
  moneda: string;
  oficina: string;
  montoDesembolsado: string;
  montoPagado: string;
  saldoCredito: string;
  estado: string;
  fechaLimitePago: string;
  details: CreditDetailModel[];


  clone(c: CreditModel): CreditModel {
    const model: CreditModel = new CreditModel();
    model.canal = c.canal;
    model.codigoCliente = c.codigoCliente;
    model.numeroCredito = c.numeroCredito;
    model.tipo = c.tipo;
    model.moneda = c.moneda;
    model.oficina = c.oficina;
    model.montoDesembolsado = c.montoDesembolsado;
    model.montoPagado = c.montoPagado;
    model.saldoCredito = c.saldoCredito;
    model.estado = c.estado;
    model.fechaLimitePago = c.fechaLimitePago;
    model.details = c.details;
    return model;
  }
}

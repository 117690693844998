import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-paginador',
  templateUrl: './paginador.component.html',
  styleUrls: ['./paginador.component.scss']
})
export class PaginadorComponent implements OnInit {

  @Input() items ;
  @Output() changePage = new EventEmitter<any>(true) ;
  @Input() initialPage ;
  @Input() pageSize ;
  @Input() maxPages ;

  pager: any = {};

  ngOnInit() {
    // set page if items array isn't empty
    if (this.items > 0) {
      this.setPage(this.initialPage);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items.currentValue !== changes.items.previousValue && this.items > 0) {
      this.setPage(this.initialPage);
    }
  }

  public setPage(page: number) {

    this.pager = this.paginate(this.items, page, this.pageSize, this.maxPages);
    this.changePage.emit(this.pager);
  }

  paginate(totalItems: number, currentPage?: number, pageSize?: number, maxPages?: number){
    let total = totalItems / pageSize;
    let totales = Number.isInteger(total)? total: Math.trunc(total)+1;
    let pages : number[] = [];
    for (let i = 1; i <= totales; i++) {
      pages.push(i);
    }
    return {
      totalItems: totalItems,
      currentPage: currentPage!=null?currentPage:this.initialPage,
      pageSize: pageSize,
      totalPages: totales,
      maxPages:maxPages,
      pages : pages
    }
  }
  getVisiblePages(): number[] {
    const currentPage = this.pager.currentPage;
    const totalPages = this.pager.totalPages;
    const visiblePagesCount = 5;

    let startPage = Math.max(currentPage - 2, 1);
    let endPage = Math.min(currentPage + 2, totalPages);

    if (endPage - startPage + 1 < visiblePagesCount) {
      if (startPage === 1) {
        endPage = Math.min(visiblePagesCount, totalPages);
      } else {
        startPage = Math.max(endPage - visiblePagesCount + 1, 1);
      }
    }

    return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
  }


}

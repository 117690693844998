

export class DeviceHeader {
  ip: string;
  os: string;
  browserName: string;
  infoDevice: string;
  deviceVersion: string;

}

import {PaymentCreditModel} from '@models/credit/payment.credit.model';
import {RequestHeaderModel} from '@models/requestHeader.model';

export class CargosModel {
  descripcion: string;
  signo: string;
  monto: string;

  constructor(descripcion: string, signo: string, monto: string) {
    this.descripcion = descripcion;
    this.signo = signo;
    this.monto = monto;
  }
}

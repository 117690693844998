<div class="cls_diclosure" #screen>
  <div class="cls_center cls_header">
    <div *ngIf="complete">
      <svg-icon *ngIf="estado.includes(RECHAZADA) || estado.includes(ANULADA)" class="FL fill_red" src="assets/util/images/icons/cancel.svg"
                [svgStyle]="{ 'height.px':45}">
      </svg-icon>
      <svg-icon *ngIf="estado.includes(CONFIRMADA)" class="FL" src="assets/util/images/icons/check.svg"
                [svgStyle]="{ 'height.px':45}">
      </svg-icon>
      <svg-icon (click)="onCapture()"
                class="FR cls_hover" src="assets/util/images/icons/share.svg"
                [svgStyle]="{ 'height.px':45}">
      </svg-icon>
    </div>
    <div class="header_des">
      <img src="assets/util/images/tesabiz.png" style="width: 200px;">
    </div>
  </div>
  <br>
  <div class="cls_center">

  </div>


  <div class="W100 FZ8" *ngIf="complete">
    <hr class="back_primario cl_hr">
    <div style="display: table; width: 100%; clear: both;">
      <div style="display: block; width: 50%; float: left;">
        <span>{{'com_crt_txNumberCore' | translate}}:</span><br/>
        <span><b>{{txNumberCore}}</b></span><br/>
      </div>
      <div style="display: block; width: 50%; float: left; text-align: right;">
        <span>{{'com_crt_date_tx' | translate}}:</span><br/>
        <span><b>{{fechaTransaccion | date: 'dd/MM/yyyy HH:mm:ss'}}</b></span>
      </div>
    </div>
  </div>
  <hr class="back_primario cl_hr">
  <div class="W100 FZ8">
    <div style="display: table; width: 100%; clear: both;">
      <div style="display: block; width: 50%; float: left;">
        <span>{{'com_crt_nameOri' | translate}}:</span><br/>
        <span><b>{{nombreOri}}</b></span>
      </div>
      <div style="display: block; width: 50%; float: left; text-align: right;">
        <span>{{'com_crt_accountOri' | translate}}:</span><br/>
        <span><b>{{cuentaOri}}</b></span>
      </div>
    </div>
    <div style="display: table; width: 100%; clear: both;">
      <span>{{'com_crt_efiOri' | translate}}:</span><br/>
      <span><b>{{bancoOri}}</b></span>
    </div>
  </div>



  <hr class="back_primario cl_hr">
  <div class="W100 FZ8">
    <div style="display: table; width: 100%; clear: both;">
      <div style="display: block; width: 50%; float: left;">
        <span>{{'com_crt_numberCredit' | translate}}:</span><br/>
        <span><b>{{numeroCredito}}</b></span>
      </div>
    </div>
  </div>

  <hr class="back_primario cl_hr">
  <div class="W100 FZ8">
    <div style="display: table; width: 100%; clear: both;">
      <div style="display: block; width: 50%; float: left;">
        <span>{{'com_crt_amount' | translate}}:</span><br/>
        <span><b>{{importe}} {{moneda}}</b></span><br/>
      </div>
      <div style="display: block; width: 50%; float: left; text-align: right;">
        <span *ngIf="estado">{{'com_crt_status' | translate}}:<br/></span>
        <span *ngIf="estado" class="{{estado.includes('Rechazada')|| estado.includes('Anulada')? 'color_red':'color_primario'}}">
          <b>{{estado}}</b><br/>
        </span>
      </div>
    </div>
  </div>
  <hr class="back_primario cl_hr">
</div>

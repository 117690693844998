import {CreditModel} from '@models/credit/credit.model';

export class PaymentDto2 {
  credit: CreditModel;
  canal: number;
  codigoCliente: string;
  numeroCC: string;
  numeroCredito: string;
  fecha: string;
  moneda: string;
  signo1: string;
  montoPago: string;
}

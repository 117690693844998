import { Off3 } from '@app/models/office/Off3';
import {Injectable} from '@angular/core';
import {Config} from './config';
import {HttpClient} from '@angular/common/http';
import {Off1} from '@app/models/office/off1';
import {Off2} from '@app/models/office/off2';

@Injectable({
  providedIn: 'root'
})
export class OfficesService {

  url = `${Config.settings.path.url_root}/ws-office/of/office/list`;

  constructor(private http: HttpClient) {
  }

  list(off1: Off1) {
    return this.http.post<Off2>(this.url, off1);
  }
  listbyparam(model: Off3) {
    return this.http.post<Off2>(`${Config.settings.path.url_root}/tesabiz-ws-security/tbz/office/listbyparam`, model);
  }

}

import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {D1} from '../models/domain/D1.model';
import {Config} from '@services/config';


@Injectable({
  providedIn: 'root'
})
export class DomainService {

  constructor(
      public http: HttpClient) {
  }
  listDomain( domain: D1) {
    const url: string = Config.settings.path.url_root + '/tesabiz-ws-security/tbz/domain/list';
    return this.http.post( url, domain);
  }
}

import {RequestHeaderModel} from '../requestHeader.model';

export class D1 {
    header: RequestHeaderModel;
    domain: string;
    constructor(header: RequestHeaderModel, domain: string) {
        this.header = header;
        this.domain = domain;
    }
}

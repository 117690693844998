import {CreditModel} from '@models/credit/credit.model';

export class PaymentCreditModel {
  numeroCC: string;
  moneda: string;
  montoPago: string;
  fecha: Date;
  credit: CreditModel;
  nroTransaccion?: string;

  clone(c: PaymentCreditModel): PaymentCreditModel {
    const model: PaymentCreditModel = new PaymentCreditModel();
    model.numeroCC = c.numeroCC;
    model.moneda = c.moneda;
    model.montoPago = c.montoPago;
    model.credit = c.credit;
    return model;
  }
}

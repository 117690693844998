import {BeneficiaryModel} from '@models/beneficiary';

export class TxDto {
  accountNumberOri: string;
  accountCode: string;
  currency: string;
  amount: number;
  reason: string;
  originFound: string;
  destFound: string;
  codService: string;
  txType: string;
  accountType: string;
  idTxMovil: string;
  via: string;
  status: string;
  date: Date;
  representativeCode: string;
  beneficiary: BeneficiaryModel;

  // ** OTROS **/
  cuentaPropia: boolean;
  txNumberCore: string;
  dateTransaction: Date;

  clone(c: TxDto): TxDto {
    const model: TxDto = new TxDto();
    model.accountNumberOri = c.accountNumberOri;
    model.accountCode = c.accountCode;
    model.currency = c.currency;
    model.amount = c.amount;
    model.reason = c.reason;
    model.codService = c.codService;
    model.currency = c.currency;
    model.txType = c.txType;
    model.accountType = c.accountType;
    model.idTxMovil = c.idTxMovil;
    model.via = c.via;
    model.status = c.status;
    model.date = c.date;
    model.originFound = c.originFound;
    model.destFound = c.destFound;
    model.beneficiary = c.beneficiary;
    model.representativeCode = c.representativeCode;
    return model;
  }

}

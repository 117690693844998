<div class="col-md-12">
  <div class="md-form">
    <label class="active">{{nombre}} {{requerido ? '(*)' : ''}}</label>
    <div class="switch" style="padding-bottom: 30px;">
      <label>
        No
        <input #imcheck="ngModel" type="checkbox" [(ngModel)]="content" name="input"
               [mdbTooltip]="descripcion"
               checked>
        <span class="lever"></span> Si
      </label>
    </div>
    <div *ngIf="!imcheck.valid && (imcheck.dirty || imcheck.touched)">
      <mdb-error *ngIf="imcheck.hasError('required')">{{cmpReq}}</mdb-error>
    </div>
  </div>
</div>




export class DetailleDpfDto {
  signo1: string;
  importe: string;
  signo2: string;
  tasa: string;
  plazo: string;
  signo3: string;
  interes: string;
  fechaApertura: string;
  fechaVencimiento: string;
  fechaUltrenovacion: string;
  formaPagointeres: string;
}

<div class="centrado">
  <div class="cls_diclosure card_layout" #screen>
    <div class="cls_center">
      <h4 class="color_primario"><b>{{'com_dpf_title' | translate}}</b></h4>
    </div>

    <hr class="back_primario cl_hr">

    <br>
    <div class="W100 FZ8">
      <div class="table_layout">
        <div class="table_layout_right">
          <span>{{'com_dpf_fecha_apertura' | translate}}:</span><br/>
        </div>
        <div class="table_layout_left">
          <span><b>{{detailDpf.fechaApertura}}</b></span>
        </div>
      </div>
    </div>

    <div class="W100 FZ8">
      <div class="table_layout">
        <div class="table_layout_right">
          <span>{{'com_dpf_taza_interes' | translate}}:</span><br/>
        </div>
        <div class="table_layout_left">
          <span><b>{{detailDpf.tasa }}</b></span>
        </div>
      </div>
    </div>

    <div class="W100 FZ8">
      <div class="table_layout">
        <div class="table_layout_right">
          <span>{{'com_dpf_fecha_vencimiento' | translate}}:</span><br/>
        </div>
        <div class="table_layout_left">
          <span><b>{{detailDpf.fechaVencimiento}} </b></span>
        </div>
      </div>
    </div>

    <div class="W100 FZ8">
      <div class="table_layout">
        <div class="table_layout_right">
          <span>{{'com_dpf_plazo' | translate}}:</span><br/>
        </div>
        <div class="table_layout_left">
          <span><b>{{detailDpf.plazo}} </b></span>
        </div>
      </div>
    </div>

    <div class="W100 FZ8">
      <div class="table_layout">
        <div class="table_layout_right">
          <span>{{'com_dpf_amount' | translate}}:</span><br/>
        </div>
        <div class="table_layout_left">
          <span><b>{{detailDpf.importe}} {{moneda}}</b></span>
        </div>
      </div>
    </div>


  </div>
</div>

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BeneficiaryModel1, BeneficiaryModel2, BeneficiaryModel3, BeneficiaryModel4} from '../models/beneficiary';
import {Config} from '@services/config';

@Injectable({
  providedIn: 'root'
})

export class BeneficiaryService {

  constructor(private http: HttpClient) {
  }

  list(model: BeneficiaryModel3) {
    return this.http.post<BeneficiaryModel4>(`${Config.settings.path.url_root}/tesabiz-ws-security/tbz/beneficiary/list`, model).pipe();
  }

  find(model: BeneficiaryModel1) {
    return this.http.post<BeneficiaryModel2>(`${Config.settings.path.url_root}/tesabiz-ws-security/tbz/beneficiary/fin`, model).pipe();
  }

  persist(model: BeneficiaryModel1) {
    return this.http.post<BeneficiaryModel2>(`${Config.settings.path.url_root}/tesabiz-ws-security/tbz/beneficiary/add`, model).pipe();
  }

  merge(model: BeneficiaryModel1) {
    return this.http.post<BeneficiaryModel2>(`${Config.settings.path.url_root}/tesabiz-ws-security/tbz/beneficiary/mergue`, model).pipe();
  }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from '@services/config';
import {Dpf1, Dpf2, Dpf3, Dpf4} from '@models/dpf';

@Injectable({
  providedIn: 'root'
})

export class DpfService {

  constructor(private http: HttpClient) {
  }

  list(model: Dpf1) {
    return this.http.post<Dpf2>(`${Config.settings.path.url_root}/tesabiz-ws-security/tbz/dpf/list`, model).pipe();
  }
  detail(model: Dpf3) {
    return this.http.post<Dpf4>(`${Config.settings.path.url_root}/tesabiz-ws-security/tbz/dpf/detail`, model).pipe();
  }
}

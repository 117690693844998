import {RequestHeaderModel} from '@models/requestHeader.model';

export class Hts1 {
  header: RequestHeaderModel;
  numAccount: string;
  accountCode: string;
  offset: number;
  page: number;
  typeAccount: string;
  currency: string;
  dateInit: string;
  dateEnd: string;
  representativeCode: string;


  constructor(header?: RequestHeaderModel,  numAccount?: string, accountCode?: string, offset?: number, page?: number, dateInit?: string, dateEnd?:string, representativeCode?:string, typeAccount?:string, currency?:string) {
    this.header = header;
    this.numAccount = numAccount;
    this.accountCode = accountCode;
    this.offset = offset;
    this.page = page;
    this.typeAccount = typeAccount;
    this.currency = currency;
    this.dateInit = dateInit;
    this.dateEnd = dateEnd;
    this.representativeCode = representativeCode;
  }
}


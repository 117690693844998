import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Cre3Model, CreditModel, Crt11Model, Crt12Model, PaymentBreadModel} from "@models/credit";
import {SERVICES} from "@config/config";
import {BaseModel} from "@models/util/base.model";
import {AuthService} from "@services/auth.service";
import {DomainService} from "@services/domain.service";
import {TranslateService} from "@ngx-translate/core";
import {HistoricalTrazeService} from "@services/historical.traze.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {MessageService} from "@app/error/message.service";
import {BeneService} from "@pages/beneficiary/bene.service";
import {AccountService} from "@services/account.service";
import {CreditService} from "@services/credit.service";
import {Router} from "@angular/router";
import {HistoryAccountDto} from "@models/history";

@Component({
  selector: 'app-payment-plan',
  templateUrl: './payment-plan.component.html',
  styleUrls: ['./payment-plan.component.scss']
})
export class PaymentPlanComponent extends BaseModel implements OnInit  {
  list: CreditModel[] = [];
  listDetail: PaymentBreadModel[] = [];
  currency : string;
  constructor(
    protected authService: AuthService,
    protected domainService: DomainService,
    private translate: TranslateService,
    protected historical: HistoricalTrazeService,
    protected deviceService: DeviceDetectorService,
    private messageService: MessageService,
    private beneService: BeneService,
    public accountService: AccountService,
    public creditService: CreditService,
    private router: Router
  ) {
    super(authService, historical, deviceService);
    super.registerEvent('Plan de pagos', this.router.url);

  }

  ngOnInit(): void {
    this.onListCredits();
  }
  onListCredits() {
    const user = this.authService.getCurrentUser();
    const creditDto = new CreditModel();
    creditDto.canal = Number(SERVICES.SER_CANAL);
    creditDto.codigoCliente = user.client.accountCode;
    const creditModel3 = new Cre3Model(this.getHeader(this.authService.getUser()), creditDto, user.client.representativeCode);
    this.creditService.list(creditModel3)
      .subscribe((resp: any) => {
        this.list = resp.list;
        if(this.list.length>0){
          this.onLoadDetail(this.list[0]);
          this.currency = this.list[0].moneda.includes('B')?'Bs':'USD';
        }
      });
  }


  onCoruselActive(event: any) {
    this.onLoadDetail(this.list[event.relatedTarget]);
  }
  onLoadDetail(credit: CreditModel) {
    const user = this.authService.getCurrentUser();
    const creditDto = new CreditModel();
    creditDto.canal = Number(SERVICES.SER_CANAL);
    creditDto.codigoCliente = user.client.accountCode;
    this.listDetail = [];
    const creditModel11 = new Crt11Model(this.getHeader(this.authService.getUser()), Number(SERVICES.SER_CANAL), user.client.accountCode, credit.numeroCredito);
    this.creditService.paymentBread(creditModel11)
      .subscribe((resp: Crt12Model) => {
         console.log(resp);
         this.listDetail = resp.detail;
         this.currency = credit.moneda.includes('B')?'Bs':'USD';
      });
  }
}

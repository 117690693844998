import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BeneService {

  private menuSubject = new Subject<boolean>();
  beneficiaryState = this.menuSubject.asObservable();

  constructor() {
  }

  onBack() {
    this.menuSubject.next(true);
  }
}


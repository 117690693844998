import {Component, Input, OnInit} from '@angular/core';
import {CreditModel} from "@models/credit";
import {FORMS} from "@config/config";
import {DpfDto} from "@models/dpf";

@Component({
  selector: 'app-card-dpf',
  templateUrl: './card-dpf.component.html',
  styleUrls: ['./card-dpf.component.scss']
})
export class CardDpfComponent implements OnInit {
  @Input() dpfDto: DpfDto;
  @Input() selected: boolean;

  cmpReq = FORMS.FRM_REQUIRED;
  cmpMin = FORMS.FRM_MINIMO;
  submitted = false;
  constructor() { }

  ngOnInit(): void {
  }
  onSelected(): any {
    this.selected = true;
  }
}

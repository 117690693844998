import {RequestHeaderModel} from '../requestHeader.model';
import {BeneficiaryModel} from './beneficiary.model';

export class BeneficiaryModel1 {
  header: RequestHeaderModel;
  beneficiary: BeneficiaryModel;
  tokenTx: string;

  constructor(header: RequestHeaderModel, beneficiary: BeneficiaryModel, tokenTx?: string) {
    this.header = header;
    this.beneficiary = beneficiary;
    this.tokenTx = tokenTx != null ? tokenTx : null;
  }
}

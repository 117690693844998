import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {NotifyModel} from '@app/components/notity/notify.model';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  private notifySubject = new Subject<NotifyModel>();

  notifyModel = this.notifySubject.asObservable();

  constructor() {
  }

  show(message: string) {
    this.notifySubject.next(<NotifyModel> { show: true, text: message} );
  }
}


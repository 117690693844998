<div *ngIf="!edit && !new">
  <div class="text-center" *ngIf="!hideTitle">
    <h4 class="cls_title_2" [innerHTML]="'ben_title' | translate"></h4>
  </div>



  <form>
    <div class="md-form cls_center form_cls_filter" style="padding-right: 30px; padding-left: 30px">
      <input #filterBeneficiary="ngModel"
             [(ngModel)]="filterBene"
             name="filterBene"
             id="idFilterBene"
             type="text"
             (keyup)="onChangeFilterBene(filterBeneficiary.value)"
             mdbInput
             class="form-control cls_input cls_input_white cls_filter"
             maxlength="50"
             autofocus
             autocomplete="off">
      <label class="cls_act_especial" for="idFilterBene" style="margin-left: 30px">
        <mdb-icon fas icon="search"></mdb-icon>
        {{'search' | translate}}</label>
    </div>
  </form>

  <div class="container-fluid cls_center">
    <button mdbBtn
            class="gradient-custom-2 PL10"
            (click)="newBeneficiary()"
            mdbWavesEffect
            type="button">
      <mdb-icon class="PR5" fas icon="plus"></mdb-icon>
      {{'ben_add' | translate}}</button>
  </div>

  <div *ngFor="let bank of list">

    <div class="row" *ngIf="!sinagrupar && bank.eifDes">
      <div class="cls_bene_title">
        <h1><b>{{bank.eifDes}} ({{bank.count}})</b></h1>
      </div>
    </div>

    <div class="row">
      <div
        style="padding-left: 25px !important;padding-right: 25px !important;"
        class="{{selectEntity.beneficiaryId==data.beneficiaryId? 'select_beneficiary':''}} col-12 col-md-6 MT20"
        *ngFor="let data of bank.list">
        <app-card-beneficiary
          [idBeneficiary]="data.beneficiaryId"
          [fullName]="data.accountName"
          [nameBank]="data.bankName"
          [numberAccount]="data.accountNumber"
          [identityType]="data.typeAccount"
          [aliasName]= "data.alias"
          (editBeneficiary)="onEditBene(data)"
          (removeBeneficiary)="onConfirmDelete(data, confirmDelete)"
          (selectBeneficiary)="onSelectBene($event, data)"
        ></app-card-beneficiary>
      </div>
    </div>
  </div>
</div>
<br/><br/>

<div *ngIf="edit || new">
  <div class="btn_back btn_back_top FL " (click)="onBack()" *ngIf="!hideTitle">
    <mdb-icon fas icon="chevron-left" class="bb_icon"></mdb-icon>
    <span>{{'login_back' | translate}}</span>
  </div>
  <div class="text-center" *ngIf="edit">
    <h4 class="cls_title_2" [innerHTML]="'ben_edit_title' | translate"></h4>
  </div>
  <div class="text-center" *ngIf="new">
    <h4 class="cls_title_2" [innerHTML]="'ben_add_title' | translate"></h4>
  </div>

  <div class="container">
    <div class="col-12 col-md-8 mx-auto text-center">
      <h6 class="cls_title_3" [innerHTML]="'ben_nota_inf' | translate"></h6>
    </div>
    <section>
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-xl-6">
          <div class="row g-0">
            <div class="card-body p-md-5 mx-md-4">
              <form #frm="ngForm" >
                <div class="md-form">
                  <input #alias="ngModel"
                         mdbInput
                         autofocus
                         type="text"
                         id="idalias"
                         class="form-control cls_input cls_input_white"
                         name="alias"
                         [(ngModel)]="entity.alias"
                         required
                         minlength="3"
                         maxlength="20"
                         validateAlphaNumeric
                         autocomplete="off"
                         [disabled]="edit">
                  <label class="cls_act_especial" for="idalias">{{'ben_f_alias' | translate}} *</label>
                  <div *ngIf="!alias.valid && (alias.dirty || alias.touched)">
                    <mdb-error *ngIf="alias.hasError('required')">{{cmpReq}}</mdb-error>
                    <mdb-error *ngIf="alias.hasError('minlength')">{{cmpMin}} 3</mdb-error>
                  </div>
                </div>

                <div class="md-form mb-4">
                  <input #accountNumber="ngModel"
                         mdbInput
                         type="text"
                         id="idaccountNumber"
                         class="form-control cls_input cls_input_white"
                         (keypress)="keyPress($event)"
                         name="accountNumber"
                         [(ngModel)]="entity.accountNumber"
                         required
                         minlength="5"
                         maxlength="20"
                         validateNumeric
                         autocomplete="off"
                         [disabled]="edit">
                  <label for="idaccountNumber">{{'ben_f_cuenta' | translate}} *</label>
                  <div *ngIf="!accountNumber.valid && (accountNumber.dirty || accountNumber.touched)">
                    <mdb-error *ngIf="accountNumber.hasError('required')">{{cmpReq}}</mdb-error>
                    <mdb-error *ngIf="accountNumber.hasError('minlength')">{{cmpMin}} 5</mdb-error>
                  </div>
                </div>

                <div class="md-form mb-4">
                  <label class="label" for="ideif">{{ 'ben_f_banco' | translate }} *</label><br>
                  <mdb-select-2 class="form-control cls_select cls_select_white"
                                #eiff="ngModel"
                                [(ngModel)]="entity.eif"
                                name="eiff"
                                (ngModelChange)="onChangeBank(entity.eif)"
                                id="ideif"
                                mdbValidate
                                required
                                [disabled]="!entity.accountNumber"
                                placeholder="{{ 'ben_fs_banco' | translate }}">

                    <mdb-select-filter #filter class="cls_select_white" [formControl]="searchInput"></mdb-select-filter>
                    <mdb-select-option class="cls_select_white" *ngFor="let option of filteredOptions | async" [value]="option.value">{{ option.label }}</mdb-select-option>
                  </mdb-select-2>
                  <div *ngIf="!eiff.valid && (eiff.dirty || eiff.touched)">
                    <mdb-error class="error-message2" *ngIf="eiff.hasError('required')">{{ cmpReq }}</mdb-error>
                  </div>
                </div>

                <div class="md-form mb-4">
                  <input #accountName="ngModel"
                         mdbInput type="text"
                         id="idaccountName"
                         class="form-control cls_input cls_input_white"
                         name="accountName"
                         [(ngModel)]="entity.accountName"
                         required
                         minlength="5"
                         maxlength="50"
                         validateAlphaSpace
                         autocomplete="off"
                         [readOnly]="entity.eif == codeBankUser">
                  <label for="idaccountName">{{'ben_f_fullname' | translate}} *</label>
                  <div *ngIf="!accountName.valid && (accountName.dirty || accountName.touched)">
                    <mdb-error *ngIf="accountName.hasError('required')">{{cmpReq}}</mdb-error>
                    <mdb-error *ngIf="accountName.hasError('minlength')">{{cmpMin}} 5</mdb-error>
                  </div>
                </div>

                <div class="md-form mb-4">
                  <input #ci="ngModel"
                         mdbInput
                         type="text"
                         id="idci"
                         class="form-control cls_input cls_input_white"
                         name="ci"
                         [(ngModel)]="entity.ci"
                         minlength="5"
                         maxlength="20"
                         required
                         validateAlphaNumeric
                         autocomplete="off"
                         [readOnly]="entity.eif == codeBankUser">
                  <label for="idci">{{'ben_f_ci' | translate}} *</label>
                  <div *ngIf="!ci.valid && (ci.dirty || ci.touched)">
                    <mdb-error *ngIf="ci.hasError('required')">{{cmpReq}}</mdb-error>
                    <mdb-error *ngIf="ci.hasError('minlength')">{{cmpMin}} 5</mdb-error>
                  </div>
                </div>

                <div class="md-form mb-4">
                  <mdb-select #tipoCuenta="ngModel"
                              [(ngModel)]="entity.typeAccount"
                              name="bankId"
                              id="idbankId"
                              mdbValidate
                              class="form-control cls_select cls_select_white"
                              required
                              [options]="listTipos"
                              [disabled]="entity.eif == codeBankUser"
                              placeholder="{{'ben_fs_tipo_cuenta' | translate}}">
                  </mdb-select>
                  <label for="idbankId">{{'ben_f_tipo_cuenta' | translate}} *</label>
                  <div *ngIf="!tipoCuenta.valid && (tipoCuenta.dirty || tipoCuenta.touched)">
                    <mdb-error *ngIf="tipoCuenta.hasError('required')">{{cmpReq}}</mdb-error>
                  </div>
                </div>

                <div class="container-fluid cls_center">
                  <div class="md-form mb-4">
                    <div class="cls_radio">
                      <label class="cls_label_radio" style="float: left; margin-top: -10px">{{'ben_f_currency' | translate}} *</label>
                      <div class="clas_r PL20" *ngFor="let currency of listCurrency">
                        <input
                          [(ngModel)]="entity.currency"
                          type="radio"
                          class="form-check-input cls_input_radio"
                          id="materialInline{{currency.value}}"
                          [value]="currency.value"
                          name="currency"
                          required
                          mdbInput>
                        <label class="form-check-label"
                               for="materialInline{{currency.value}}">{{currency.label}}</label>
                      </div>
                    </div>
                  </div>
                </div>

                <br/>
                <div  class="row flex-center">
                    <div  style="display: block">
                      <button mdbBtn class="gradient-custom-2 FR"
                              mdbWavesEffect
                              (click)="onBack()"
                              type="button">{{'ben_cancel' | translate}}</button>
                      <div  class="FR" > &nbsp;&nbsp;  </div>
                      <button mdbBtn class="gradient-custom-2 FR"
                              mdbWavesEffect
                              (click)="onConfirm(confirmSave)"
                              [disabled]="!frm.valid || submit"
                              type="submit">{{'ben_submit' | translate}}</button>
                    </div>

                </div>
                <div class="md-form mb-4">
                  <div class="text-center">

                  </div>
                </div>
                <div class="md-form mb-4">
                  <div class="text-center">

                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
<app-confirm-key #confirmDelete
                 text="{{'ben_delete' | translate}}"
                 (success)="onSubmitDelete($event, confirmDelete)"></app-confirm-key>
<app-confirm-key #confirmSave
                 text="{{'ben_save' | translate}}"
                 (success)="onSubmit($event, confirmSave)"></app-confirm-key>

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from '@services/config';
import {Ht1Model} from '@models/historical/Ht1.model';
import {Ht2Model} from '@models/historical/Ht2.model';

@Injectable({
  providedIn: 'root'
})

export class HistoricalTrazeService {

  constructor(private http: HttpClient) {
  }

  register(model: Ht1Model) {
    return this.http.post<Ht2Model>(`${Config.settings.path.url_root}/tesabiz-ws-security/tbz/historical/register`, model).pipe();
  }
}

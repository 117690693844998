<div class="cls_diclosure" #screen>
  <div class="cls_center cls_header">
    <div *ngIf="complete">
      <svg-icon *ngIf="estado.includes(RECHAZADA) || estado.includes(ANULADA)" class="FL fill_red" src="assets/util/images/icons/cancel.svg"
                [svgStyle]="{ 'height.px':45}">
      </svg-icon>
      <svg-icon *ngIf="estado.includes(CONFIRMADA)" class="FL" src="assets/util/images/icons/check.svg"
                [svgStyle]="{ 'height.px':45}">
      </svg-icon>

    <!-- boton de descargar comprobante -->

    <div *ngIf="type_trans != TYPETRANS">
      <svg-icon (click)="onCapture()"
                class="FR cls_hover" src="assets/util/images/icons/share.svg"
                [svgStyle]="{ 'height.px':45}">
      </svg-icon>
   <!-- --></div>

    </div>
    <div class="header_des">
      <img src="assets/util/images/tesabiz.png" style="width: 200px;">
    </div>
  </div>
  <br>
  <div *ngIf="!complete" class="cls_center">
    <h4 class="color_primario"><b>{{'com_resumen' | translate}}</b></h4>
  </div>
  <div *ngIf="complete" class="cls_center">
    <h4 class="color_primario"><b>{{'com_comprobante' | translate}}</b></h4>
  </div>
  <div class="W100 FZ8" *ngIf="complete">
    <hr *ngIf="numeroTransaccionACH" class="back_primario cl_hr">
    <div style="display: table; width: 100%; clear: both;">
      <div style="display: block; width: 50%; float: left;">
        <span>{{'com_txNumberCore' | translate}}:</span><br/>
        <span><b>{{txNumberCore}}</b></span><br/>
        <span *ngIf="numeroTransaccionACH">{{'com_txNumberAch' | translate}}:<br/></span>
        <span *ngIf="numeroTransaccionACH"><b>{{numeroTransaccionACH}}</b><br/></span>
      </div>
      <div style="display: block; width: 50%; float: left; text-align: right;">
        <span>{{'com_fechaTransaccion' | translate}}:</span><br/>
        <span><b>{{fechaTransaccion | date: 'dd/MM/yyyy HH:mm:ss'}}</b></span>
      </div>
    </div>
  </div>
  <hr *ngIf="bancoOri || nombreOri || cuentaOri " class="back_primario cl_hr">
  <div   class="W100 FZ8">
    <div *ngIf="nombreOri" style="display: table; width: 100%; clear: both;">
      <div style="display: block; width: 50%; float: left;">
        <span>{{'com_nombreOri' | translate}}:</span><br/>
        <span><b>{{nombreOri}}</b></span>
      </div>
      <div *ngIf="cuentaOri" style="display: block; width: 50%; float: left; text-align: right;">
        <span>{{'com_cuentaOri' | translate}}:</span><br/>
        <span><b>{{cuentaOri}}</b></span>
      </div>
    </div>

    <div *ngIf="bancoOri" style="display: table; width: 100%; clear: both;">
      <span>{{'com_bancoOri' | translate}}:</span><br/>
      <span><b>{{bancoOri}}</b></span>
    </div>
  </div>

  <hr *ngIf="bancoDes || nombreDes || cuentaDes " class="back_primario cl_hr">
  <div  class="W100 FZ8">
    <div  style="display: table; width: 100%; clear: both;">
      <div *ngIf="nombreDes && sign === '+' || bancoDes"  style="display: block; width: 50%; float: left;">
        <span>{{'com_nombreDes' | translate}}:</span><br/>
        <span><b>{{nombreDes}}</b></span>
      </div>

      <div *ngIf="nombreDes && sign === '-' && !bancoDes"  style="display: block; width: 50%; float: left;">
        <span>{{'com_nombreOri' | translate}}:</span><br/>
        <span><b>{{nombreDes}}</b></span>
      </div>


      <div *ngIf="cuentaDes && sign === '+' || bancoDes" [style]="nombreDes?'display: block; width: 50%; float: left; text-align: right;':'display: block; width: 100%; float: left; text-align: right;'">
        <span>{{'com_cuentaDes' | translate}}:</span><br/>
        <span><b>{{cuentaDes}}</b></span>
      </div>

      <div *ngIf="cuentaDes && sign === '-' && !bancoDes" [style]="nombreDes?'display: block; width: 50%; float: left; text-align: right;':'display: block; width: 100%; float: left; text-align: right;'">
        <span>{{'com_cuentaOri' | translate}}:</span><br/>
        <span><b>{{cuentaDes}}</b></span>
      </div>


    </div>
    <div *ngIf="bancoDes" style="display: table; width: 100%; clear: both;">
        <span>{{'com_bancoDes' | translate}}:</span><br/>
        <span><b>{{bancoDes}}</b></span>
    </div>
  </div>

  <hr class="back_primario cl_hr">
  <div class="W100 FZ8">
    <div style="display: table; width: 100%; clear: both;">
      <div style="display: block; width: 50%; float: left;">
        <span>{{'com_importe' | translate}}:</span><br/>
        <span><b>{{importe | numberFormat | number : '1.2-2'}}</b> {{moneda}}</span><br/>
        <span *ngIf="quotaAmount">{{'com_quote' | translate}}:<br/></span>
        <span *ngIf="quotaAmount"><b>{{quotaAmount}}</b><br/></span>
        <span>{{'com_glosa' | translate}}:</span><br/>
        <span><b>{{glosa}}</b></span>
      </div>
      <div *ngIf="estado!= 'null'" style="display: block; width: 50%; float: left; text-align: right;">
        <span *ngIf="estado">{{'com_estado' | translate}}:<br/></span>
        <span *ngIf="estado" class="{{estado.includes('Rechazada')|| estado.includes('Anulada')? 'color_red':'color_primario'}}">
          <b>{{estado}}</b><br/>
        </span>
        <span *ngIf="quotaValue">{{'com_vc' | translate}}:<br/></span>
        <span *ngIf="quotaValue"><b>{{quotaValue}}</b></span>
      </div>
    </div>
  </div>
  <hr class="back_primario cl_hr">
</div>

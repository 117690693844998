import {Injectable} from '@angular/core';
import {ToastService} from 'ng-uikit-pro-standard';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(public toast: ToastService) { }

  showSuccess(message: string): void {
    const options = { opacity: 1 };
    this.toast.success(message, '', options);
  }

  showInfo(message: string): void {
    const options = { opacity: 1 };
    this.toast.info(message, '', options);
  }

  showWarning(message: string): void {
    const options = { opacity: 1 };
    this.toast.warning(message, '', options);
  }

  showError(message: string): void {
    const options = { opacity: 1 };
    this.toast.error(message, '', options);
  }
}

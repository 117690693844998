import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AuthService} from '@services/auth.service';
import {AuthModel} from '@models/auth';
import {Router} from '@angular/router';
import {ItemsModelMenu} from '@models/util/items.model.menu';
import {BaseModel} from '@models/util/base.model';
import {interval, Subscription} from 'rxjs';
import {MenuService} from '@pages/template/menu/menu.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {HistoricalTrazeService} from '@services/historical.traze.service';
import {StorageUtil} from '@models/util';
import {PhoneService} from '@services/phone.service';
import {PH1} from '@models/phone/ph1.model';
import {PhoneDto} from '@models/phone/phoneDto.model';
import {VERSION} from "../../../../environments/version";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent extends BaseModel implements OnInit, OnDestroy {

  @ViewChild('sidenav') sidenav: ElementRef;

  clicked: boolean;
  currentUser: AuthModel;
  model: ItemsModelMenu[];
  date: Date;
  loading = true;
  phoneList: PhoneDto[];
  src = interval(30000);
  id = Date.now();
  obs: Subscription;
  title: string;
  year: number;
  _VERSION;

  constructor(
    private router: Router,
    private phoneService: PhoneService,
    protected authService: AuthService,
    private menuService: MenuService,
    protected historical: HistoricalTrazeService,
    protected deviceService: DeviceDetectorService
  ) {
    super(authService, historical, deviceService);
    this._VERSION =  VERSION.hash;
    this.phoneList = [];
    this.clicked = this.clicked === undefined ? false : true;
    this.authService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {
    if (this.currentUser.menu === undefined) {
      this.logout();
      return;
    }

    this.year = new Date().getFullYear();

    // Solo obtengo los menus visibles, esto se diferencia por el campo icon (Se hizo por tema de permisos en la capa de seguridad)
    this.model = this.getVisibleMenu(this.currentUser.menu);
    this.date = StorageUtil.get('loginDate');
    const timeSession = Number(this.currentUser.timeSession);
    console.log('cal', timeSession);
    this.obs = this.src.subscribe(value => {
      const aux = new Date();
      const dateLast = new Date(this.authService.getLastConsume());
      console.log('dateLast', dateLast);
      let diff = aux.getMinutes() - dateLast.getMinutes();
      if (diff < 0) {
        diff = 60 - diff;
      }
      console.log('diff', diff);
      if (diff >= timeSession) {
        console.log('Cerrar cession....');
        this.obs.unsubscribe();
        this.logout();
      }
    });
  }

  ngOnDestroy() {
    this.obs.unsubscribe();
  }

  getVisibleMenu(inputMenu: ItemsModelMenu[]) {
    let list: ItemsModelMenu[];
    list = [];
    for (const item of inputMenu) {
      console.log(item);
      // si el campo icon es hide entonces es un menu oculto
      if (item.icon != null && item.icon === 'hide') {

      } else {
        if (item.items != null && item.items.length > 0) {
          item.items = this.getVisibleMenu(item.items);
        }
        list.push(item);
      }
    }
    return list;
  }

  logout() {
    const header = this.getHeader(this.authService.getUser());
    const phoneRequest = new PH1(header);
    this.phoneService.offSession(phoneRequest).subscribe(data => {
        console.log('ESTADO PHONE: ', data);
      },
      () => {
        console.log('ESTADO PHONE: ', 'ERROR');
      },
      () => {
      }
    );
    this.authService.logout();
    this.router.navigate(['/login']);
  }
  geo() {
    this.router.navigate(['/office/list']);
  }
  user() {
    this.router.navigate(['/profile']);
  }

}

import {CreditModel} from '@models/credit/credit.model';
import {RequestHeaderModel} from '@models/requestHeader.model';

export class Cre3Model {
  header: RequestHeaderModel;
  credit: CreditModel;
  representativeCode: string;
  constructor(header: RequestHeaderModel, credit: CreditModel, representativeCode: string) {
    this.header = header;
    this.credit = credit;
    this.representativeCode = representativeCode;
  }
}

import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from '@services/config';
import {Tx1} from '@models/transfer';

@Injectable({
  providedIn: 'root'
})
export class TransferService {

  constructor(public http: HttpClient) {
    console.log('Servicio de trnasferencia');
  }
  transfer(tx1: Tx1) {
    const url: string = Config.settings.path.url_root + '/tesabiz-ws-security/tbz/transaction/transfer';
    return this.http.post(url, tx1);
  }
}

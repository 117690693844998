import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '@services/auth.service';
import {AuthModel1} from '@models/auth';
import {FORMS, LOCSESION} from '@config/config';
import {BaseModel} from '@models/util/base.model';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from '@app/error/message.service';
import {StorageUtil} from '@models/util';
import {DatePipe} from '@angular/common';
import {Cd1Model} from '@models/qoute';
import {AccountService} from '@services/account.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {HistoricalTrazeService} from '@services/historical.traze.service';
import {CaptchaComponent} from '@app/components/captcha/captcha.component';
import {environment} from '../../../../environments/environment.prod';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {VERSION} from '../../../../environments/version';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BaseModel implements OnInit, AfterViewInit {

  @ViewChild('ocaptcha', {static: false}) ocaptcha: CaptchaComponent;

  cmpReq = FORMS.FRM_REQUIRED;
  cmpMin = FORMS.FRM_MINIMO;
  submitted = false;
  usu: string;
  pass: string;
  captcha: string;
  viewPass: boolean;
  eyePass = false;
  eyeUser = false;
  isPage = false;
  visiblePreload = true;
  isInit = true;
  production = environment.production;
  year: number;
  _VERSION;
  private config: {hash: string};
  constructor(
    private http: HttpClient,
    private router: Router,
    protected authService: AuthService,
    private message: MessageService,
    private accountService: AccountService,
    protected historical: HistoricalTrazeService,
    protected deviceService: DeviceDetectorService,
    private translate: TranslateService,
    private httpClient: HttpClient
  ) {
    super(authService, historical, deviceService);
    this._VERSION =  VERSION.hash;
  }

  ngOnInit() {
//    this.config = require("./../assets/version.json");
  ///  console.log(this.config.hash);
    // this.httpClient.get<{version: string}>('/assets/version.json')
    //   .subscribe(config => {
    //     console.log(config);
    //   });

    this.preload();
    this.authService.logout();
    this.captcha = '';
    this.year = new Date().getFullYear();

    this.getIP();
    const aux = StorageUtil.get(LOCSESION.LS_IP);
    console.log('IP',  aux);

    // OJO BORRAR
/*    this.usu = 'gabriela';
    this.pass = 'Tesa123.';
    this.viewPass = true;*/

  }

  ngAfterViewInit() {

    this.ocaptcha.createCapcha();
    this.loadVersion();
  }

  preload() {
    return new Promise(resolve => {
      setTimeout(() => {
        this.visiblePreload = false;
      }, 2000);
    });
  }

  nextPass() {
    this.viewPass = true;
  }

  backUser() {
    this.viewPass = false;
  }

  isCaptchaValid() {
    if (!this.ocaptcha.getValidar() && this.production) {
      this.message.showWarning('Código de captcha incorrecto');
      return false;
    } else {
      return true;
    }
  }

  back() {
    this.isPage = !this.isPage;
  }

  onLogin() {

    if (this.isCaptchaValid()) {
      const header = this.getHeader(this.usu);
      header.user = this.usu;
      const authModel1 = new AuthModel1(header, this.pass);

      StorageUtil.set('loginDate', new Date());
      this.submitted = true;
      console.log('login1::', authModel1);
      this.authService.login(authModel1).subscribe((data) => {
          console.log('login2::', data);
          this.submitted = false;
          if (data && data.token) {
            this.authService.setUser(authModel1, data);

            const saludo = this.translate.instant('login_success') + ' ' + this.authService.getCurrentUser().client.fullName;
            this.message.showSuccess(saludo);
            this.router.navigate(['']);

            this.getInfo();
            super.registerEvent('login', this.router.url);
          } else {
            console.error('ST');
            this.message.showError(data.header.txtReturn);
          }
        },
        error => {
          this.submitted = false;
        });
    }
  }


  getInfo() {
    const header = this.getHeader(this.usu);
    const date = new Date();
    const datePipe = new DatePipe('en-US');
    const dateString = datePipe.transform(date, 'yyyyMMdd');
    const cd1 = new Cd1Model(header, dateString);
    console.log('guote1::', cd1);
    this.accountService.qoute(cd1)
      .subscribe(resp => {
          console.log('guote2--::', resp);
          this.authService.updateInfo(resp.salePrice, resp.purchasePrice);
        },
        error => {
        },
      );
  }

  goOnboard() {
    this.router.navigate(['#']);
  }

  togogleEyePass() {
    this.eyePass = !this.eyePass;
  }
  togogleEyeUser() {
    this.eyeUser = !this.eyeUser;
  }

  loadVersion(){
    this.versionServer(this._VERSION);
  }
  versionServer(response: string) {
    const url = window.location.href.toString().split('/')[0] + 'assets/config/version.json';
    const headers = new HttpHeaders()
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache');
    this.http
      .get<{ version: any }>( url, {headers})
      .subscribe(config => {
        if(response !== config.version){
          window.location.reload();
        }
      });
  }
  getIP() {
    this.authService.getIP().subscribe((res:any)=>{
      console.log(res.ip);
      StorageUtil.set(LOCSESION.LS_IP, res.ip);
    });
  }
}


<header>
  <section class="view cls_page_control">
    <div class="row">
      <div class="col-md-6 col-sm-12 cls_page_content">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <h1 class="heading display-4 text-info">P&aacute;gina no encontrada</h1>
          <img src="assets/util/images/pages/icon-404.svg">
          <h4 class="subheading font-weight-bold text-info">El recurso solicitado no está disponible.</h4><br/><br/>
          <button type="button" [routerLink]="['/']" class="btn btn-info btn-rounded">Ir al Inicio<i class="fas fa-caret-right ml-3"></i></button>
        </div>
      </div>
    </div>
  </section>
</header>
